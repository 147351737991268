import { useContext, useEffect, useState } from 'react';
import {
  deleteFeedback,
  getAllFeedbacks,
  updateFeedback,
} from '../services/Manager/MongoDB/Feedback/FeedbackManager';
import { GlobalContext } from '../contexts/GlobalStorage';

export default function useFeedbacks() {
  const global = useContext(GlobalContext);
  const [load, setLoad] = useState(false);
  const [paginators, setPaginators] = useState([]);
  const [qntFeedbacksShow, setQntFeedbacksShow] = useState(10);
  const [dataFeedback, setDataFeedback] = useState([]);
  const [dataFeedbacktShow, setDataFeedbackShow] = useState([]);
  const [back, setBack] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [onConfirmModal, setOnConfirmModal] = useState(false);
  const [idFeedbackToDelete, setIdFeedbackToDelete] = useState('');
  const [bodyMessageModal, setBodyMessageModal] = useState('');
  const [idToUpdate, setIdToUpdate] = useState('');
  const [filter, setFilter] = useState('');
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  useEffect(() => {
    async function allFeedbacks() {
      const data = await getAllFeedbacks();
      let paginators = [];
      for (let i = 0; i < data.length / qntFeedbacksShow; i++) {
        paginators.push('');
      }
      setPaginators(paginators);

      if (global.userType === 2) {
        const userData = data.filter(
          (item) => item.clientEmail === global.userEmail
        );
        setDataFeedback(userData);
        setBack(userData);
        setDataFeedbackShow(
          userData.filter(
            (item, i) =>
              i < qntFeedbacksShow * 1 &&
              i >= qntFeedbacksShow * 1 - qntFeedbacksShow
          )
        );
      } else {
        setDataFeedback(data);
        setBack(data);
        setDataFeedbackShow(
          data.filter(
            (item, i) =>
              i < qntFeedbacksShow * 1 &&
              i >= qntFeedbacksShow * 1 - qntFeedbacksShow
          )
        );
      }
    }
    allFeedbacks();
  }, [global.userEmail, global.userType, load, qntFeedbacksShow]);

  useEffect(() => {
    if (onConfirmModal) {
      if (idFeedbackToDelete !== '') {
        deleteFeedback(idFeedbackToDelete).then(() => {
          setIdFeedbackToDelete('');
          setOnConfirmModal(false);
          setLoad(!load);
          setBodyMessageModal('');
          return;
        });
      } else if (idToUpdate !== '') {
        setLoadingUpdateStatus(true);
        updateFeedback(idToUpdate, {
          status: filter,
          message: feedbackMessage,
        }).then(() => {
          setLoadingUpdateStatus(false);
          setIdToUpdate('');
          setOnConfirmModal(false);
          setBodyMessageModal('');
          setFeedbackMessage('');
          setLoad(!load);
          return;
        });
      }
    } else {
      setIdFeedbackToDelete('');
      setIdToUpdate('');
      setOnConfirmModal(false);
      setBodyMessageModal('');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirmModal]);

  const handleDelete = async (id) => {
    setBodyMessageModal('Você deseja deletar esse feedback?');
    setShowConfirmModal(true);
    setIdFeedbackToDelete(id);
  };

  const handleFilterOption = (event, id) => {
    setFilter(event.target.value);
    setBodyMessageModal('Você deseja alterar o status desse feedback?');
    setShowConfirmModal(true);
    setIdToUpdate(id);
  };
  return {
    load,
    setLoad,
    paginators,
    setPaginators,
    qntFeedbacksShow,
    setQntFeedbacksShow,
    dataFeedback,
    setDataFeedback,
    dataFeedbacktShow,
    setDataFeedbackShow,
    back,
    setBack,
    showConfirmModal,
    setShowConfirmModal,
    onConfirmModal,
    setOnConfirmModal,
    idFeedbackToDelete,
    setIdFeedbackToDelete,
    bodyMessageModal,
    setBodyMessageModal,
    idToUpdate,
    setIdToUpdate,
    filter,
    setFilter,
    loadingUpdateStatus,
    setLoadingUpdateStatus,
    handleDelete,
    handleFilterOption,
    feedbackMessage,
    setFeedbackMessage,
  };
}
