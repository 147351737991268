import React, { useContext, useEffect, useState } from 'react';
import { Container, Button, Form, Col, Spinner } from 'react-bootstrap';
import { Toast } from '../../../components/Toast/Toast';
import './newusers.scss';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import api from '../../../services/api';
import {
  notifySuccess,
  notifyError,
} from '../../../components/Toast/ToastController';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { Chip, Input, InputAdornment } from '@material-ui/core';
import { ArrowLeft, PlusCircle } from 'react-bootstrap-icons';
import { cnpjMask } from '../../../helpers/cnpjMask';

function NewUsers() {
  let history = useHistory();

  const global = useContext(GlobalContext);
  const [validated, setValidated] = useState(false);
  const [cnpjValues, setCnpjValues] = useState([]);
  const [currValue, setCurrValue] = useState('');

  const [typeUser, setTypeUser] = React.useState('');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [userPermissions, setUserPermissions] = useState({
    addUserClient: false,
    changeCnpjUserClient: false,
    changeStatusFeedback: false,
    changeStatusUserClient: false,
    deleteFeedback: false,
    deleteUserClient: false,
    resetPasswordUserClient: false,
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (
      global.userType === 2 ||
      (global.userType === 1 && !global.permissions?.addUserClient)
    ) {
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, global.userType, global.permissions?.addUserClient]);

  const handleTypeUser = (event) => {
    setTypeUser(event.target.value);
  };

  const handleAddUser = async () => {
    if (typeUser === '' || name === '' || email === '') {
      notifyError('Preencha todos os campos');
      return;
    }
    if (typeUser === 2 && cnpjValues.length === 0) {
      notifyError('Preencha todos os campos');
      return;
    }

    const data = {
      name: name,
      email: email,
      usertype: typeUser,
      cnpj: cnpjValues,
      permissions: typeUser !== 2 ? userPermissions : [],
    };

    try {
      setLoading(true);
      const response = await api.post('/users', data);

      if (response.status === 200) {
        setLoading(false);
        window.location.href = '/users';
        toast(true);
      } else if (
        (setLoading(false),
        response.status === 400 ||
          response.status === 401 ||
          response.status === 500)
      ) {
        toast('error');
      }
      return;
    } catch (error) {
      setLoading(false);
      toast(false);
    }
  };

  const toast = (opcao) => {
    const loginSuccessful = opcao;
    console.log(loginSuccessful);
    if (loginSuccessful) {
      return notifySuccess('Usario criado com sucesso');
    } else if (loginSuccessful === 'error') {
      return notifyError('Já existe esse email');
    } else {
      return notifyError('Credenciais inválidas');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
    }

    handleAddUser();
    setValidated(true);
  };

  const handleChange = (e) => {
    e.persist();
    // setCurrValue(cnpjMask(e.target.value));
    setCurrValue(cnpjMask(e.target.value));
  };

  const handleDelete = (item, index) => {
    let arr = [...cnpjValues];
    arr.splice(index, 1);
    setCnpjValues(arr);
  };

  const handleSetCnpj = (e) => {
    const cnpj = currValue;
    e.persist();

    if (cnpj === '') {
      notifyError('Digite um CNPJ válido');
      return;
    }

    if (cnpjValues.includes(cnpj)) {
      notifyError('CNPJ já cadastrado');
      return;
    }

    setCnpjValues((oldState) => [...oldState, cnpj]);
    setCurrValue('');
  };

  return (
    <>
      <Container className='NewUsers'>
        <div className='title'>
          <ArrowLeft
            style={{
              cursor: 'pointer',
              marginRight: '10px',
            }}
            size={36}
            onClick={() => {
              history.goBack();
            }}
          />
          <h2>Cadastro de usuários</h2>
        </div>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Row>
            <Col>
              <Form.Group as={Col} controlId='formGridName'>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId='formGridEmail'>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type='email'
                  placeholder=''
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            {typeUser === 2 && (
              <Col>
                <Form.Group as={Col} controlId='formGridCnpj'>
                  <FormControl fullWidth>
                    <Form.Label>CNPJ</Form.Label>
                    <div>
                      {cnpjValues.map((item, index) => (
                        <Chip
                          key={index}
                          size='small'
                          onDelete={() => handleDelete(item, index)}
                          label={item}
                        />
                      ))}
                    </div>

                    <Input
                      value={currValue}
                      //value={currValue}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position='end'>
                          <PlusCircle
                            size={20}
                            color='#4274e3'
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              handleSetCnpj(e);
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                    {cnpjValues.length === 0 && (
                      <Form.Text className='text-muted'>
                        *Campo obrigatório para usuários do tipo Cliente
                      </Form.Text>
                    )}
                  </FormControl>
                </Form.Group>
              </Col>
            )}
          </Form.Row>
          <Form.Row>
            <Col xs={6}>
              <Form.Label className='teste'>Tipo de Usuário</Form.Label>
              <Form.Group as={Col}>
                <FormControl>
                  <Select
                    className='selectmenu'
                    value={typeUser}
                    onChange={handleTypeUser}
                    placeholder=''
                  >
                    <MenuItem value={1}>
                      <div>Administrador</div>
                    </MenuItem>
                    <MenuItem value={2}>Cliente</MenuItem>
                    {global.userType === 3 && (
                      <MenuItem value={3}>Super Admin</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Form.Group>
            </Col>
          </Form.Row>
          {typeUser !== 2 && typeUser !== 3 && typeUser !== '' && (
            <Form.Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Permissões</Form.Label>
                  <Form.Check
                    checked={userPermissions?.deleteFeedback}
                    id='deleteFeedback-switch'
                    type='switch'
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        deleteFeedback: e.target.checked,
                      })
                    }
                    label='Deletar feedback'
                  />
                  <Form.Check
                    checked={userPermissions?.changeStatusFeedback}
                    id='changeStatusFeedback-switch'
                    type='switch'
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        changeStatusFeedback: e.target.checked,
                      })
                    }
                    label='Alterar status feedback'
                  />
                  <Form.Check
                    checked={userPermissions?.addUserClient}
                    id='addUserClient-switch'
                    type='switch'
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        addUserClient: e.target.checked,
                      })
                    }
                    label='Adicionar usuário cliente'
                  />
                  <Form.Check
                    checked={userPermissions?.resetPasswordUserClient}
                    id='resetPasswordUserClient-switch'
                    type='switch'
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        resetPasswordUserClient: e.target.checked,
                      })
                    }
                    label='Resetar senha do usuário cliente'
                  />
                  <Form.Check
                    checked={userPermissions?.deleteUserClient}
                    id='deleteUserClient-switch'
                    type='switch'
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        deleteUserClient: e.target.checked,
                      })
                    }
                    label='Deletar usuário cliente'
                  />

                  <Form.Check
                    checked={userPermissions?.changeStatusUserClient}
                    id='changeStatusUserClient-switch'
                    type='switch'
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        changeStatusUserClient: e.target.checked,
                      })
                    }
                    label='Alterar status usuário cliente'
                  />

                  <Form.Check
                    checked={userPermissions?.changeCnpjUserClient}
                    id='changeCnpjUserClient-switch'
                    type='switch'
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        changeCnpjUserClient: e.target.checked,
                      })
                    }
                    label='Alterar CNPJ usuário cliente'
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          )}

          <Form.Row>
            <Col>
              <Form.Group as={Col} controlId='formGridName'>
                <Button
                  variant='primary'
                  type='submit'
                  className='subimit-btn'
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner size='sm' animation='border' />
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </Container>
      <Toast />
    </>
  );
}

export default NewUsers;
