import React from 'react';
import { Container } from 'react-bootstrap';
import { Clipboard } from 'react-bootstrap-icons';

import { Toast } from '../../components/Toast/Toast';
import './CopyToken.scss';
// import Buttons from "../../components/Button/Button";
import { copyToClipBoard } from '../../services/Global/clipboard/Clipboard';
import { notifySuccess } from '../../components/Toast/ToastController';
import { useLocation } from 'react-router-dom';

export default function CopyLink() {
  let token = useLocation().search;

  token = token.slice(20);
  token = token.substr(0, 70);

  const copyLinkIntegration = (token) => {
    copyToClipBoard(token);
    notifySuccess('Token copiado');
  };

  const copyLink = (token) => {
    return copyLinkIntegration(token);
  };

  return (
    <>
      <Container className='CopyLink'>
        <h2>Grant Token</h2>

        <div key={token} className='CopyLink' onClick={() => copyLink(token)}>
          <div className='boxtoken'>
            <h4 className='token'>{token}</h4>
            &nbsp;&nbsp;
            <Clipboard color='#4274e3' size={30} />
          </div>
        </div>
      </Container>

      <Toast />
    </>
  );
}
