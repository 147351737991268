import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Pagination,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import { ArrowClockwise, Trash } from 'react-bootstrap-icons';
// import { formatDate } from '../../helpers/FormatDate';
import { getAllLogs } from '../../services/Manager/MongoDB/Log/LogManager';
import moment from 'moment';
import { GlobalContext } from '../../contexts/GlobalStorage';
import ModalLog from './modalLog/LogModal';
import FilterLogs from '../_components/filterLogs/FilterLogs';
import ModalConfirm from '../_components/modalConfirm/ModalConfirm';
import LimitFilterButton from '../../components/LimitFilterButton/LimitFilterButton';
import './log.scss';

export default function Feedback() {
  const global = useContext(GlobalContext);
  const [load, setLoad] = useState(false);
  const [paginators, setPaginators] = useState([]);
  const [qntLogsShow, setQntLogsShow] = useState(5);
  const [paginator, setPaginator] = useState(1);
  const [dataLog, setDataLog] = useState([]);
  const [dataLogShow, setDataLogShow] = useState([]);
  const [back, setBack] = useState([]);

  useEffect(() => {
    async function allLogs() {
      const data = await getAllLogs();
      let paginators = [];
      for (let i = 0; i < data.length / qntLogsShow; i++) {
        paginators.push('');
      }
      setPaginators(paginators);

      setDataLog(data);
      setBack(data);
      setDataLogShow(
        data.filter(
          (item, i) => i < qntLogsShow * 1 && i >= qntLogsShow * 1 - qntLogsShow
        )
      );
    }
    allLogs();
  }, [global.userEmail, global.userType, load, qntLogsShow]);

  const changePaginator = (index) => {
    const paginatorIndex = index;
    setPaginator(index);

    setDataLogShow(
      dataLog.filter(
        (item, i) =>
          i < qntLogsShow * paginatorIndex &&
          i >= qntLogsShow * paginatorIndex - qntLogsShow
      )
    );
  };

  return (
    <div>
      <div className='Clients'>
        <Row>
          <Col className='table'>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>
                    <div className='d-flex'>
                      <FilterLogs
                        handleSetDataLogShow={setDataLogShow}
                        data={dataLog}
                        qntLogsShow={qntLogsShow}
                        handleSetDataLog={setDataLog}
                        handleSetLoad={setLoad}
                        load={load}
                        back={back}
                      />
                      <LimitFilterButton
                        handleSetLimit={setQntLogsShow}
                        limit={qntLogsShow}
                      />
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>

                  <th>
                    {' '}
                    <ArrowClockwise
                      style={{ cursor: 'pointer', float: 'right' }}
                      size={26}
                      color='#4274e3'
                      onClick={() => {
                        setLoad(!load);
                      }}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Usuário</th>
                  <th>Tipo de Ação</th>
                  <th>Data</th>
                  <th>horário</th>
                  <th
                    style={{
                      width: '375px',
                    }}
                  >
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataLogShow.map((log) => (
                  <tr key={log?._id}>
                    <td>{log?.clientName}</td>
                    <td>{log.actionType}</td>
                    <td>{log?.date}</td>
                    <td>{log?.time}</td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        {' '}
                        <ModalLog
                          logMessage={log?.log}
                          logClientName={log?.clientName}
                          logUser={log?.user}
                          logDate={log?.date}
                          logTime={log?.time}
                          logAction={log?.action}
                          logTypeAction={log?.actionType}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {dataLogShow.length === 0 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '50px',
                  height: '100%',
                }}
              >
                Nenhum dado encontrado
              </div>
            )}
          </Col>
        </Row>

        <Row style={{ width: '100%' }}></Row>
        {dataLog.length > qntLogsShow && (
          <Row>
            <Pagination>
              <Pagination.First
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(1);
                }}
              />
              <Pagination.Prev
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(paginator - 1);
                }}
              />

              {paginators.map((item, index) => {
                return (
                  <Pagination.Item
                    key={index}
                    onClick={() => changePaginator(index + 1)}
                    active={index + 1 === paginator}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}

              <Pagination.Next
                onClick={() => {
                  changePaginator(paginator + 1);
                }}
                disabled={Math.ceil(dataLog.length / qntLogsShow) <= paginator}
              />
              <Pagination.Last
                onClick={() => {
                  changePaginator(Math.ceil(dataLog.length / qntLogsShow));
                }}
                disabled={Math.ceil(dataLog.length / qntLogsShow) <= paginator}
              />
            </Pagination>
          </Row>
        )}
      </div>
    </div>
  );
}
