import moment from 'moment';
import 'moment/locale/pt-br';
export const formatDateCertificate = (date) => {
  try {
    const formattedDate = moment(date, 'DD-MM-YYYY HH:mm:ss')
      .locale('pt-br')
      .format('DD [de] MMMM [de] YYYY[,] [às] HH:mm [h]');
    return formattedDate;
  } catch (err) {
    return date;
  }
};
