import React, { useContext } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { BoxArrowRight, List } from 'react-bootstrap-icons';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { getIdUsuario } from '../../../services/Auth/Login';
import ProfileImage from '../../../components/ProfileImage/ProfileImage';

export default function DropdownMenu({ idEnterprise, handleSair }) {
  const global = useContext(GlobalContext);
  const id = getIdUsuario();

  return (
    <Dropdown className='dropdown'>
      <div style={{ width: "40px", height: "40px" }}>
        <ProfileImage />
      </div>
      <Dropdown.Toggle>
        <List size={24} />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className='DropdownMenu'
        style={{ backgroundColor: '#32a1ee' }}
      >
        <Nav
          style={{
            flexDirection: 'column',
          }}
        >
          <Dropdown.Item exact as={NavLink} className='navLink' to={'/home'}>
            Home
          </Dropdown.Item>
          <Dropdown.Item
            exact
            className='navLink'
            as={NavLink}
            to={
              global.enterpriseList.length == 1
                ? '/Clients/'
                : '/Clients'
            }
          >
            {global.userType !== 2 && 'Clientes'}
            {global.userType === 2 && (
              <>{global.userCnpj.length > 1 ? 'Empresas' : 'Meus dados'}</>
            )}
          </Dropdown.Item>
          <Dropdown.Item
            exact
            className='navLink'
            to='/certificate'
            as={NavLink}
          >
            Certificados
          </Dropdown.Item>
          <Dropdown.Item exact className='navLink' to='/nfe' as={NavLink}>
            NF-e
          </Dropdown.Item>
          <Dropdown.Item exact className='navLink' to='/nfse' as={NavLink}>
            NFs-e
          </Dropdown.Item>
          <Dropdown.Item
            exact
            className='navLink'
            to={`/users/update/${id}`}
            as={NavLink}
          >
            <div className="profileNav">
              Perfil
            </div>
          </Dropdown.Item>
          {global.userType !== 2 && (
            <Dropdown.Item exact className='navLink' to={'/users'} as={NavLink}>
              Usuários
            </Dropdown.Item>
          )}

          {global.userType === 3 && (
            <Dropdown.Item exact className='navLink' to={'/log'} as={NavLink}>
              Logs
            </Dropdown.Item>
          )}

          <Dropdown.Item
            exact
            className='navLink'
            to={'/feedback'}
            as={NavLink}
          >
            {global.userType === 2 ? 'Meus feedbacks' : 'Feedbacks'}
          </Dropdown.Item>

          <div onClick={handleSair} className='logout'>
            Sair
            <BoxArrowRight style={{ marginLeft: '5px' }} />
          </div>
        </Nav>
      </Dropdown.Menu>
    </Dropdown>
  );
}
