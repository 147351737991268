export function downloadFile({ downloadUrl, fileName }) {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", fileName);
  
    // Append to html link element page
    document.body.appendChild(link);
  
    // Start download
    link.click();
  
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }