import React, { useContext, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Pagination,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import { ArrowClockwise, Trash } from 'react-bootstrap-icons';
import { formatDate } from '../../helpers/FormatDate';
import { GlobalContext } from '../../contexts/GlobalStorage';
import ModalFeedback from './modalFeedback/ModalFeedback';
import FilterFeedbacks from '../_components/filterFeedbacks/FilterFeedbacks';
import ModalConfirm from '../_components/modalConfirm/ModalConfirm';
import LimitFilterButton from '../../components/LimitFilterButton/LimitFilterButton';
import useFeedbacks from '../../hooks/use-feedbacks';

export default function Feedback() {
  const global = useContext(GlobalContext);
  const {
    load,
    setLoad,
    paginators,
    qntFeedbacksShow,
    setQntFeedbacksShow,
    dataFeedback,
    setDataFeedback,
    dataFeedbacktShow,
    setDataFeedbackShow,
    back,
    setShowConfirmModal,
    setOnConfirmModal,
    showConfirmModal,
    bodyMessageModal,
    loadingUpdateStatus,
    handleDelete,
    handleFilterOption,
    idToUpdate,
    feedbackMessage,
    setFeedbackMessage,
    filter,
    idFeedbackToDelete,
  } = useFeedbacks();
  const [paginator, setPaginator] = useState(1);

  const changePaginator = (index) => {
    const paginatorIndex = index;
    setPaginator(index);

    setDataFeedbackShow(
      dataFeedback.filter(
        (item, i) =>
          i < qntFeedbacksShow * paginatorIndex &&
          i >= qntFeedbacksShow * paginatorIndex - qntFeedbacksShow
      )
    );
  };

  return (
    <div>
      <ModalConfirm
        handleSetShowConfirmModal={setShowConfirmModal}
        handleSetOnConfirmModal={setOnConfirmModal}
        showConfirmModal={showConfirmModal}
        body={bodyMessageModal}
        isFeedback={true}
        feedbackMessage={feedbackMessage}
        setFeedbackMessage={setFeedbackMessage}
        filter={filter}
        idFeedbackToDelete={idFeedbackToDelete}
      />
      <div className='Clients'>
        <Row>
          <Col className='table'>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>
                    <div className='d-flex'>
                      <FilterFeedbacks
                        handleSetDataFeedbackShow={setDataFeedbackShow}
                        data={dataFeedback}
                        qntFeedbacksShow={qntFeedbacksShow}
                        handleSetDataFeedback={setDataFeedback}
                        handleSetLoad={setLoad}
                        load={load}
                        back={back}
                      />
                      <LimitFilterButton
                        handleSetLimit={setQntFeedbacksShow}
                        limit={qntFeedbacksShow}
                      />
                    </div>
                  </th>
                  {global.userType !== 2 && <th> </th>}
                  <th></th>
                  <th></th>
                  <th></th>

                  <th>
                    {' '}
                    <ArrowClockwise
                      style={{ cursor: 'pointer', float: 'right' }}
                      size={26}
                      color='#4274e3'
                      onClick={() => {
                        setLoad(!load);
                      }}
                    />
                  </th>
                </tr>
                <tr>
                  {global.userType !== 2 && <th>Cliente</th>}

                  <th>Tipo</th>
                  <th>Data</th>
                  <th>Email</th>
                  <th
                    style={{
                      width: '108px',
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      width: '375px',
                    }}
                  >
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataFeedbacktShow.map((feedback, index) => (
                  <tr key={feedback?.id || index}>
                    {global.userType !== 2 && <td>{feedback?.clientName}</td>}
                    <td>{feedback?.feedbackType}</td>
                    <td>{formatDate(feedback?.createdAt)}</td>
                    <td>{feedback?.clientEmail}</td>

                    {feedback.status === 'Atendido' && (
                      <td className='text-success'>{feedback?.status}</td>
                    )}

                    {feedback.status === 'Em Andamento' && (
                      <td className='text-warning'>{feedback?.status}</td>
                    )}
                    {feedback.status === 'Aberto' && (
                      <td className='text-primary'>{feedback?.status}</td>
                    )}
                    {feedback.status === 'Em Análise' && (
                      <td className='text-info'>{feedback?.status}</td>
                    )}
                    {feedback.status === 'Finalizado' && (
                      <td className='text-danger'>{feedback?.status}</td>
                    )}

                    <td>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        {' '}
                        <ModalFeedback
                          feedbackType={feedback?.feedbackType}
                          feedbackMessage={feedback?.feedbackMessage}
                        />
                        {(global.userType === 3 ||
                          global.permissions.deleteFeedback) && (
                          <Button
                            variant='danger'
                            size='sm'
                            onClick={() => handleDelete(feedback.idFeedback)}
                            className='ButtonOptions'
                          >
                            Deletar
                            <Trash
                              style={{ marginLeft: '5px' }}
                              color='white'
                              size={17}
                            />
                          </Button>
                        )}
                        {(global.userType === 3 ||
                          global.permissions.changeStatusFeedback) &&
                          feedback.status !== 'Finalizado' &&
                          feedback.status !== 'Atendido' &&
                          feedback.status !== 'Concluído' && (
                            <>
                              <Form.Control
                                className='filterSelect'
                                as='select'
                                onChange={(e) => {
                                  handleFilterOption(e, feedback.idFeedback);
                                }}
                                value={feedback.status}
                                style={{
                                  width: 'auto',
                                  marginLeft: '5px',
                                }}
                              >
                                <option key={0} value={'Aberto'}>
                                  Aberto
                                </option>
                                <option key={1} value={'Em Andamento'}>
                                  Em Andamento
                                </option>
                                <option key={2} value={'Atendido'}>
                                  Atendido
                                </option>
                                <option key={4} value={'Em Análise'}>
                                  Em Análise
                                </option>
                                <option key={5} value={'Finalizado'}>
                                  Finalizado
                                </option>
                              </Form.Control>
                              {loadingUpdateStatus &&
                                feedback.idFeedback === idToUpdate && (
                                  <Spinner
                                    style={{
                                      alignSelf: 'center',
                                      marginLeft: '5px',
                                    }}
                                    variant='primary'
                                    size='sm'
                                    animation='border'
                                  />
                                )}
                            </>
                          )}
                      </div>
                    </td>
                  </tr> //
                ))}
              </tbody>
            </Table>
            {dataFeedbacktShow.length === 0 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '50px',
                  height: '100%',
                }}
              >
                Nenhum dado encontrado
              </div>
            )}
          </Col>
        </Row>

        <Row style={{ width: '100%' }}></Row>
        {dataFeedback.length > qntFeedbacksShow && (
          <Row>
            <Pagination>
              <Pagination.First
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(1);
                }}
              />
              <Pagination.Prev
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(paginator - 1);
                }}
              />

              {paginators.map((item, index) => {
                return (
                  <Pagination.Item
                    key={index}
                    onClick={() => changePaginator(index + 1)}
                    active={index + 1 === paginator}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}

              <Pagination.Next
                onClick={() => {
                  changePaginator(paginator + 1);
                }}
                disabled={
                  Math.ceil(dataFeedback.length / qntFeedbacksShow) <= paginator
                }
              />
              <Pagination.Last
                onClick={() => {
                  changePaginator(
                    Math.trunc(dataFeedback.length / qntFeedbacksShow)
                  );
                }}
                disabled={
                  Math.ceil(dataFeedback.length / qntFeedbacksShow) <= paginator
                }
              />
            </Pagination>
          </Row>
        )}
      </div>
    </div>
  );
}
