import { useContext, useEffect, useState } from 'react';
import { notifyError } from '../components/Toast/ToastController';
import { GlobalContext } from '../contexts/GlobalStorage';
import {
  getNfseByOrgId,
  getPaginatedNfse,
} from '../services/Manager/MongoDB/Nfse/NfseManager';

export default function useNfse() {
  const global = useContext(GlobalContext);

  const [nfses, setNfses] = useState([]);
  const [reload, setReload] = useState(false);
  const [paginator, setPaginator] = useState(1);
  const [paginators, setPaginators] = useState([]);
  const [orgId, setOrgId] = useState('');
  const [limitNotesToShow, setLimitNotesToShow] = useState(10);
  const [loadingNfses, setLoadingNfses] = useState(false);
  const [allNotes, setAllNotes] = useState([]);

  useEffect(() => {
    async function allNotes() {
      setNfses([]);
      setLoadingNfses(true);
      if (global.enterpriseList.length === 0) {
        setNfses([]);
        setLoadingNfses(false);
        return;
      }

      const handleAddPagination = (paginatedNfses) => {
        setAllNotes(paginatedNfses.total);
        let paginators = [];
        for (let i = 0; i < paginatedNfses.total / limitNotesToShow; i++) {
          paginators.push('');
        }
        setPaginators(paginators);
      };

      try {
        if (global.userType === 2) {
          if (orgId === '') {
            for (let enterprise of global.enterpriseList) {
              const nfse = await getNfseByOrgId({
                orgId: enterprise.idOrg,
                limit: limitNotesToShow,
                page: paginator - 1,
              });
              if (nfse.data.length > 0) {
                handleAddPagination(nfse);
                return setNfses((nfses) => [...nfses, ...nfse.data]);
              }
            }
            setLoadingNfses(false);
          } else {
            const paginatedNfses = await getNfseByOrgId({
              orgId,
              limit: limitNotesToShow,
              page: paginator - 1,
            });
            handleAddPagination(paginatedNfses);
            setNfses(paginatedNfses.data);
            setLoadingNfses(false);
          }
        } else if (global.userType !== 2) {
          if (orgId === '') {
            const paginatedNfses = await getPaginatedNfse({
              limit: limitNotesToShow,
              page: paginator - 1,
            });
            setNfses(paginatedNfses.data);
            handleAddPagination(paginatedNfses);
            setLoadingNfses(false);
          } else {
            const paginatedNfses = await getNfseByOrgId({
              orgId,
              limit: limitNotesToShow,
              page: paginator - 1,
            });
            handleAddPagination(paginatedNfses);
            setNfses(paginatedNfses.data);
            setLoadingNfses(false);
          }
        }
      } catch (err) {
        setLoadingNfses(false);
        notifyError('Erro ao buscar NFSE-s no servidor.');
      } finally {
        setLoadingNfses(false);
      }
    }
    allNotes();
  }, [
    limitNotesToShow,
    paginator,
    orgId,
    reload,
    global.enterpriseList.length,
    global.userType,
    global.enterpriseList,
  ]);
  return {
    nfses,
    setNfses,
    reload,
    setReload,
    paginator,
    setPaginator,
    paginators,
    orgId,
    setOrgId,
    limitNotesToShow,
    setLimitNotesToShow,
    loadingNfses,
    setLoadingNfses,
    allNotes,
  };
}
