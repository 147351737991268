import axios from 'axios';
import api from '../../../api';
const url = `${process.env.REACT_APP_URL_BACKEND}/enterprise`;

export const enterpriseInitialData = {
  cpfCnpj: '',
  inscricaoMunicipal: '',
  inscricaoEstadual: '',
  razaoSocial: '',
  email: '',
  endereco: {
    tipoLogradouro: '',
    logradouro: '',
    numero: '',
    complemento: '',
    tipoBairro: '',
    bairro: '',
    codigoPais: '',
    descricaoPais: '',
    codigoCidade: '',
    descricaoCidade: '',
    estado: '',
    cep: '',
  },
};

export const createEnterprise = async (body) => {
  const enterprise = await axios.post(`${url}`, JSON.stringify(body)).then(
    (response) => console.log(response),
    (error) => console.log(error)
  );
  return enterprise;
};

export const getEnterpriseById = async (id) => {
  const enterprise = await axios.get(`${url}/${id}`);
};

export const getAllEnterprises = async () => {
  const enterprise = await api.get(`${url}`);

  return enterprise.data;
};

export const updateEnterprise = async (id, body) => {
  const enterprise = await axios.get(`${url}/${id}`);
};

export const getEnterpriseByCnpj = async (cnpj) => {
  const { data } = await api.get(`/enterprise/cnpj/${cnpj}`);

  return data;
};
