import React, { useContext, useEffect } from 'react';
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './../../../assets/img/logo.png';
import { BoxArrowRight } from 'react-bootstrap-icons';
import './Header.scss';
import { logout, getIdUsuario } from '../../../services/Auth/Login';
import { NavLink } from 'react-router-dom';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { useHistory } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';
import ModalConfirm from '../modalConfirm/ModalConfirm';
import ProfileImage from '../../../components/ProfileImage/ProfileImage';

function Header({ name, client }) {
  const global = useContext(GlobalContext);
  const [idEnterprise, setIdEnterprise] = React.useState('');
  let history = useHistory();
  const id = getIdUsuario();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [onConfirmModal, setOnConfirmModal] = React.useState(false);
  const indexHeader = document.querySelector('.indexHeader');

  async function confirmSair() {
    setShowConfirmModal(true);
  }

  useEffect(() => {
    if (onConfirmModal) {
      history.push(`/`);
      logout();
      indexHeader.style.display = 'none';
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirmModal]);

  useEffect(() => {
    if (global.enterpriseList.length === 1) {
      setIdEnterprise(global.enterpriseList[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global]);

  return (
    <>
      <Container className='Header' fluid>
        <Container className='Container'>
          <Row
            style={{
              margin: '10px 0',
              display: 'flex',
            }}
            className='rowContainer'
          >
            {name !== '' && (
              <Col className='Logo'>
                {name === '' ? (
                  <Link to='/'>
                    <Image href='/' src={logo} />
                  </Link>
                ) : (
                  <Link to='/home'>
                    <Image href='/home' src={logo} />
                  </Link>
                )}
              </Col>
            )}
            <ModalConfirm
              handleSetShowConfirmModal={setShowConfirmModal}
              handleSetOnConfirmModal={setOnConfirmModal}
              showConfirmModal={showConfirmModal}
              body={'Deseja realmente sair do sistema?'}
            />

            {name === '' && (
              <Col className='LogoWithoutLogin'>
                {name === '' ? (
                  <Link to='/'>
                    <Image href='/' src={logo} />
                  </Link>
                ) : (
                  <Link to='/home'>
                    <Image href='/home' src={logo} />
                  </Link>
                )}
              </Col>
            )}
            {name === '' ? (
              ''
            ) : (
              <Col
                style={{
                  backgroundColor: '#32a1ee',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className='middleMenu'
              >
                <Nav>
                  <NavLink exact className='navLink' to={'/home'}>
                    Home
                  </NavLink>
                  <NavLink
                    exact
                    className='navLink'
                    to={
                      global.enterpriseList.length === 1
                        ? '/updatecompanies/' + idEnterprise
                        : '/Clients'
                    }
                  >
                    {global.userType !== 2 && 'Clientes'}
                    {global.userType === 2 && (
                      <>
                        {global.enterpriseList.length > 1
                          ? 'Empresas'
                          : 'Meus dados'}
                      </>
                    )}
                  </NavLink>
                  <NavLink exact className='navLink' to='/certificate'>
                    Certificados
                  </NavLink>
                  <NavLink exact className='navLink' to='/nfe'>
                    NF-e
                  </NavLink>
                  <NavLink exact className='navLink' to='/nfse'>
                    NFs-e
                  </NavLink>
                </Nav>
              </Col>
            )}
            {name !== '' && (
              <Col className='right-menu'>
                {name === '' ? (
                  ''
                ) : (
                  <Nav
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <NavLink
                      exact
                      className='navLink'
                      to={`/users/update/${id}`}
                    >
                      <div className='profileNav'>
                        <ProfileImage />
                        Perfil
                      </div>
                    </NavLink>
                    {global.userType !== 2 && (
                      <NavLink exact className='navLink' to={'/users'}>
                        Usuários
                      </NavLink>
                    )}

                    {global.userType === 3 && (
                      <NavLink exact className='navLink' to={'/log'}>
                        Logs
                      </NavLink>
                    )}

                    <NavLink exact className='navLink' to={'/feedback'}>
                      {global.userType === 2 ? 'Meus feedbacks' : 'Feedbacks'}
                    </NavLink>

                    <div onClick={confirmSair} className='logout'>
                      Sair
                      <BoxArrowRight style={{ marginLeft: '5px' }} />
                    </div>
                  </Nav>
                )}
              </Col>
            )}
            {name !== '' && (
              <DropdownMenu
                handleSair={confirmSair}
                idEnterprise={idEnterprise}
              />
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Header;
