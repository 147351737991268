import React, { useContext } from 'react';
import './DashClient.scss';
import { Spinner, Table } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { useHistory } from 'react-router-dom';
import useClients from '../../../hooks/use-clients';
import { cnpjMask } from '../../../helpers/cnpjMask';

export default function DashClient() {
  const global = useContext(GlobalContext);
  const { loadingClients, dataClient, load, setLoad, dataClientShow } =
    useClients();
  const history = useHistory();

  const handleCompany = async (id) => {
    if (global.userType !== 2) {
      history.push(`/clients/update/${id}`);
    } else {
      history.push('/updatecompanies/' + id);
    }
  };

  return (
    <div className='ContainerDashClient'>
      <div className='bodyColHead'>
        <h3>
          {global.userType !== 2 && 'Clientes'}
          {global.userType === 2 && (
            <>
              {global.enterpriseList.length > 1
                ? 'Minhas empresas'
                : 'Meus dados'}
            </>
          )}
        </h3>
        <ArrowClockwise
          style={{ cursor: 'pointer' }}
          size={26}
          color='#4274e3'
          onClick={() => {
            setLoad(!load);
          }}
        />
      </div>

      <Table responsive striped hover>
        <thead className='tableHeadDashClient'>
          <tr>
            <th className='tableEmpresa'>Nome</th>
            {global.userType !== 2 ? <th>Nome Resp.</th> : <th>CNPJ</th>}
            <th>E-mail do BoletoCloud</th>
          </tr>
        </thead>
        <tbody>
          {(global.userType === 2 ? dataClientShow : dataClient).map(
            (client) => (
              <tr
                key={client.id}
                onClick={() => {
                  handleCompany(client.id);
                }}
              >
                {global.userType !== 2 ? (
                  <td>{client.companyName}</td>
                ) : (
                  <td>{client.nomeFantasia}</td>
                )}
                {global.userType !== 2 ? (
                  <td>{client.name}</td>
                ) : (
                  <td>{cnpjMask(client.cpfCnpj)}</td>
                )}
                <td>{client.email} </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
      {!loadingClients && (dataClient.length || dataClientShow.length) === 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '50px',
            height: '100%',
          }}
        >
          Nenhuma cliente encontrado
        </div>
      )}
      {loadingClients && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Spinner animation='border' variant='primary' />
        </div>
      )}
    </div>
  );
}
