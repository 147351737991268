import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';

export default function FeedbackForm({
  feedbackMessage,
  handleSetFeedbackMessage,
  handleSubmit,
  loading,
}) {
  return (
    <div className='feedbackFormContainer'>
      <div>
        <Form.Group>
          <Form.Control
            as='textarea'
            className='feedbackForm'
            type='text'
            placeholder='Tem uma dúvida ou uma sugestão? Deixe seu feedback'
            value={feedbackMessage}
            onChange={(e) => handleSetFeedbackMessage(e.target.value)}
          />
        </Form.Group>
      </div>
      <div className='feedbackFormButtons'>
        <Button
          style={{
            width: '100%',
          }}
          onClick={(e) => handleSubmit(e)}
          disabled={feedbackMessage === undefined || loading}
        >
          {loading ? (
            <Spinner size='sm' animation='border' />
          ) : (
            'Enviar feedback'
          )}
        </Button>
      </div>
    </div>
  );
}
