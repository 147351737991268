import { useContext, useEffect, useState } from 'react';
import { notifyError } from '../components/Toast/ToastController';
import { GlobalContext } from '../contexts/GlobalStorage';
import {
  getAllNotes,
  getNfeByOrgId,
  getPaginatedNfe,
} from '../services/Manager/MongoDB/Nfe/NfeManager';

export default function useNfe() {
  const global = useContext(GlobalContext);

  const [nfes, setNfes] = useState([]);
  const [reload, setReload] = useState(false);
  const [orgId, setOrgId] = useState('');
  const [paginator, setPaginator] = useState(1);
  const [paginators, setPaginators] = useState([]);
  const [limitNotesToShow, setLimitNotesToShow] = useState(10);
  const [loadingNfes, setLoadingNfes] = useState(false);
  const [allNotes, setAllNotes] = useState([]);
  useEffect(() => {
    async function allNotes() {
      setNfes([]);
      setLoadingNfes(true);

      if (global.enterpriseList.length === 0) {
        setNfes([]);
        setLoadingNfes(false);
        return;
      }

      const handleAddPagination = (paginatedNfes) => {
        setAllNotes(paginatedNfes.total);
        let paginators = [];
        for (let i = 0; i < paginatedNfes.total / limitNotesToShow; i++) {
          paginators.push('');
        }
        setPaginators(paginators);
      };

      try {
        if (global.userType === 2) {
          if (orgId === '') {
            for (let enterprise of global.enterpriseList) {
              const nfe = await getNfeByOrgId({
                orgId: enterprise.idOrg,
                limit: limitNotesToShow,
                page: paginator - 1,
              });
              if (nfe.data.length > 0) {
                handleAddPagination(nfe);
                return setNfes((nfes) => [...nfes, ...nfe.data]);
              }
            }
            setLoadingNfes(false);
          } else {
            const paginatedNfes = await getNfeByOrgId({
              orgId,
              limit: limitNotesToShow,
              page: paginator - 1,
            });
            handleAddPagination(paginatedNfes);
            setNfes(paginatedNfes.data);
            setLoadingNfes(false);
          }
        } else if (global.userType !== 2) {
          if (orgId === '') {
            const paginatedNfes = await getPaginatedNfe({
              limit: limitNotesToShow,
              page: paginator - 1,
            });
            setNfes(paginatedNfes.data);
            handleAddPagination(paginatedNfes);
            setLoadingNfes(false);
          } else {
            const paginatedNfes = await getNfeByOrgId({
              orgId,
              limit: limitNotesToShow,
              page: paginator - 1,
            });
            handleAddPagination(paginatedNfes);
            setNfes(paginatedNfes.data);
            setLoadingNfes(false);
          }
        }
      } catch (err) {
        setLoadingNfes(false);
        notifyError('Erro ao buscar NFE-s no servidor.');
      } finally {
        setLoadingNfes(false);
      }
    }
    allNotes();
  }, [
    limitNotesToShow,
    paginator,
    orgId,
    reload,
    global.enterpriseList.length,
    global.userType,
    global.enterpriseList,
  ]);
  return {
    nfes,
    setNfes,
    reload,
    setReload,
    orgId,
    setOrgId,
    paginator,
    paginators,
    setPaginator,
    limitNotesToShow,
    setLimitNotesToShow,
    loadingNfes,
    setLoadingNfes,
    allNotes,
  };
}
