import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Row, Col, Table, Pagination, Spinner, Button } from 'react-bootstrap';
import { ArrowClockwise, EyeFill } from 'react-bootstrap-icons';
import Toast from '../../components/Toast/Toast';
import './nfe.scss';
import { formatDate } from '../../helpers/FormatDate';
import { formatValue } from '../../helpers/FormatValue';
import FilterNfe from '../_components/filterNfe/FilterNfe';
import LimitFilterButton from '../../components/LimitFilterButton/LimitFilterButton';
import useNfe from '../../hooks/use-nfe';
import { GlobalContext } from '../../contexts/GlobalStorage';

export default function NfeList() {
  const global = useContext(GlobalContext);
  const {
    nfes,
    setNfes,
    reload,
    setReload,
    setOrgId,
    orgId,
    paginator,
    setPaginator,
    limitNotesToShow,
    setLimitNotesToShow,
    loadingNfes,
    setLoadingNfes,
    paginators,
    allNotes,
  } = useNfe();
  const history = useHistory();
  const [initItem, setInitItem] = useState(0);
  const [lastItem, setLastItem] = useState(5);

  const handlePrevPage = () => {
    if (paginator === initItem + 1) {
      setInitItem(initItem - 5);
      setLastItem(lastItem - 5);
    }
    setPaginator((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setLoadingNfes(true);
    if (paginator === lastItem) {
      handlePage();
    }
    setPaginator((prev) => prev + 1);
  };

  const handleSetPaginator = (page) => {
    setPaginator(page);
  };
  const handlePage = () => {
    setInitItem(initItem + 5);
    setLastItem(lastItem + 5);
  };

  return (
    <>
      <Toast />

      <div style={{ height: '100%' }} className='Clients'>
        <Row>
          <Col className='table'>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>
                    <div className='d-flex'>
                      <FilterNfe
                        handleSetNfes={setNfes}
                        handleSetLoadingNfes={setLoadingNfes}
                        handleSetOrgId={setOrgId}
                        loadingNfes={loadingNfes}
                      />
                      <LimitFilterButton
                        handleSetLimit={setLimitNotesToShow}
                        limit={limitNotesToShow}
                      />
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {' '}
                    <ArrowClockwise
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                      size={26}
                      color='#4274e3'
                      onClick={() => {
                        setReload(!reload);
                      }}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Cliente</th>
                  <th className='tableEmpresa'>ID Nota</th>
                  <th>ID Books</th>
                  <th>Status</th>
                  <th>Hora de Criação</th>
                  <th>Valor</th>
                  <th>
                    {' '}
                    <div>Opções</div>
                  </th>
                </tr>
              </thead>
              {!loadingNfes && (
                <tbody>
                  {nfes.map((note) => (
                    <tr
                      key={note?.idPlugnotas}
                      onClick={() => history.push(`/nfe/${note?._id}`)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{note?.clientName}</td>
                      <td>{note?.idPlugnotas}</td>
                      <td>{note?.idInvoiceZohoBook} </td>
                      {note.statusPlugnotas === 'REJEITADO' && (
                        <td className='text-danger'>{note?.statusPlugnotas}</td>
                      )}
                      {note.statusPlugnotas === 'CONCLUIDO' && (
                        <td className='text-success'>
                          {note?.statusPlugnotas}
                        </td>
                      )}
                      {note.statusPlugnotas === 'Nota(as) em processamento' && (
                        <td className='text-warning'>
                          {note?.statusPlugnotas}
                        </td>
                      )}
                      {note.statusPlugnotas === 'CANCELADO' && (
                        <td className='text-danger'>{note?.statusPlugnotas}</td>
                      )}
                      {note.statusPlugnotas === 'EXCEPTION' && (
                        <td className='text-primary'>
                          {note?.statusPlugnotas}
                        </td>
                      )}

                      <td>{formatDate(note?.dataHoraCriacao)}</td>

                      <td>{formatValue(note?.valor)}</td>
                      <td>
                        <Button
                          size='sm'
                          onClick={() => history.push(`/nfe/${note?._id}`)}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          Ver
                          <EyeFill
                            style={{ marginLeft: '5px' }}
                            color='white'
                            size={17}
                          />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>

            {!loadingNfes && nfes?.length === 0 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '50px',
                  height: '100%',
                }}
              >
                {global.userType === 2 && orgId === ''
                  ? 'Escolha uma empresa'
                  : 'Nenhuma NF-e encontrada'}
              </div>
            )}
          </Col>
          {loadingNfes && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Spinner animation='border' variant='primary' />
            </div>
          )}
        </Row>
        {nfes.length > 0 && (
          <Row>
            <Col>
              <Pagination>
                <Pagination.First
                  disabled={paginator <= 1}
                  onClick={() => {
                    setPaginator(1);
                    setInitItem(0);
                    setLastItem(5);
                  }}
                />
                <Pagination.Prev
                  disabled={paginator <= 1}
                  onClick={handlePrevPage}
                />
                {paginators.map((item, index) => {
                  return (
                    <div key={index}>
                      {index < lastItem && index >= initItem && (
                        <Pagination.Item
                          onClick={() => handleSetPaginator(index + 1)}
                          active={index + 1 === paginator}
                        >
                          {index + 1}
                        </Pagination.Item>
                      )}
                      {index === 15 && <Pagination.Ellipsis />}
                    </div>
                  );
                })}

                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={nfes?.length < limitNotesToShow}
                />
                <Pagination.Last
                  onClick={() => {
                    setPaginator(Math.ceil(allNotes / limitNotesToShow));
                    setInitItem(Math.ceil(allNotes / limitNotesToShow) - 5);
                    setLastItem(Math.ceil(allNotes / limitNotesToShow));
                  }}
                  disabled={Math.ceil(allNotes / limitNotesToShow) <= paginator}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
