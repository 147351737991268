import React, { useContext, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { CustomToggle } from '../../../components/CustomToggle/CustomToggle';
import { GlobalContext } from '../../../contexts/GlobalStorage';

const typeEnum = {
  0: 'Nenhum',
  1: 'Cliente',
  2: 'Tipo',
  3: 'Email',
  4: 'Status',
};
export default function FilterFeedbacks({
  handleSetDataFeedbackShow,
  data,
  handleSetDataFeedback,
  handleSetLoad,
  load,
  back,
  qntFeedbacksShow,
}) {
  const global = useContext(GlobalContext);
  const [filter, setFilter] = useState({
    type: 0,
    value: '',
    searchValue: '',
  });

  const handleFilterOption = (event) => {
    handleSetLoad(!load);

    const type = Number(event?.target?.value);

    setFilter((prev) => {
      return {
        ...prev,
        searchValue: '',
        type,
      };
    });
  };

  const handleFilterSearch = (event) => {
    handleSetDataFeedback(back);
    handleSetDataFeedbackShow(
      back.filter(
        (item, i) =>
          i < qntFeedbacksShow * 1 &&
          i >= qntFeedbacksShow * 1 - qntFeedbacksShow
      )
    );
    const searchValue = event.target.value;
    setFilter((prev) => {
      return {
        ...prev,
        searchValue,
      };
    });
  };

  const handleSubmitSearch = async () => {
    const newDataFiltered = data.filter((item) => {
      if (filter.type === 1) {
        if (
          item.clientName
            .replace(/ /g, '')
            .toLowerCase()
            .includes(filter.searchValue.replace(/ /g, '').toLowerCase())
        ) {
          return item;
        } else {
          return null;
        }
      } else if (filter.type === 2) {
        if (item.feedbackType === filter.searchValue) {
          return item;
        } else {
          return null;
        }
      } else if (filter.type === 3) {
        if (
          item.clientEmail
            .replace(/ /g, '')
            .toLowerCase()
            .includes(filter.searchValue.replace(/ /g, '').toLowerCase())
        ) {
          return item;
        } else {
          return null;
        }
      } else if (filter.type === 4) {
        if (item.status === filter.searchValue) {
          return item;
        } else {
          return null;
        }
      } else {
        return true;
      }
    });
    return (
      handleSetDataFeedbackShow(
        newDataFiltered.filter(
          (item, i) =>
            i < qntFeedbacksShow * 1 &&
            i >= qntFeedbacksShow * 1 - qntFeedbacksShow
        )
      ),
      handleSetDataFeedback(newDataFiltered)
    );
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
      <Dropdown.Menu>
        <Row className='dropMenuClients'>
          <Col className='filterCol'>
            <Form.Label className='filterLabel'>Filtrar por:</Form.Label>
            <Form.Control
              className='filterSelect'
              as='select'
              onChange={handleFilterOption}
              value={filter.type}
            >
              {Object.keys(typeEnum).map((type) => (
                <>
                  {global.userType !== 2 ? (
                    <option key={type} value={type}>
                      {typeEnum[type]}
                    </option>
                  ) : (
                    <>
                      {type !== '1' && type !== '3' && (
                        <option key={type} value={type}>
                          {typeEnum[type]}
                        </option>
                      )}
                    </>
                  )}
                </>
              ))}
            </Form.Control>
          </Col>

          {(filter.type === 1 || filter.type === 3) && global && (
            <>
              <Col className='searchFilterCol'>
                <Form.Label className='filterLabel'>
                  {typeEnum[filter.type]}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                  onChange={handleFilterSearch}
                  value={filter.searchValue}
                ></Form.Control>
              </Col>
              <Button
                type='button'
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                disabled={filter.searchValue === ''}
                onClick={handleSubmitSearch}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}

          {filter.type === 2 && (
            <>
              <Col className='searchFilterCol'>
                <Form.Label className='filterLabel'>
                  {typeEnum[filter.type]}
                </Form.Label>
                <Form.Control
                  as='select'
                  placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                  onChange={handleFilterSearch}
                  value={filter.searchValue}
                >
                  <option key={0}>Escolha uma opção</option>
                  <option key={1} value={'Problemas'}>
                    Problemas
                  </option>
                  <option key={2} value={'Sugestões'}>
                    Sugestões
                  </option>
                  <option key={3} value={'Outros'}>
                    Outros
                  </option>
                </Form.Control>
              </Col>
              <Button
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                onClick={handleSubmitSearch}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}
          {filter.type === 4 && (
            <>
              <Col className='searchFilterCol'>
                <Form.Label className='filterLabel'>
                  {typeEnum[filter.type]}
                </Form.Label>
                <Form.Control
                  as='select'
                  placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                  onChange={handleFilterSearch}
                  value={filter.searchValue}
                >
                  <option key={0}>Escolha uma opção</option>
                  <option key={1} value={'Aberto'}>
                    Aberto
                  </option>
                  <option key={2} value={'Em Andamento'}>
                    Em Andamento
                  </option>
                  <option key={3} value={'Atendido'}>
                    Atendido
                  </option>
                </Form.Control>
              </Col>
              <Button
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                onClick={handleSubmitSearch}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
}
