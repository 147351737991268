import React, { useEffect, useState } from 'react';
import imageEdit from '../../assets/img/iconEdit.png';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import './ClientImage.scss';

export default function ClientImage() {
  const editImageProfile = document.getElementById('btnEdit');
  const clientImage = document.querySelector('.imageClient');
  const [image, setImage] = useState();
  const { id } = useParams();
  const updateClientPage = window.location.pathname.substring(0, 15);
  const createClientPage = window.location.pathname.substring(0, 11);

  useEffect(() => {
    async function getClientImage() {
      return await api.get('/clientImage/' + id);
    }
    getClientImage().then((response) => {
      setImage(response.data.response);
    });
    if (image !== undefined && image.length !== 0) changeClientImage();
  });

  function changeClientImage() {
    clientImage.style.backgroundImage = `url('${image}')`;
  }

  function handleOnClick(evt) {
    if (!(evt.target && evt.target.files && evt.target.files.length > 0)) {
      return;
    }

    let r = new FileReader();
    r.onload = async function () {
      const base64String = r.result;
      clientImage.style.backgroundImage = `url('${base64String}')`;
      if (createClientPage === '/newclients')
        localStorage.setItem('clientImage', base64String);
      await api.put('/clientImage', {
        id: id,
        clientImage: base64String,
      });
    };

    r.readAsDataURL(evt.target.files[0]);
  }

  function addEditComponent() {
    editImageProfile.style.display = 'flex';
  }

  function removeEditComponent() {
    editImageProfile.style.display = 'none';
  }

  return (
    <>
      <div
        className='imageClient'
        onMouseOver={
          updateClientPage === '/clients/update' ||
          createClientPage === '/newclients'
            ? addEditComponent
            : undefined
        }
        onMouseLeave={
          updateClientPage === '/clients/update' ||
          createClientPage === '/newclients'
            ? removeEditComponent
            : undefined
        }
      >
        {updateClientPage === '/clients/update' ||
        createClientPage === '/newclients' ? (
          <div className='overlay-clients'>
            <div id='btnEdit'>
              <label className='custom-file-upload'>
                <input
                  type='file'
                  accept='image/png, image/jpeg'
                  className='image-insert'
                  onChange={handleOnClick}
                />
                <img
                  href='file'
                  src={imageEdit}
                  alt='ícone de alteração de perfil'
                  width='20px'
                  height='20px'
                />
              </label>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className='line' />
    </>
  );
}
