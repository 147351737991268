import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { ArrowLeft, Clipboard, Link45deg } from 'react-bootstrap-icons';

import { Toast } from '../../components/Toast/Toast';
import api from '../../services/api';
import './RegisterClient.scss';
// import Buttons from "../../components/Button/Button";
import { copyToClipBoard } from '../../services/Global/clipboard/Clipboard';
import {
  notifySuccess,
  notifyError,
} from '../../components/Toast/ToastController';
import axios from 'axios';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { useHistory } from 'react-router-dom';
import ClientImage from '../../components/ClientImage/ClientImage';

export default function NewClients() {
  // codigo que eu Genival criei
  const history = useHistory();
  let clientImage;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [cnpjData, setCnpj] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [enablePlugNota, setEnablePlugNota] = useState(false);
  const [limitRequestCallApi, setLimitRequestCallApi] = useState('');
  const [organizationId, setOrganizationId] = useState('');

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [dataIntegrations, setDataIntegrations] = useState({});
  const [dataIntegrationsKey, setDataIntegrationsKey] = useState([]);

  const [clientID, setClientID] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [grantToken, setGrantToken] = useState('');
  const [linkgranttoken, setLinkgranttoken] = useState('');

  const [accountToken, setAccountToken] = useState('');
  const [bankAccountToken, setBankAccountToken] = useState('');

  const [statesBrazil, setStatesBrazil] = useState([]);

  const formatCPFCNPJ = (cpfCnpj) => {
    const cpfFormatted = cpfCnpj.replace(/\D+/g, '');
    if (cpf.isValid(cpfCnpj)) return setCnpj(cpf.format(cpfCnpj));
    if (cnpj.isValid(cpfCnpj)) return setCnpj(cnpj.format(cpfCnpj));
    return setCnpj(cpfFormatted);
    // if (cpfCnpj.length === 11) {
    //   if (!cpf.isValid(cpfCnpj)) {
    //     setCnpj(cpfFormatted)
    //   }

    //   if (cpf.isValid(cpfCnpj)) {
    //     setCnpj(cpf.format(cpfCnpj))
    //   }

    // } else if (cpfCnpj.length === 14) {
    //   if (!cnpj.isValid(cpfCnpj)) {
    //     setCnpj(cpfFormatted)
    //   }
    //   if (!cnpj.isValid(cpfCnpj)) {
    //     setCnpj(cnpj.format(cpfCnpj))
    //   }

    // } else {
    //   setCnpj(cpfFormatted)
    // }
  };

  const getStatesIBGE = async () => {
    const url = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';
    const response = await axios.get(url);
    const data = response.data;
    const states = data.map((item) => {
      return item.sigla;
    });
    const statesInOrder = states.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    return setStatesBrazil(statesInOrder);
  };

  const validateData = async () => {
    if (!name)
      return { error: 'É necessário digitar um responsável para prosseguir' };
    if (!companyName)
      return {
        error: 'É necessário digitar o nome da empresa para prosseguir',
      };
    if (!email)
      return { error: 'É necessário digitar um email válido para prosseguir' };
    if (!city)
      return { error: 'É necessário digitar uma cidade para prosseguir' };
    if (!cpf.isValid(cnpjData) && !cnpj.isValid(cnpjData))
      return { error: 'O cpf ou cnpj digitado não é válido' };
    if (limitRequestCallApi <= 0)
      return {
        error:
          'É necessário adicionar um valor para número máximo de notas a serem emitidas',
      };
    if (state.length === 0)
      return { error: 'É necessário escolher um estado para prosseguir' };
    if (!organizationId)
      return {
        error:
          'É necessário preencher o campo id da organização zohobooks para prosseguir',
      };
    if (!clientID)
      return {
        error: 'É necessário preencher o campo Client id para prosseguir',
      };
    if (!clientSecret)
      return {
        error: 'É necessário preencher o campo Client Secret para prosseguir',
      };
    if (!grantToken)
      return {
        error: 'É necessário preencher o campo Grant Token para prosseguir',
      };
    return true;
  };

  async function handleAddClient() {
    const data = {
      name: name,
      clientImage: clientImage,
      email: email,
      companyName: companyName,
      cnpj: cnpjData,
      state: state,
      city: city,
      integration: {
        plugNotas: {
          enable: enablePlugNota, //Enotas ativo
          countCallApi: 0,
          limitRequestCallApi: limitRequestCallApi, //Máximo de notas a serem emitidas
        },
        zohobooks: {
          organizationId: organizationId, //ID da organização do Zoho Book
          apikey: '', //Token do Zoho Books
        },
        billet: {
          accountToken: accountToken,
          bankAccountToken: bankAccountToken,
          bankCharges: '',
          enable: false,
        },
      },
      otherIntegrations: {
        clientId: clientID,
        clientSecret: clientSecret,
        greatToken: grantToken,
      },
    };

    try {
      await api
        .post('/client', data)
        .then(
          (response) => {
            window.location.href = '/clients';
          },
          (error) => {
            const response = translateMsg(error.response.data.error);
            toastMsgError(response);
          }
        )
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      toast(false);
    }
  }

  const translateMsg = (msg) => {
    let msgTranslated = '';
    switch (msg) {
      case 'invalid_client':
        msgTranslated = 'Grant token inválido';
        break;
      default:
        msgTranslated = msg;
    }

    return msgTranslated;
  };

  const toastMsgError = (msg) => {
    return notifyError(msg);
  };

  const valueredirect = `${process.env.REACT_APP_URL_GRANT_TOKEN}`;

  const toast = (opcao) => {
    const loginSuccessful = opcao;
    if (loginSuccessful) {
      return notifySuccess('Cliente criado com sucesso');
    } else {
      return notifyError('Credencias inválidas');
    }
  };

  const handleSubmit = async (event) => {
    clientImage = localStorage.getItem('clientImage');
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    const dataValid = await validateData();
    if (dataValid.error) return notifyError(dataValid.error);

    handleAddClient();
  };

  useEffect(() => {
    const defineDataIntegrations = () => {
      const dataIntegration = {
        stepone: {
          title: '1°',
          teste: 'Acesse o link e faça o login.',
          links: ['https://api-console.zoho.com/'],
        },
        steptwo: {
          title: '2°',
          teste:
            'Crie um cliente Server-based Applications, no campo Authorized Redirect URIs, use o link abaixo.',
          links: [`${valueredirect}`],
        },
        stepthree: {
          title: '3°',
          teste:
            'Depois de criado, copie e cole os seguintes campos Client ID e Client Secret no Zplugin.',
        },
        stepfour: {
          title: '4°',
          teste: 'Abra uma guia, clique no Link para criação do Grant Token.',
        },
        stepfive: {
          title: '5°',
          teste: 'Aceite os termos da zoho books.',
        },
        stepsix: {
          title: '6°',
          teste: 'Copie o link e cole no campo Grant Token.',
        },
      };
      const dataKeys = Object.keys(dataIntegration);
      setDataIntegrations(dataIntegration);
      setDataIntegrationsKey(dataKeys);
    };
    defineDataIntegrations();

    getStatesIBGE();
  }, [valueredirect]);

  const copyLinkIntegration = (link) => {
    copyToClipBoard(link);
    notifySuccess('Link copiado para área de transferência');
  };

  const copyLink = (link) => {
    return copyLinkIntegration(link);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const redirect = process.env.REACT_APP_URL_GRANT_TOKEN;

  const onBlurIDClient = (env) => {
    const { value } = env.target;

    const response = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBooks.fullaccess.all&client_id=${value}&state=Homolog&response_type=code&redirect_uri=${redirect}&access_type=offline`;

    return response;
  };

  return (
    <>
      <Container className='NewClients'>
        <Row
          style={{
            padding: '0px 15px',
          }}
        >
          <Col
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            className='title'
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowLeft
                style={{
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
                size={36}
                onClick={() => {
                  history.goBack();
                }}
              />
              <h2>Criar Cliente</h2>
            </div>
            <Button
              onClick={handleSubmit}
              style={{
                width: 'auto',
              }}
              variant='primary'
              type='submit'
              className='subimit-btn'
            >
              Cadastrar cliente
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className='form-block'>
                <ClientImage />
                <h3>Dados pessoais</h3>
                <Form.Row>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label>Nome do Responsável</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='usuario1'
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um nome do responsável
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridCompany'>
                      <Form.Label>Nome da Empresa</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Nome completo da empresa'
                        required
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha o nome da empresa valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='usuario@zplugin.com'
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um email valido
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>CPF/CNPJ</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='00.000.000/0000-00'
                        value={cnpjData}
                        required
                        onChange={(e) => formatCPFCNPJ(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um CPF ou CNPJ valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridState'>
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        className='selectState'
                        as='select'
                        onChange={(event) => {
                          setState(event.target.value);
                        }}
                      >
                        <option></option>
                        {statesBrazil.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Estado valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCity'>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Nome da cidade'
                        required
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma cidade valida.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div className='form-block'>
                <h3>Integração Zohobooks</h3>
                <Form.Row>
                  <Col sm={12} md={6} xl={8}>
                    <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                      <Form.Label>ID da organização Zoho Books</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={organizationId}
                        onChange={(e) => setOrganizationId(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} xl={4}>
                    <Form.Group as={Col} controlId='formGridMaxNotasEmitidas'>
                      <Form.Label>Limite de notas emitidas</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder=''
                        value={limitRequestCallApi}
                        onChange={(e) => setLimitRequestCallApi(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={6} xl={4}>
                    <Form.Group as={Col} controlId='formGridTokenZohoBooks'>
                      <Form.Label>Client ID</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={clientID}
                        onBlur={(env) => setLinkgranttoken(onBlurIDClient(env))}
                        onChange={(e) => setClientID(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} xl={4}>
                    <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                      <Form.Label>Client Secret</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={clientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                        required
                      />
                      {clientID !== '' && clientSecret !== '' && (
                        <a
                          id='guide'
                          key={linkgranttoken}
                          onClick={() => {
                            window.open(linkgranttoken);
                          }}
                        >
                          Clique aqui para criar o Grant Token
                        </a>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={12} xl={4}>
                    <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                      <Form.Label>Grant Token</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={
                          !clientID || !clientSecret
                            ? 'Requisitos não preenchidos'
                            : grantToken
                        }
                        onChange={(e) => setGrantToken(e.target.value)}
                        required
                        disabled={!clientID || !clientSecret}
                      />
                      <Link45deg color='gray' size={20} />
                      <a id='guide' onClick={handleShow}>
                        Guia para criação do Grant Token
                      </a>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <h3>Integração Boleto Cloud</h3>
                <Form.Row>
                  <Col sm={12} md={6} xl={6}>
                    <Form.Group as={Col} controlId='formGridAccountToken'>
                      <Form.Label>Token de api do Boleto Cloud</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={accountToken}
                        onChange={(e) => setAccountToken(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                    <Form.Group as={Col} controlId='formGridBankAccountToken'>
                      <Form.Label>Token da Conta Bancaria</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={bankAccountToken}
                        onChange={(e) => setBankAccountToken(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} xl={4} className='plugnotas-checkbox'>
                    <Form.Group id='formGridCheckbox'>
                      <Form.Check type='checkbox' id=''>
                        <Form.Check.Input
                          type='checkbox'
                          value={enablePlugNota}
                          onChange={(e) => setEnablePlugNota(e.target.checked)}
                        />
                        <Form.Check.Label>PlugNotas ativo</Form.Check.Label>
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Guia de criação do Grant Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataIntegrationsKey.map((integration) => {
            return (
              <Row className='modal-row' key={integration}>
                <Col>
                  <h3>{dataIntegrations[integration].title}</h3>
                  <p>{dataIntegrations[integration].teste}</p>
                  {dataIntegrations[integration].links
                    ? dataIntegrations[integration].links.map((link) => {
                        return (
                          <div
                            key={link}
                            className='modal-row__line'
                            onClick={() => {
                              if (
                                link == 'https://admin.zplugin.com/copytoken'
                              ) {
                                copyLink(link);
                                return;
                              }
                              window.open(link);
                            }}
                          >
                            <Clipboard color='#4274e3' size={16} />
                            &nbsp;
                            <div style={{ cursor: 'pointer' }}>
                              {'Clique aqui.'}
                            </div>
                          </div>
                        );
                      })
                    : ''}
                </Col>
              </Row>
            );
          })}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Fechar
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast />
    </>
  );
}
