import React, { useState } from 'react';
import { Button, Modal, Image } from 'react-bootstrap';
import { EyeFill } from 'react-bootstrap-icons';
import logo from '../../../assets/img/logozplugin.png';

export default function ModalLog({
  logClientName,
  logDate,
  logTime,
  logTypeAction,
  logAction,
  logMessage,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button size='sm' className='ButtonOptions' onClick={handleShow}>
        Ver
        <EyeFill style={{ marginLeft: '5px' }} color='white' size={17} />
      </Button>
      <Modal show={show} size='lg' onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='d-flex justify-content-start flex-fill'>
            <Image src={logo} width={100} height={50} />
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            wordWrap: 'break-word',
          }}
        >
          <p>Usuário: {logClientName}</p>
          <p>
            Data: {logDate} às {logTime}
          </p>
          <p>Tipo da Ação: {logTypeAction}</p>
          <p>Ação: {logAction}</p>
          <p>Log gerado: {logMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
