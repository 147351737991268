import React, { useContext } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import { ArrowClockwise, EyeFill } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { formatDate } from '../../../helpers/FormatDate';
import { formatValue } from '../../../helpers/FormatValue';
import useNfe from '../../../hooks/use-nfe';

import FilterNfe from '../filterNfe/FilterNfe';
import './DashNfe.scss';
export default function DashNfe() {
  const history = useHistory();
  const global = useContext(GlobalContext);
  const {
    nfes,
    loadingNfes,
    load,
    setLoad,
    setNfes,
    setLoadingNfes,
    setOrgId,
    orgId,
  } = useNfe();

  return (
    <div className='ContainerDashNfe'>
      <div className='bodyColHead'>
        <h3>Últimas NF-e emitidas</h3>
        <ArrowClockwise
          style={{ cursor: 'pointer' }}
          size={26}
          color='#4274e3'
          onClick={() => {
            setLoad(!load);
          }}
        />
      </div>

      <Table responsive striped hover>
        <thead>
          <tr>
            <FilterNfe
              handleSetNfes={setNfes}
              handleSetLoadingNfes={setLoadingNfes}
              handleSetOrgId={setOrgId}
            />
          </tr>
          <tr>
            <th>Cliente</th>
            <th className='tableEmpresa'>ID Nota</th>
            <th>ID Books</th>
            <th>Status</th>
            <th>Hora de Criação</th>
            <th>Valor</th>
            <th className='tableOptions'>Opções</th>
          </tr>
        </thead>
        {!loadingNfes && (
          <tbody>
            {nfes.map((note) => (
              <tr
                key={note?.idPlugnotas}
                onClick={() => history.push(`/nfe/${note?._id}`)}
              >
                <td>{note?.clientName}</td>
                <td>{note?.idPlugnotas}</td>
                <td>{note?.idInvoiceZohoBook} </td>
                {note.statusPlugnotas === 'REJEITADO' && (
                  <td className='text-danger'>{note?.statusPlugnotas}</td>
                )}
                {note.statusPlugnotas === 'CONCLUIDO' && (
                  <td className='text-success'>{note?.statusPlugnotas}</td>
                )}
                {note.statusPlugnotas === 'Nota(as) em processamento' && (
                  <td className='text-warning'>{note?.statusPlugnotas}</td>
                )}
                {note.statusPlugnotas === 'CANCELADO' && (
                  <td className='text-danger'>{note?.statusPlugnotas}</td>
                )}
                {note.statusPlugnotas === 'EXCEPTION' && (
                  <td className='text-primary'>{note?.statusPlugnotas}</td>
                )}

                <td>{formatDate(note?.dataHoraCriacao)}</td>

                <td>{formatValue(note?.valor)}</td>
                <td>
                  <Button
                    size='sm'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Ver
                    <EyeFill
                      style={{ marginLeft: '5px' }}
                      color='white'
                      size={17}
                    />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {!loadingNfes && nfes.length === 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '50px',
            height: '100%',
          }}
        >
          {global.userType === 2 && orgId === ''
            ? 'Escolha uma empresa'
            : 'Nenhuma NF-e encontrada'}
        </div>
      )}
      {loadingNfes && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            padding: '50px',
          }}
        >
          <Spinner animation='border' variant='primary' />
        </div>
      )}
    </div>
  );
}
