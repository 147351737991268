import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from '../pages/login/Login';

import Clients from '../pages/clients/Clients';
import RegisterClient from '../pages/register-client/RegisterClient';
import UpdateClient from '../pages/clients/update-clients/UpdateClients';
import SeeClient from '../pages/clients/see-clients/SeeClients';

import RegisterCompany from '../pages/company/new/RegisterCompany';
import UpdateCompany from '../pages/company/update/UpdateCompany';
import SeeCompany from '../pages/company/see/SeeCompany';

import NewUser from '../pages/user/New/NewUsers';
import User from '../pages/user/Users';
import SeeUsers from '../pages/user/See/SeeUsers';
import UpdateUsers from '../pages/user/Update/UpdateUsers';

import Certificate from '../pages/create-certificate/Certificate';

import CopyToken from '../pages/copytoken/CopyToken';

import Nfe from '../pages/nfe/Nfe';
import SeeNfe from '../pages/nfe/see-nfe/SeeNfe';

import Nfse from '../pages/nfse/Nfse';
import SeeNfse from '../pages/nfse/see-nfse/SeeNfse';

import PrivateRoute from '../services/Auth/uAuth';
import Home from '../pages/home/Home';
import Loading from '../pages/Loading/Loading';
import Feedback from '../pages/feedbacks/Feedback';
import Log from '../pages/log/Log'

export default function Router() {
  return (
    <Switch>
      <Route path='/' component={Login} exact />
      <PrivateRoute path='/home' component={Home} isHome={true} exact />
      <PrivateRoute path='/feedback' component={Feedback} exact />

      {/* Empresa */}
      <PrivateRoute
        path='/newcompanies/:id/:idOng'
        component={RegisterCompany}
        exact
      />
      <PrivateRoute
        path='/updatecompanies/:id'
        component={UpdateCompany}
        exact
      />
      <PrivateRoute path='/seecompanies/:id' component={SeeCompany} exact />

      {/* Cliente */}
      <PrivateRoute path='/clients' component={Clients} exact />
      <PrivateRoute path='/clients/update/:id' component={UpdateClient} exact />
      <PrivateRoute path='/newclients' component={RegisterClient} exact />
      <PrivateRoute path='/clients/see/:id' component={SeeClient} exact />

      {/* usuarios */}

      <PrivateRoute
        path='/newuser'
        component={NewUser}
        acessLevelPermited={['1', '3']}
        exact
      />
      <PrivateRoute
        path='/users'
        component={User}
        acessLevelPermited={['1', '3']}
        exact
      />
      <PrivateRoute path='/users/see/:id' component={SeeUsers} exact />

      <PrivateRoute path='/users/update/:id' component={UpdateUsers} exact />

      {/* Certificado */}
      <PrivateRoute path='/certificate' component={Certificate} exact />

      {/* Token ZohoBooks */}
      <Route path='/copytoken' component={CopyToken} exact />
      {/* loading */}
      <PrivateRoute path='/loading' component={Loading} exact />
      {/* Notas */}
      <PrivateRoute path='/nfe' component={Nfe} exact />

      <PrivateRoute path='/nfe/:nfeId' component={SeeNfe} exact />

      <PrivateRoute path='/nfse' component={Nfse} exact />

      <PrivateRoute path='/nfse/:nfseId' component={SeeNfse} exact />

      {/* Logs */}
      <PrivateRoute path='/log' acessLevelPermited={['3']} component={Log} exact />
    </Switch>
  );
}
