import React, { useContext, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../contexts/GlobalStorage';

export default function Loading() {
  const history = useHistory();
  const global = useContext(GlobalContext);
  useEffect(() => {
    setTimeout(() => {
      global.setMounted(true);
      history.push('/home');
    }, 2000);
  });
  return (
    <div>
      <div
        style={{
          height: '80vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Spinner animation='border' variant='primary' />
      </div>
    </div>
  );
}
