import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';

import { Toast } from '../../../components/Toast/Toast';
import { notifyError } from '../../../components/Toast/ToastController';
import IconButton from '../../../components/IconButton/IconButton';

import {
  getEnterpriseByCnpj,
  enterpriseInitialData,
} from '../../../services/Manager/MongoDB/Enterprise/EnterpriseManager';
import {
  getNfeById,
  nfeInitialData,
} from '../../../services/Manager/MongoDB/Nfe/NfeManager';
import {
  getPdfById,
  getPlugnotasById,
  getXmlById,
  plugnotasInitialData,
} from '../../../services/Manager/Plugnotas/Nfe/PlugnotasNfeManager';

import './SeeNfe.scss';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';

export default function UpdateClients() {
  const { nfeId } = useParams();
  const history = useHistory();
  const [nfe, setNfe] = useState(nfeInitialData);
  const [enterprise, setEnterprise] = useState(enterpriseInitialData);
  const [plugnotas, setPlugnotas] = useState(plugnotasInitialData);

  useEffect(() => {
    const getAsyncData = async () => {
      try {
        const nfeData = await getNfeById(nfeId);
        setNfe(nfeData);
      } catch (e) {
        notifyError('Erro ao buscar NF-e no servidor.');
      }
    };

    getAsyncData();
  }, [nfeId]);

  useEffect(() => {
    if (nfe.idPlugnotas) {
      const getAsyncData = async () => {
        try {
          const plugnotasData = await getPlugnotasById(nfe.idPlugnotas);

          setPlugnotas(plugnotasData);
        } catch (e) {
          notifyError('Erro ao buscar NF-e no plugnotas.');
        }
      };

      getAsyncData();
    }
  }, [nfe]);

  useEffect(() => {
    if (plugnotas.emitente) {
      const getAsyncData = async () => {
        try {
          const enterpriseData = await getEnterpriseByCnpj(plugnotas.emitente);
          setEnterprise(enterpriseData);
        } catch (e) {
          notifyError('Erro ao buscar o emitente no servidor.');
        }
      };

      getAsyncData();
    }
  }, [plugnotas]);

  const handleDownloadPDF = () => {
    try {
      getPdfById(nfe.idPlugnotas);
    } catch (e) {
      notifyError('Não foi possível encontrar o PDF para esta nota.');
    }
  };

  const handleDownloadXML = () => {
    try {
      getXmlById(nfe.idPlugnotas);
    } catch (e) {
      notifyError('Não foi possível encontrar o XML para esta nota.');
    }
  };
  return (
    <>
      <Container className='NewClients Container'>
        <Row>
          <Col className='title'>
            <ArrowLeft
              style={{
                cursor: 'pointer',
                marginRight: '10px',
              }}
              size={36}
              onClick={() => history.push('/nfe')}
            />
            <h2>Visualização de NF-e</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form noValidate validated={false}>
              <div className='form-block'>
                <h3>Informações do Emitente</h3>
                <Form.Row>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label>Razão Social</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.razaoSocial}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>CNPJ</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.cpfCnpj}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridState'>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.email}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Inscrição Estadual</Form.Label>
                      <Form.Control
                        type='email'
                        value={enterprise.inscricaoEstadual}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>Inscrição Municipal</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.inscricaoMunicipal}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridState'>
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.endereco.estado}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCity'>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.endereco.codigoCidade}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div className='form-block'>
                <h3>Informações Zoho Books</h3>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                      <Form.Label>Módulo</Form.Label>
                      <Form.Control
                        type='text'
                        value={nfe.moduleZohoBook}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} xl={4}>
                    <Form.Group as={Col} controlId='formGridMaxNotasEmitidas'>
                      <Form.Label>ID Books</Form.Label>
                      <Form.Control
                        type='text'
                        value={nfe.idInvoiceZohoBook}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>

              <div className='form-block'>
                <h3>Informações da NF-e</h3>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Destinatário</Form.Label>
                      <Form.Control value={plugnotas.destinatario} readOnly />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>Data da emissão</Form.Label>
                      <Form.Control
                        type='text'
                        value={plugnotas.emissao}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label>ID Nota</Form.Label>
                      <Form.Control
                        type='text'
                        value={plugnotas.chave}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Status</Form.Label>
                      <Form.Control value={plugnotas.status} readOnly />
                    </Form.Group>
                  </Col>
                  <Col sm={18} lg={9}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label>Mensagem de retorno</Form.Label>
                      <Form.Control
                        type='text'
                        value={plugnotas.mensagem || plugnotas.erro}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Nº</Form.Label>
                      <Form.Control value={plugnotas.numero} readOnly />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>Data da autorização</Form.Label>
                      <Form.Control
                        type='text'
                        value={
                          plugnotas.status === 'REJEITADO'
                            ? 'Não autorizado'
                            : plugnotas.dataAutorizacao
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row className='buttonRow'>
                  <IconButton onClick={handleDownloadPDF}>
                    Baixar PDF
                  </IconButton>
                  <IconButton onClick={handleDownloadXML}>
                    Baixar XML
                  </IconButton>
                </Form.Row>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <Toast />
    </>
  );
}
