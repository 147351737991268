import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../contexts/GlobalStorage';

export default function useCertificate() {
  const global = useContext(GlobalContext);
  const [loadingCertificate, setLoadingCertificate] = useState(false);
  const [qntCertificateShow, setQntCertificateShow] = useState(10);
  const [dataCertificate, setDataCertificate] = useState([]);
  const [dataCertificateShow, setDataCertificateShow] = useState([]);
  const [back, setBack] = useState([]);
  const [paginators, setPaginators] = useState([]);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    async function allCertificado() {
      setLoadingCertificate(true);
      if (global.userType === 2) {
        setDataCertificateShow(global.dataCertificateUser);
      } else {
        setDataCertificate(global.dataCertificate);
        setBack(global.dataCertificate);
        let paginators = [];
        for (
          let i = 0;
          i < global.dataCertificate.length / qntCertificateShow;
          i++
        ) {
          paginators.push('');
        }
        setPaginators(paginators);
        setDataCertificateShow(
          global.dataCertificate.filter(
            (item, i) =>
              i < qntCertificateShow * 1 &&
              i >= qntCertificateShow * 1 - qntCertificateShow
          )
        );
        setLoadingCertificate(false);
      }
      setLoadingCertificate(false);
    }

    allCertificado();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    global.dataCertificate,
    global.dataCertificateUser,
    load,
    qntCertificateShow,
  ]);
  return {
    loadingCertificate,
    setLoadingCertificate,
    qntCertificateShow,
    setQntCertificateShow,
    dataCertificate,
    setDataCertificate,
    dataCertificateShow,
    setDataCertificateShow,
    back,
    setBack,
    paginators,
    setPaginators,
    load,
    setLoad,
  };
}
