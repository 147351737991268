import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Modal,
  ProgressBar,
} from 'react-bootstrap';
import { PencilSquare, Trash } from 'react-bootstrap-icons';

import './Users.scss';
import api from '../../services/api';
import { getIName } from '../../services/Auth/Login';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../contexts/GlobalStorage';
import FilterUsers from '../_components/filterUsers/FilterUsers';
import { BiPlusMedical } from 'react-icons/bi';
import ModalConfirm from '../_components/modalConfirm/ModalConfirm';
import LimitFilterButton from '../../components/LimitFilterButton/LimitFilterButton';
import useUsers from '../../hooks/use-users';
import { formatDate } from '../../helpers/FormatDate';
import defaultImage from '../../assets/img/profileImage.png';
export default function Users() {
  let history = useHistory();
  const global = useContext(GlobalContext);
  const {
    qntUsersShow,
    setQntUsersShow,
    dataUser,
    setDataUser,
    dataUserShow,
    setDataUserShow,
    back,
    paginators,
    load,
    setLoad,
  } = useUsers();

  const [paginator, setPaginator] = useState(1);

  const [show, setShow] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [onConfirmModal, setOnConfirmModal] = React.useState(false);
  const [idFeedbackToDelete, setIdFeedbackToDelete] = React.useState('');

  const [integrationPlugNotas] = useState({
    integration: {
      plugNotas: {
        countCallApi: 0,
        enable: false,
        available: 0,
        limitRequestCallApi: 0,
        perCent: 0,
      },
    },
  });

  useEffect(() => {
    if (global.userType === 2) {
      history.push('/home');
    }
  });

  const changePaginator = (index) => {
    const paginatorIndex = index;
    setPaginator(index);

    setDataUserShow(
      dataUser.filter(
        (item, i) =>
          i < qntUsersShow * paginatorIndex &&
          i >= qntUsersShow * paginatorIndex - qntUsersShow
      )
    );
  };

  useEffect(() => {
    if (onConfirmModal) {
      api
        .delete('/users/' + idFeedbackToDelete, {
          data: { superUser: getIName() },
        })
        .then(() => {
          setIdFeedbackToDelete('');
          setOnConfirmModal(false);
          setLoad(!load);
          return;
        })
        .catch(() => {
          setIdFeedbackToDelete('');
          setOnConfirmModal(false);
          return;
        });
    } else {
      setIdFeedbackToDelete('');
      setOnConfirmModal(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirmModal]);

  const handleDelete = async (id) => {
    try {
      setShowConfirmModal(true);
      setIdFeedbackToDelete(id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ModalConfirm
        handleSetShowConfirmModal={setShowConfirmModal}
        handleSetOnConfirmModal={setOnConfirmModal}
        showConfirmModal={showConfirmModal}
        body={'Deseja realmente apagar esse usuário?'}
      />
      <div className='Clients'>
        <Row>
          <Col className='table'>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>
                    <div className='d-flex'>
                      <FilterUsers
                        handleSetDataUserShow={setDataUserShow}
                        data={dataUser}
                        qntUsersShow={qntUsersShow}
                        handleSetDataUser={setDataUser}
                        handleSetLoad={setLoad}
                        load={load}
                        back={back}
                      />
                      <LimitFilterButton
                        handleSetLimit={setQntUsersShow}
                        limit={qntUsersShow}
                      />
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {' '}
                    {(global.userType === 3 ||
                      global.permissions.addUserClient) && (
                      <Button
                        onClick={() => history.push('/newuser')}
                        className='buttonElement'
                        variant='primary'
                        style={{
                          float: 'right',
                        }}
                      >
                        <BiPlusMedical
                          className='iconButton'
                          color='#ffffff'
                          size={13}
                        />
                        Novo
                      </Button>
                    )}
                  </th>
                </tr>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Tipo de Usuários</th>
                  <th>Status</th>
                  <th>Último Login</th>
                  <th>Opções</th>
                </tr>
              </thead>
              <tbody>
                {dataUserShow.map((user) => {
                  return (
                    <tr key={user._id}>
                      <td>
                        <img
                          className='showProfileImage'
                          src={
                            user.profileImage ? user.profileImage : defaultImage
                          }
                        />
                        {user.name}
                      </td>
                      <td>{user.email} </td>
                      <td>
                        {user.usertype === 1 && 'Administrador'}
                        {user.usertype === 2 && 'Cliente'}
                        {user.usertype === 3 && 'Super Administrador'}
                      </td>
                      <td>{user.status}</td>
                      <td>{formatDate(user?.lastLogin)}</td>
                      <td>
                        <div style={{ display: 'flex' }}>
                          {user.usertype !== 3 && global.userType !== 3 && (
                            <>
                              <Button
                                size='sm'
                                onClick={() =>
                                  history.push('/users/update/' + user._id)
                                }
                                className='ButtonOptions'
                              >
                                Editar
                                <PencilSquare
                                  style={{ marginLeft: '5px' }}
                                  color='white'
                                  size={17}
                                />
                              </Button>

                              {global.permissions.deleteUserClient &&
                                user.usertype === 2 && (
                                  <Button
                                    variant='danger'
                                    size='sm'
                                    onClick={() => handleDelete(user._id)}
                                    className='ButtonOptions'
                                  >
                                    Deletar
                                    <Trash
                                      style={{ marginLeft: '5px' }}
                                      color='white'
                                      size={17}
                                    />
                                  </Button>
                                )}
                            </>
                          )}

                          {global.userType === 3 && (
                            <>
                              <Button
                                size='sm'
                                onClick={() =>
                                  history.push('/users/update/' + user._id)
                                }
                                className='ButtonOptions'
                              >
                                Editar
                                <PencilSquare
                                  style={{ marginLeft: '5px' }}
                                  color='white'
                                  size={17}
                                />
                              </Button>
                              <Button
                                variant='danger'
                                size='sm'
                                onClick={() =>
                                  handleDelete(user._id, user.name)
                                }
                                className='ButtonOptions'
                              >
                                Deletar
                                <Trash
                                  style={{ marginLeft: '5px' }}
                                  color='white'
                                  size={17}
                                />
                              </Button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>

        {dataUser.length > qntUsersShow && (
          <Row>
            <Pagination>
              <Pagination.First
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(1);
                }}
              />
              <Pagination.Prev
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(paginator - 1);
                }}
              />

              {paginators.map((item, index) => {
                return (
                  <Pagination.Item
                    key={index}
                    onClick={() => changePaginator(index + 1)}
                    active={index + 1 === paginator}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}

              <Pagination.Next
                onClick={() => {
                  changePaginator(paginator + 1);
                }}
                disabled={
                  Math.ceil(dataUser.length / qntUsersShow) <= paginator
                }
              />
              <Pagination.Last
                onClick={() => {
                  changePaginator(Math.trunc(dataUser.length / qntUsersShow));
                }}
                disabled={
                  Math.ceil(dataUser.length / qntUsersShow) <= paginator
                }
              />
            </Pagination>
          </Row>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Estatísticas de uso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h5>{integrationPlugNotas.companyName}</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Integração Plugnotas</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <ProgressBar
                  variant='success'
                  now={integrationPlugNotas.integration.plugNotas.perCent}
                  label={`${integrationPlugNotas.integration.plugNotas.perCent}%`}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                Disponivel:{' '}
                {integrationPlugNotas.integration.plugNotas.limitRequestCallApi}
              </span>
            </Col>
            <Col>
              <span>
                Utilizadas:{' '}
                {integrationPlugNotas.integration.plugNotas.countCallApi}
              </span>
            </Col>
            <Col>
              <span>
                Restante: {integrationPlugNotas.integration.plugNotas.available}
              </span>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
