import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { EyeFill } from 'react-bootstrap-icons';

export default function ModalFeedback({ feedbackType, feedbackMessage }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button size='sm' className='ButtonOptions' onClick={handleShow}>
        Ver
        <EyeFill style={{ marginLeft: '5px' }} color='white' size={17} />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{feedbackType}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            wordWrap: 'break-word',
          }}
        >
          {feedbackMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
