import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Form, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Toast } from '../../../components/Toast/Toast';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import './seeusers.scss';
import { Chip, Input, InputAdornment } from '@material-ui/core';
import { PlusCircle } from 'react-bootstrap-icons';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { cnpjMask } from '../../../helpers/cnpjMask';

function SeeUsers() {
  const global = useContext(GlobalContext);
  const [validated, setValidated] = useState(false);

  const [typeUser, setTypeUser] = React.useState('');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [cnpjValues, setCnpjValues] = useState([]);
  const [currValue, setCurrValue] = useState('');

  const { id } = useParams();

  useEffect(() => {
    async function allUsers() {
      const response = await api.get('/users/' + id);
      setName(response.data.name);
      setEmail(response.data.email);
      setTypeUser(response.data.usertype);
      response.data.cnpj.forEach((cnpj) => {
        setCnpjValues([...cnpjValues, cnpjMask(cnpj)]);
      });
    }
    allUsers();
  }, [id]);

  const handleTypeUser = (event) => {
    setTypeUser(event.target.value);
  };

  const handleSubmit = (event) => {
    setValidated(true);
  };

  return (
    <>
      <Container className='SeeUsers'>
        <div className='title'>
          <h2>Visualização de usuarios</h2>
        </div>
        <Form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Row>
            <Col>
              <Form.Group as={Col} controlId='formGridName'>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  disabled
                  value={name}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId='formGridEmail'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder=''
                  disabled
                  value={email}
                />
              </Form.Group>
            </Col>
            {typeUser == 2 && (
              <Col>
                <Form.Group as={Col} controlId='formGridCnpj'>
                  <FormControl fullWidth>
                    <Form.Label>CNPJ</Form.Label>
                    <div>
                      {cnpjValues.map((item, index) => (
                        <Chip size='small' onDelete={() => {}} label={item} />
                      ))}
                    </div>

                    <Input
                      value={currValue}
                      type='number'
                      minLength='14'
                      endAdornment={
                        <InputAdornment position='end'>
                          <PlusCircle
                            size={20}
                            color='#4274e3'
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                    {cnpjValues.length === 0 && (
                      <Form.Text className='text-muted'>
                        *Campo obrigatório para usuários do tipo Cliente
                      </Form.Text>
                    )}
                  </FormControl>
                </Form.Group>
              </Col>
            )}
          </Form.Row>
          <Form.Row>
            <Col xs={6}>
              <Form.Label className='teste'>Tipo de Usuário</Form.Label>
              <Form.Group as={Col}>
                <FormControl>
                  <Select
                    disabled
                    className='selectmenu'
                    value={typeUser}
                    onChange={handleTypeUser}
                    placeholder=''
                  >
                    <MenuItem value={1}>
                      <div>Administrador</div>
                    </MenuItem>
                    <MenuItem value={2}>Cliente</MenuItem>
                  </Select>
                </FormControl>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>
              <Form.Group as={Col} controlId='formGridName'>
                <Link to={'/users/update/' + id}>
                  <Button variant='primary' type='submit'>
                    Editar Usuario.
                  </Button>
                </Link>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </Container>
    </>
  );
}

export default SeeUsers;
