import React, { useContext, useState } from 'react';
import { Row, Col, Table, Button, Pagination, Spinner } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { BiPlusMedical } from 'react-icons/bi';
import './Certificate.scss';
import { Toast } from '../../components/Toast/Toast';
import { GlobalContext } from '../../contexts/GlobalStorage';
import { formatDateCertificate } from '../../helpers/formatDateCertificate';
import FilterCertificate from '../_components/filterCertificate/FilterCertificate';
import LimitFilterButton from '../../components/LimitFilterButton/LimitFilterButton';
import useCertificate from '../../hooks/use-certificate';
import ModalCreateCertificate from '../_components/modalCreateCertificate/modalCreateCertificate';
import { cnpjMask } from '../../helpers/cnpjMask';

export default function Users() {
  const global = useContext(GlobalContext);
  const {
    loadingCertificate,
    qntCertificateShow,
    setQntCertificateShow,
    dataCertificate,
    setDataCertificate,
    dataCertificateShow,
    setDataCertificateShow,
    back,
    paginators,
    load,
    setLoad,
  } = useCertificate();

  const [paginator, setPaginator] = useState(1);
  const [show, setShow] = useState(false);
  const [methodSend, setMethodSend] = useState('enviar');
  const [idUpdate, setIdUpdate] = useState('');
  const [certificateName, setCertificateName] = useState('');

  const changePaginator = (index) => {
    const paginatorIndex = index;
    setPaginator(index);

    setDataCertificateShow(
      dataCertificate.filter(
        (item, i) =>
          i < qntCertificateShow * paginatorIndex &&
          i >= qntCertificateShow * paginatorIndex - qntCertificateShow
      )
    );
  };

  const getNameCertificateEdit = (name) => {
    const startName = name.indexOf('CN=') + 3;
    const endName = name.indexOf(':');
    const nameFormatted = name.slice(startName, endName);
    setCertificateName(nameFormatted);
  };

  const clearcertificate = (name) => {
    let response = name.split(',');
    return response[0];
  };

  const openModal = (method, id) => {
    if (method === 'editar') setIdUpdate(id);
    if (method === 'enviar') setIdUpdate('');
    setMethodSend(method);
    setShow(true);
  };

  return (
    <>
      <div className='Clients'>
        <Row>
          <Col className='table'>
            <Table responsive striped hover>
              <thead>
                {global.userType !== 2 && (
                  <tr>
                    <th>
                      {global.userType !== 2 && (
                        <div className='d-flex'>
                          <FilterCertificate
                            handleSetDataCertificateShow={
                              setDataCertificateShow
                            }
                            data={dataCertificate}
                            handleSetDataCertificate={setDataCertificate}
                            handleSetLoad={setLoad}
                            load={load}
                            back={back}
                            qntCertificateShow={qntCertificateShow}
                          />
                          <LimitFilterButton
                            handleSetLimit={setQntCertificateShow}
                            limit={qntCertificateShow}
                          />
                        </div>
                      )}
                    </th>
                    <th></th>
                    <th></th>
                    <th>
                      <Button
                        onClick={() => openModal('enviar', '')}
                        className='buttonElement'
                        variant='primary'
                      >
                        <BiPlusMedical
                          className='iconButton'
                          color='#ffffff'
                          size={13}
                        />
                        Novo
                      </Button>
                    </th>
                  </tr>
                )}

                <tr>
                  <th>CNPJ</th>
                  <th>Nome</th>
                  <th>Vencimento</th>
                  <th
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    Opções{' '}
                    {global.userType === 2 && (
                      <Button
                        style={{
                          marginLeft: '20px',
                        }}
                        onClick={() => openModal('enviar', '')}
                        className='buttonElement'
                        variant='primary'
                      >
                        <BiPlusMedical
                          className='iconButton'
                          color='#ffffff'
                          size={13}
                        />
                        Novo
                      </Button>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataCertificateShow.map((certificate) => {
                  return (
                    <tr key={certificate.id} className='rowCertify'>
                      <td>
                        {cnpjMask(certificate.cnpj) ||
                          certificate.nome.split('=')[1]}
                      </td>
                      <td>{clearcertificate(certificate.nome)}</td>
                      <td>{formatDateCertificate(certificate.vencimento)}</td>
                      <td>
                        <Button
                          size='sm'
                          onClick={() => {
                            openModal('editar', certificate.id);
                            getNameCertificateEdit(certificate.nome);
                          }}
                          style={{ display: 'flex' }}
                        >
                          Editar
                          <PencilSquare
                            style={{ marginLeft: '5px' }}
                            color='white'
                            size={17}
                          />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {!loadingCertificate && dataCertificateShow.length === 0 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '50px',
                  height: '100%',
                }}
              >
                Nenhum certificado encontrado
              </div>
            )}
          </Col>
          {loadingCertificate && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                paddingBottom: '20px',
              }}
            >
              <Spinner animation='border' variant='primary' />
            </div>
          )}
        </Row>
        {dataCertificate.length > qntCertificateShow && (
          <Row>
            <Pagination>
              <Pagination.First
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(1);
                }}
              />
              <Pagination.Prev
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(paginator - 1);
                }}
              />

              {paginators.map((item, index) => {
                return (
                  <Pagination.Item
                    key={index}
                    onClick={() => changePaginator(index + 1)}
                    active={index + 1 === paginator}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}

              <Pagination.Next
                onClick={() => {
                  changePaginator(paginator + 1);
                }}
                disabled={
                  Math.ceil(dataCertificate.length / qntCertificateShow) <=
                  paginator
                }
              />
              <Pagination.Last
                onClick={() => {
                  changePaginator(
                    Math.ceil(dataCertificate.length / qntCertificateShow)
                  );
                }}
                disabled={
                  Math.ceil(dataCertificate.length / qntCertificateShow) <=
                  paginator
                }
              />
            </Pagination>
          </Row>
        )}
      </div>
      <ModalCreateCertificate
        idUpdate={idUpdate}
        show={show}
        handleSetShow={setShow}
        methodSend={methodSend}
        certificateName={certificateName}
      />

      <Toast position='top-center' />
    </>
  );
}
