import React from 'react';
import { Button } from 'react-bootstrap';
import { CheckSquareFill } from 'react-bootstrap-icons';

export default function FeedbackSuccess({ handleReset }) {
  return (
    <div className='feedbackSuccessContainer'>
      <div className='feedbackSuccessContent'>
        <CheckSquareFill className='mb-2' size={36} color='#77B255' />
        <h3>Agradecemos o feedback!</h3>
      </div>
      <Button onClick={handleReset} className='mb-2'>
        Enviar outro
      </Button>
    </div>
  );
}
