import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import {
  Archive,
  Envelope,
  FileEarmarkTextFill,
  Key,
} from 'react-bootstrap-icons';
import {
  notifyError,
  notifySuccess,
} from '../../../components/Toast/ToastController';
import api from '../../../services/api';
import { getIName } from '../../../services/Auth/Login';

export default function ModalCreateCertificate({
  idUpdate,
  show,
  handleSetShow,
  methodSend,
  certificateName,
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileToSend, setFileToSend] = useState({});

  const handleClose = () => handleSetShow(false);

  const toast = (sucess, msg) => {
    if (sucess) {
      return notifySuccess(msg);
    } else {
      return notifyError(msg);
    }
  };

  const onChange = async (e) => {
    let file = e.target.files[0];
    setFileName(file.name);
    setFileToSend(file);
  };

  const validateExtensionCertify = (str) => {
    const validsExtensions = ['pfx', 'p12'];

    const nameArchiveReversed = str.split('').reverse().join('');
    const endExtension = nameArchiveReversed.indexOf('.');
    const extensionReversed = nameArchiveReversed.slice(0, endExtension);
    const extension = extensionReversed
      .split('')
      .reverse()
      .join('')
      .toLowerCase();

    return validsExtensions.indexOf(extension) >= 0;
  };

  async function sendLog(id) {
    const infoLog = {
      clientName: getIName(),
      idCertificate: id,
    };
    api.post('/certificate', infoLog);
  }
  const sendCertifyPlugNotas = () => {
    const url = process.env.REACT_APP_HOST_PLUGNOTAS;
    const key = process.env.REACT_APP_KEY_PLUGNOTAS;
    const boundary = process.env.REACT_APP_BOUNDARY_PLUGNOTAS;

    if (!password || password.length === 0)
      return toast(false, 'É necessário digitar uma senha para prosseguir');
    if (!fileName || fileName.length === 0)
      return toast(
        false,
        'É necessário adicionar um certificado para prosseguir'
      );

    if (!validateExtensionCertify(fileName))
      return toast(false, 'A extensão do arquivo enviado não é válida');

    const form = new FormData();
    form.append('arquivo', fileToSend);
    form.append('senha', password);
    form.append('email', email);

    const options = {
      method: 'POST',
      url: `${url}/certificado`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': key,
        'content-type': `multipart/form-data; boundary=---${boundary}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        sendLog(idUpdate);
        toast(true, 'Certificado adicionado com sucesso');
        handleSetShow(false);
      })
      .catch(function (error) {
        const message = error.response.data.error.message;
        toast(false, message);
      });
  };

  const updateCertifyPlugNotas = () => {
    const url = process.env.REACT_APP_HOST_PLUGNOTAS;
    const key = process.env.REACT_APP_KEY_PLUGNOTAS;
    const boundary = process.env.REACT_APP_BOUNDARY_PLUGNOTAS;

    if (!password || password.length === 0)
      return toast(false, 'É necessário digitar uma senha para prosseguir');
    if (!fileName || fileName.length === 0)
      return toast(
        false,
        'É necessário adicionar um certificado para prosseguir'
      );

    if (!validateExtensionCertify(fileName))
      return toast(false, 'A extensão do arquivo enviado não é válida');

    const form = new FormData();
    form.append('arquivo', fileToSend);
    form.append('senha', password);
    form.append('email', email);

    const options = {
      method: 'PUT',
      url: `${url}/certificado/${idUpdate}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': key,
        'content-type': `multipart/form-data; boundary=---${boundary}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        sendLog(idUpdate);

        toast(true, 'Certificado atualizado com sucesso');
        handleSetShow(false);
      })
      .catch(function (error) {
        const message = error.response.data.error.message;
        toast(false, message);
      });
  };
  return (
    <Modal show={show} onHide={handleClose} size='lg' centered>
      <Modal.Header closeButton className='modalHeader'>
        <FileEarmarkTextFill color='#4274e3' size={25} className='iconHeader' />
        {methodSend === 'enviar' && (
          <Modal.Title className='titleNewCertify'>
            Novo Certificado
          </Modal.Title>
        )}
        {methodSend === 'editar' && (
          <div>
            <Modal.Title className='titleNewCertify'>
              Editar Certificado
            </Modal.Title>
            <Modal.Title className='subtitleNewCertify'>
              {certificateName}
            </Modal.Title>
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Row>
              <Col>
                <Form.Group as={Col} controlId='formGridName'>
                  <div class='label-email'>
                    <Envelope color='#999' size={18} />
                    <Form.Label className='titleInput'>Email:</Form.Label>
                  </div>
                  <Form.Control
                    type='text'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group as={Col} controlId='formGridEmail'>
                  <Key color='#999' size={18} />
                  <Form.Label className='titleInput'>Senha:</Form.Label>
                  <Form.Control
                    type='password'
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Row>
              <Col>
                <label className='uploadFile' for='uploadFile'>
                  <div className='uploadArea'>
                    <Archive color='#4274e3' size={45} />
                    <span className='uploadMsgUpload'>
                      Upload de certificado
                    </span>
                    {fileName.length === 0 && (
                      <span className='uploadMsgOpacity'>
                        Clique para adicionar um arquivo
                      </span>
                    )}
                    {fileName.length > 0 && (
                      <span className='fileName'>{fileName}</span>
                    )}
                  </div>
                </label>
              </Col>
              <Col hidden>
                <input
                  id='uploadFile'
                  type='file'
                  name='file'
                  onChange={(e) => onChange(e)}
                />
              </Col>
            </Form.Row>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='danger'
          onClick={handleClose}
          className='btnsCertificate'
        >
          Cancelar
        </Button>
        {methodSend === 'enviar' && (
          <Button
            variant='primary'
            onClick={sendCertifyPlugNotas}
            className='btnsCertificate'
          >
            Criar
          </Button>
        )}
        {methodSend === 'editar' && (
          <Button
            variante='primary'
            onClick={updateCertifyPlugNotas}
            className='btnsCertificate'
          >
            Atualizar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
