import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import api from '../../../services/api';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './SeeCompany.scss';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';
import useCertificate from '../../../hooks/use-certificate';
import { formatDateCertificate } from '../../../helpers/formatDateCertificate';

function UpdateCompanies() {
  const history = useHistory();
  const { dataCertificate } = useCertificate();
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [cepEndereco, setCepEndereco] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [certificado, setCertificado] = useState(['']);
  const [simplesNacional, setSimplesNacional] = useState(false);
  const [incentivoFiscal, setIncentivoFiscal] = useState(false);
  const [incentivadorCultural, setIncentivadorCultural] = useState(false);
  const [tipoLogradouroEndereco, setTipoLogradouroEndereco] = useState('');
  const [logradouroEndereco, setLogradouroEndereco] = useState('');
  const [numeroEndereco, setNumeroEndereco] = useState('');
  const [complementoEndereco, setComplementoEndereco] = useState('');
  const [tipoBairroEndereco, setTipoBairroEndereco] = useState('');
  const [bairroEndereco, setBairrooEndereco] = useState('');
  const [codigoPaisEndereco, setCodigoPaisEndereco] = useState('');
  const [descricaoPaisEndereco, setDescricaoPaisEndereco] = useState('');
  const [codigoCidadeEndereco, setCodigoCidadeEndereco] = useState('');
  const [descricaoCidadeEndereco, setDescricaoCidadeEndereco] = useState('');
  const [estadoEndereco, setEstadoEndereco] = useState('');
  const [dddTelefone, setDddTelefone] = useState('');
  const [numeroTelefone, setNumeroTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [ativoNfse, setAtivoNfse] = useState(false);
  const [tipoContratoNfse, setTipoContratoNfse] = useState('');
  const [producaoConfigNfse, setProducaoConfigNfse] = useState(false);
  const [serieConfigNfse, setSerieConfigNfse] = useState('');
  const [numeroRpsConfigNfse, setNumeroRpsConfigNfse] = useState('');
  const [loteRpsConfigNfse, setLoteRpsConfigNfse] = useState('');
  const [loginPrefeituraRpsConfigNfse, setLoginPrefeituraRpsConfigNfse] =
    useState('');
  const [senhaPrefeituraRpsConfigNfse, setSenhaPrefeituraRpsConfigNfse] =
    useState('');
  const [envioEmailRpsConfigNfse, setEnvioEmailRpsConfigNfse] = useState(false);
  const [ativoNfe, setAtivoNfe] = useState(false);
  const [tipoContratoNfce, setTipoContratoNfce] = useState('');
  const [producaoConfigNfe, setProducaoConfigNfe] = useState(false);
  const [impressaoFcpConfigNfe, setImpressaoFcpConfigNfe] = useState(false);
  const [impressaoPartilhaConfigNfe, setImpressaoPartilhaConfigNfe] =
    useState(false);
  const [serieConfigNfe, setSerieConfigNfe] = useState('');
  const [numeroConfigNfe, setNumeroConfigNfe] = useState('');
  const [serieConfigNfeEntrada, setSerieConfigNfeEntrada] = useState('');
  const [numeroConfigNfeEntrada, setNumeroConfigNfeEntrada] = useState('');
  const [ativoDfeConfigNfe, setAtivoDfeConfigNfe] = useState(false);
  const [envioEmailConfigNfe, setEnvioEmailConfigNfe] = useState(false);
  const [ativoNfce, setAtivoNfce] = useState(false);
  const [producaoConfigNfce, setProducaoConfigNfce] = useState(false);
  const [serieConfigNfce, setSerieConfigNfce] = useState('');
  const [numeroConfigNfce, setNumeroConfigNfce] = useState('');
  const [envioEmailNfce, setEnvioEmailNfce] = useState(false);
  const [
    idCodigoSegurancaContribuinteConfigSefazNfe,
    setDdCodigoSegurancaContribuinteConfigSefazNfe,
  ] = useState('');
  const [
    codigoSegurancaContribuinteConfigSefazNfe,
    setCodigoSegurancaContribuinteConfigSefazNfe,
  ] = useState('');

  const { id } = useParams();

  const [validated, setValidated] = useState(false);

  const [tax, setTax] = React.useState('');
  const [specialTax, setSpecialTax] = React.useState('0');

  useEffect(() => {
    async function allCompay() {
      const response = await api.get('/enterprise/' + id);

      setCpfCnpj(response.data.cpfCnpj);
      setInscricaoMunicipal(response.data.inscricaoMunicipal);
      setInscricaoEstadual(response.data.inscricaoEstadual);
      setRazaoSocial(response.data.razaoSocial);
      setNomeFantasia(response.data.nomeFantasia);
      setCertificado(response.data.certificado);
      setSimplesNacional(response.data.simplesNacional);
      setTax(response.data.regimeTributario);
      setIncentivoFiscal(response.data.incentivoFiscal);
      setIncentivadorCultural(response.data.incentivadorCultural);
      setSpecialTax(response.data.regimeTributarioEspecial);
      setTipoLogradouroEndereco(response.data.endereco.tipoLogradouro);
      setLogradouroEndereco(response.data.endereco.logradouro);
      setNumeroEndereco(response.data.endereco.numero);
      setComplementoEndereco(response.data.endereco.complemento);
      setTipoBairroEndereco(response.data.endereco.tipoBairro);
      setBairrooEndereco(response.data.endereco.bairro);
      setCodigoPaisEndereco(response.data.endereco.codigoPais);
      setDescricaoPaisEndereco(response.data.endereco.descricaoPais);
      setCodigoCidadeEndereco(response.data.endereco.codigoCidade);
      setDescricaoCidadeEndereco(response.data.endereco.descricaoCidade);
      setEstadoEndereco(response.data.endereco.estado);
      setCepEndereco(response.data.endereco.cep);
      setDddTelefone(response.data.telefone.ddd);
      setNumeroTelefone(response.data.telefone.numero);
      setEmail(response.data.email);
      setAtivoNfse(response.data.nfse.ativo);
      if (response.data.nfse) {
        setTipoContratoNfse(response.data.nfse.tipoContrato);
        setProducaoConfigNfse(response.data.nfse.config.producao);
        setSerieConfigNfse(response.data.nfse.config.rps.serie);
        setNumeroRpsConfigNfse(response.data.nfse.config.rps.numero);
        setLoteRpsConfigNfse(response.data.nfse.config.rps.lote);
        setLoginPrefeituraRpsConfigNfse(
          response.data.nfse.config.prefeitura?.login
        );
        setSenhaPrefeituraRpsConfigNfse(
          response.data.nfse.config.prefeitura?.senha
        );
        setEnvioEmailRpsConfigNfse(response.data.nfse.config.email.envio);
      }
      if (response.data.nfe) {
        setAtivoNfe(response.data.nfe.ativo);
        if (response.data.nfe.config) {
          setProducaoConfigNfe(response.data.nfe.config.producao);
          setImpressaoFcpConfigNfe(response.data.nfe.config.impressaoFcp);
          setImpressaoPartilhaConfigNfe(
            response.data.nfe.config.impressaoPartilha
          );
          setSerieConfigNfe(response.data.nfe.config.serie);
          setNumeroConfigNfe(response.data.nfe.config.numero);
          setSerieConfigNfeEntrada(response.data.nfe.config.serieEntrada);
          setNumeroConfigNfeEntrada(response.data.nfe.config.numeroEntrada);
          setAtivoDfeConfigNfe(response.data.nfe.config.dfe.ativo);
          setEnvioEmailConfigNfe(response.data.nfe.config.email.envio);
        }
      }
      if (response.data.nfce) {
        setAtivoNfce(response.data.nfce.ativo);
        setTipoContratoNfce(response.data.nfce.tipoContrato);
        if (response.data.nfce.config) {
          setProducaoConfigNfce(response.data.nfce.config.producao);
          setSerieConfigNfce(response.data.nfce.config.serie);
          setNumeroConfigNfce(response.data.nfce.config.numero);
          if (response.data.nfce.config.email) {
            setEnvioEmailNfce(response.data.nfce.config.email.envio);
          }
          if (response.data.nfce.config.sefaz) {
            setDdCodigoSegurancaContribuinteConfigSefazNfe(
              response.data.nfce.config.sefaz.idCodigoSegurancaContribuinte
            );
            setCodigoSegurancaContribuinteConfigSefazNfe(
              response.data.nfce.config.sefaz.codigoSegurancaContribuinte
            );
          }
        }
      }
    }

    allCompay();
  }, [dataCertificate, id]);

  const clearcertificate = (name) => {
    let response = name.split(',');
    return response[0];
  };

  return (
    <>
      <Container className='NewClients'>
        <Row
          style={{
            padding: '0px 15px',
          }}
        >
          <Col
            className='title'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowLeft
                style={{
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
                size={36}
                onClick={() => {
                  history.goBack();
                }}
              />
              <h2>Visualizar a Empresa</h2>
            </div>
            <Link to={'/updatecompanies/' + id}>
              {' '}
              <Button variant='primary' className='subimit-btn'>
                Editar empresa
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form noValidate validated={validated}>
              <div id='dados_gerais' className='form-block'>
                <h3>Dados gerais</h3>
                <Form.Row>
                  <Col>
                    <Form.Group as={Col} controlId='formCompanyRazaoSocial'>
                      <Form.Label>Razão Social</Form.Label>
                      <Form.Control
                        type='text'
                        name='razaoSocial'
                        value={razaoSocial}
                        required
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma Razão Social valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId='formCompanyNomeFantasia'>
                      <Form.Label>Nome Fantasia</Form.Label>
                      <Form.Control
                        type='text'
                        name='nomeFantasia'
                        value={nomeFantasia}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma Nome Fantasia valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group as={Col} controlId='formCompanyCPFouCNPJ'>
                      <Form.Label>CPF / CNPJ</Form.Label>
                      <Form.Control
                        type='text'
                        name='cpfCnpj'
                        placeholder=''
                        value={cpfCnpj}
                        maxLength='14'
                        required
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma CPF / CNPJ valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      as={Col}
                      controlId='formCompanyInscricaoEstadual'
                    >
                      <Form.Label>Inscrição Estadual</Form.Label>
                      <Form.Control
                        type='text'
                        name='inscricaoEstadual'
                        placeholder=''
                        value={inscricaoEstadual}
                        maxLength='12'
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma Inscrição Estadual valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      as={Col}
                      controlId='formCompanyInscricaoMunicipal'
                    >
                      <Form.Label>Inscrição Municipal</Form.Label>
                      <Form.Control
                        type='text'
                        name='inscricaoMunicipal'
                        value={inscricaoMunicipal}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma Inscrição Municipal valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label className='titlefromdropdraw'>
                      Certificado
                    </Form.Label>
                    <Form.Group as={Col}>
                      <FormControl>
                        <Select
                          value={certificado}
                          onChange={(e) => setCertificado(e.target.value)}
                          className='selectmenu'
                          disabled
                        >
                          {dataCertificate.map((response) => (
                            <MenuItem value={response.id}>
                              <div className='corMenu'>
                                {clearcertificate(response.nome)
                                  .split('=')[1]
                                  .split(' ', 3)
                                  .join(' ') +
                                  ' ' +
                                  formatDateCertificate(response.cadastro)}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label className='titlefromdropdraw'>
                      Regime Tributario
                    </Form.Label>
                    <Form.Group as={Col}>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          value={tax}
                          placeholder=''
                          disabled
                        >
                          <MenuItem value={0}>
                            <div className='corMenu'>Nenhum</div>
                          </MenuItem>
                          <MenuItem value={1}>
                            <div className='corMenu'>Simples Nacional</div>
                          </MenuItem>
                          <MenuItem value={2}>
                            <div className='corMenu'>
                              Simples Nacional - Excesso
                            </div>
                          </MenuItem>
                          <MenuItem value={3}>
                            <div className='corMenu'>
                              Normal - Lucro Presumido
                            </div>
                          </MenuItem>
                          <MenuItem value={4}>
                            <div className='corMenu'>Normal - Lucro Real</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label className='titlefromdropdraw'>
                      {' '}
                      Regime Tributario Especial
                    </Form.Label>
                    <Form.Group as={Col}>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          value={specialTax}
                          disabled
                        >
                          <MenuItem value={0}>
                            <div className='corMenu'>
                              Sem Regime Tributário Especial
                            </div>
                          </MenuItem>
                          <MenuItem value={1}>
                            <div className='corMenu'>
                              Micro Empresa Municipal
                            </div>
                          </MenuItem>
                          <MenuItem value={2}>
                            <div className='corMenu'>Estimativa</div>
                          </MenuItem>
                          <MenuItem value={3}>
                            <div className='corMenu'>
                              Sociedade de Profissionais
                            </div>
                          </MenuItem>
                          <MenuItem value={4}>
                            <div className='corMenu'>Cooperatival</div>
                          </MenuItem>
                          <MenuItem value={5}>
                            <div className='corMenu'>
                              Microempresário Individual - MEI
                            </div>
                          </MenuItem>
                          <MenuItem value={6}>
                            <div className='corMenu'>
                              Microempresa ou Pequeno Porte - ME EPP
                            </div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Check type='checkbox'>
                        <Form.Check.Input
                          type='checkbox'
                          name='simplesNacional'
                          id='formCompanySimplesNacional'
                          checked={simplesNacional}
                          required
                          disabled
                        />
                        <Form.Check.Label>Simples Nacional</Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Check type='checkbox'>
                        <Form.Check.Input
                          type='checkbox'
                          name='incentivoFiscal'
                          id='formCompanyIncentivoFiscal'
                          checked={incentivoFiscal}
                          disabled
                        />
                        <Form.Check.Label>Incentivo Fiscal</Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Check type='checkbox'>
                        <Form.Check.Input
                          type='checkbox'
                          name='incentivadorCultural'
                          id='formCompanyIncentivadorCultural'
                          checked={incentivadorCultural}
                          disabled
                        />
                        <Form.Check.Label>
                          Incentivador Cultural
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div id='endereco' className='form-block'>
                <h3>Endereço</h3>
                <Form.Row>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyCEP'>
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        placeholder=''
                        type='text'
                        name='cep'
                        value={cepEndereco}
                        required
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um CEP valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyTipoLogradouro'>
                      <Form.Label>Tipo Logradoro</Form.Label>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          value={tipoLogradouroEndereco}
                          disabled
                        >
                          <MenuItem value={'Alameda'}>
                            <div className='corMenu'>Alameda</div>
                          </MenuItem>
                          <MenuItem value={'Avenida'}>
                            <div className='corMenu'>Avenida</div>
                          </MenuItem>
                          <MenuItem value={'Chácara'}>
                            <div className='corMenu'>Chácara</div>
                          </MenuItem>
                          <MenuItem value={'Colônia'}>
                            <div className='corMenu'>Colônia</div>
                          </MenuItem>
                          <MenuItem value={'Condomínio'}>
                            <div className='corMenu'>Condomínio</div>
                          </MenuItem>
                          <MenuItem value={'Estância'}>
                            <div className='corMenu'>Estância</div>
                          </MenuItem>
                          <MenuItem value={'Estrada'}>
                            <div className='corMenu'>Estrada</div>
                          </MenuItem>
                          <MenuItem value={'Fazenda'}>
                            <div className='corMenu'>Fazenda</div>
                          </MenuItem>
                          <MenuItem value={'Praça'}>
                            <div className='corMenu'>Praça</div>
                          </MenuItem>
                          <MenuItem value={'Prolongamento'}>
                            <div className='corMenu'></div>
                            Prolongamento
                          </MenuItem>
                          <MenuItem value={'Rodovia'}>
                            <div className='corMenu'>Rodovia</div>
                          </MenuItem>
                          <MenuItem value={'Rua'}>
                            <div className='corMenu'>Rua</div>
                          </MenuItem>
                          <MenuItem value={'Sítio'}>
                            <div className='corMenu'>Sítio</div>
                          </MenuItem>
                          <MenuItem value={'Travessa'}>
                            <div className='corMenu'>Travessa</div>
                          </MenuItem>
                          <MenuItem value={'Vicinal'}>
                            <div className='corMenu'>Vicinal</div>
                          </MenuItem>
                          <MenuItem value={'Eqnp'}>
                            <div className='corMenu'>Eqnp</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col xs={10}>
                    <Form.Group as={Col} controlId='formCompanyLogradouro'>
                      <Form.Label>Logradouro</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoLogradouro'
                        value={logradouroEndereco}
                        required
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um logradouro valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row className=''>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyNumero'>
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoNumero'
                        required
                        value={numeroEndereco}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Número valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyComplemento'>
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoComplemento'
                        value={complementoEndereco}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um complemento valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Label className='titlefromdropdraw'>
                      Tipo de Bairro
                    </Form.Label>
                    <Form.Group as={Col}>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          value={tipoBairroEndereco}
                          disabled
                        >
                          <MenuItem value={'Bairro'}>
                            <div className='corMenu'>Bairro</div>
                          </MenuItem>
                          <MenuItem value={'Bosque'}>
                            <div className='corMenu'>Bosque</div>
                          </MenuItem>
                          <MenuItem value={'Chácara'}>
                            <div className='corMenu'>Chácara</div>
                          </MenuItem>
                          <MenuItem value={'Conjunto'}>
                            <div className='corMenu'>Conjunto</div>
                          </MenuItem>
                          <MenuItem value={'Desmembramento'}>
                            <div className='corMenu'>Desmembramento</div>
                          </MenuItem>
                          <MenuItem value={'Distrito'}>
                            <div className='corMenu'>Distrito</div>
                          </MenuItem>
                          <MenuItem value={'Favela'}>
                            <div className='corMenu'>Favela</div>
                          </MenuItem>
                          <MenuItem value={'Fazenda'}>
                            <div className='corMenu'>Fazenda</div>
                          </MenuItem>
                          <MenuItem value={'Gleba'}>
                            <div className='corMenu'>Gleba</div>
                          </MenuItem>
                          <MenuItem value={'Horto'}>
                            <div className='corMenu'>Horto</div>
                          </MenuItem>
                          <MenuItem value={'Jardim'}>
                            <div className='corMenu'>Jardim</div>
                          </MenuItem>
                          <MenuItem value={'Loteamento'}>
                            <div className='corMenu'>Loteamento</div>
                          </MenuItem>
                          <MenuItem value={'Núcleo'}>
                            <div className='corMenu'>Núcleo</div>
                          </MenuItem>
                          <MenuItem value={'Parque'}>
                            <div className='corMenu'>Parque</div>
                          </MenuItem>
                          <MenuItem value={'Residencial'}>
                            <div className='corMenu'>Residencial</div>
                          </MenuItem>
                          <MenuItem value={'Sítio'}>
                            <div className='corMenu'>Sítio</div>
                          </MenuItem>
                          <MenuItem value={'Tropical'}>
                            <div className='corMenu'>Tropical</div>
                          </MenuItem>
                          <MenuItem value={'Vila'}>
                            <div className='corMenu'>Vila</div>
                          </MenuItem>
                          <MenuItem value={'Zona'}>
                            <div className='corMenu'>Zona</div>
                          </MenuItem>
                          <MenuItem value={'Centro'}>
                            <div className='corMenu'>Centro</div>
                          </MenuItem>
                          <MenuItem value={'Setor'}>
                            <div className='corMenu'>Setor</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group as={Col} controlId='formCompanyBairro'>
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoBairro'
                        value={bairroEndereco}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um bairro valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyCodCidade'>
                      <Form.Label>Código cidade</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoCodigoCidade'
                        required
                        value={codigoCidadeEndereco}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Código cidade valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group as={Col} controlId='formCompanyCidade'>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoCidade'
                        value={descricaoCidadeEndereco}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Cidade valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Label className='titlefromdropdraw'>
                      Tipo de Estado
                    </Form.Label>
                    <Form.Group as={Col} controlId='formCompanyEstado'>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          value={estadoEndereco}
                          disabled
                        >
                          <MenuItem value={estadoEndereco}>
                            <div className='corMenu'>{estadoEndereco}</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group
                      as={Col}
                      controlId='formCompanyCountryDescription'
                    >
                      <Form.Label>Código país</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoCountryDescription'
                        required
                        value={codigoPaisEndereco}
                        placeholder=''
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Código país valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group as={Col} controlId='formCompanyPais'>
                      <Form.Label>País</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoCodigoPais'
                        required
                        value={descricaoPaisEndereco}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um País valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div id='contato' className='form-block'>
                <h3>Contato</h3>
                <Form.Row>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyDDD'>
                      <Form.Label>DDD</Form.Label>
                      <Form.Control
                        placeholder=''
                        type='text'
                        name='telefoneDDD'
                        value={dddTelefone}
                        maxLength='2'
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um DDD país valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group as={Col} controlId='formCompanyTelefone'>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        placeholder=''
                        type='phone'
                        name='telefoneNumero'
                        value={numeroTelefone}
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Telefone valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group as={Col} controlId='formCompanyEmail'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder=''
                        type='email'
                        name='email'
                        value={email}
                        required
                        readOnly
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um email valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>

              <div className='titlefrom'>
                <h3>NFS-e</h3> &nbsp;&nbsp;&nbsp;&nbsp;
                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  value={ativoNfse}
                  placeholder=''
                  readOnly
                  disabled
                >
                  <MenuItem value={false}>
                    <div className='corMenu'>Desativa a emissão de NFS-e.</div>
                  </MenuItem>
                  <MenuItem value={true}>
                    <div className='corMenu'>Ativa a emissão de NFS-e.</div>
                  </MenuItem>
                </Select>
              </div>

              {ativoNfse && (
                <div id='nfse' className='form-block'>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Select
                          labelId='demo-simple-select-helper-label'
                          value={producaoConfigNfse}
                          disabled
                        >
                          <MenuItem value={false}>
                            <div className='corMenu'>Emissão homologação..</div>
                          </MenuItem>
                          <MenuItem value={true}>
                            <div className='corMenu'>Emissão em produção.</div>
                          </MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check type='checkbox' id='formCompanyEmailNFSE'>
                          <Form.Check.Input
                            type='checkbox'
                            checked={envioEmailRpsConfigNfse}
                            disabled
                          />
                          <Form.Check.Label>Email</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={4}>
                      <FormControl>
                        <Form.Label className='titlefromdropdraw'>
                          Tipo de Constrato
                        </Form.Label>
                        <Select
                          labelId='demo-simple-select-helper-label'
                          value={tipoContratoNfse}
                          disabled
                        >
                          <MenuItem value={0}>
                            <div className='corMenu'>Bilhetagem</div>
                          </MenuItem>
                          <MenuItem value={1}>
                            <div className='corMenu'>Ilimitado</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={4}>
                      <Form.Group
                        as={Col}
                        controlId='formCompanyLoginPrefeituraNFSE'
                      >
                        <Form.Label>Login Prefeitura</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfseLoginPrefeitura'
                          value={loginPrefeituraRpsConfigNfse}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um login valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group
                        as={Col}
                        controlId='formCompanySenhaPrefeituraNFSE'
                      >
                        <Form.Label>Senha Prefeitura</Form.Label>
                        <Form.Control
                          type='password'
                          name='nfseSenhaPrefeitura'
                          value={senhaPrefeituraRpsConfigNfse}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma senha valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanyRPSSerieNFSE'>
                        <Form.Label>Série RPS</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfseRpsSerie'
                          value={serieConfigNfse}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma série valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanyRPSNumeroNFSE'>
                        <Form.Label>Número NFS-e (RPS)</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfseRpsNumero'
                          value={numeroRpsConfigNfse}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um número valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanyRPSLoteNFSE'>
                        <Form.Label>Lote NFS-e (RPS)</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfseRpsLote'
                          value={loteRpsConfigNfse}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um lote valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </div>
              )}
              <div className='titlefrom'>
                <h3>NF-e</h3> &nbsp;&nbsp;&nbsp;&nbsp;
                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  value={ativoNfe}
                  placeholder=''
                  disabled
                >
                  <MenuItem value={false}>
                    <div className='corMenu'>Desativa a emissão de NF-e.</div>
                  </MenuItem>
                  <MenuItem value={true}>
                    <div className='corMenu'>Ativa a emissão de NF-e.</div>
                  </MenuItem>
                </Select>
              </div>
              {ativoNfe && (
                <div id='nfe' className='form-block'>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Select
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          value={producaoConfigNfe}
                          disabled
                        >
                          <MenuItem value={false}>
                            <div className='corMenu'>
                              Emissão em homologação..
                            </div>
                          </MenuItem>
                          <MenuItem value={true}>
                            <div className='corMenu'>Emissão em produção.</div>
                          </MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check type='checkbox' id='formCompanyEmailNFE'>
                          <Form.Check.Input
                            type='checkbox'
                            name='nfeEmail'
                            value={envioEmailConfigNfe}
                            disabled
                          />
                          <Form.Check.Label>Email</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          type='checkbox'
                          id='formCompanyImpressaoFCPNFE'
                        >
                          <Form.Check.Input
                            type='checkbox'
                            checked={impressaoFcpConfigNfe}
                            disabled
                          />
                          <Form.Check.Label>Impressao FCP</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          type='checkbox'
                          id='formCompanyImpressaoPartilhaoNFE'
                        >
                          <Form.Check.Input
                            type='checkbox'
                            checked={impressaoPartilhaConfigNfe}
                            disabled
                          />
                          <Form.Check.Label>
                            Impressão partilha
                          </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanySerieNFE'>
                        <Form.Label>Série NFe de saída</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfeConfigSerie'
                          value={serieConfigNfe}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma série valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanyNumeroNFE'>
                        <Form.Label>Número NFe de saída</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfeConfigNumero'
                          value={numeroConfigNfe}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um número valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </div>
              )}
              <div className='titlefrom'>
                <h3>NFC-e</h3> &nbsp;&nbsp;&nbsp;&nbsp;
                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  value={ativoNfce}
                  disabled
                >
                  <MenuItem value={false}>
                    <div className='corMenu'>Desativa a emissão de NFC-e.</div>
                  </MenuItem>
                  <MenuItem value={true}>
                    <div className='corMenu'>Ativa a emissão de NFC-e.</div>
                  </MenuItem>
                </Select>
              </div>
              {ativoNfce && (
                <div id='nfce' className='form-block'>
                  <Form.Row className='accordion-body'>
                    <Col>
                      <Form.Group>
                        <Select
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          value={producaoConfigNfce}
                          disabled
                        >
                          <MenuItem value={false}>
                            <div className='corMenu'>
                              Emissão em homologação..
                            </div>
                          </MenuItem>
                          <MenuItem value={true}>
                            <div className='corMenu'>Emissão em produção.</div>
                          </MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check type='checkbox' id='formCompanyEmailNFCE'>
                          <Form.Check.Input
                            type='checkbox'
                            checked={envioEmailNfce}
                            disabled
                          />
                          <Form.Check.Label>Email</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={4}>
                      <FormControl>
                        <Form.Label className='teste'>Tipo contrato</Form.Label>
                        <Select
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          value={tipoContratoNfce}
                          placeholder=''
                          disabled
                        >
                          <MenuItem value={0}>
                            <div className='corMenu'>Bilhetagem</div>
                          </MenuItem>
                          <MenuItem value={1}>
                            <div className='corMenu'>Ilimitado</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanySerieNFCE'>
                        <Form.Label>Série NFCe</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfceConfigSerie'
                          value={serieConfigNfce}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma Série valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanyNumeroNFCE'>
                        <Form.Label>Número NFCe</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfceConfigNumero'
                          value={numeroConfigNfce}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um Número contrato valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanyIDCodSegNFCE'>
                        <Form.Label>
                          ID código de segurança do contribuinte
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='nfceSefazIdCod'
                          value={idCodigoSegurancaContribuinteConfigSefazNfe}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um ID valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanyCodSegNFCE'>
                        <Form.Label>
                          Código de segurança do contribuinte
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='nfceSefazCod'
                          value={codigoSegurancaContribuinteConfigSefazNfe}
                          readOnly
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um Código valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UpdateCompanies;
