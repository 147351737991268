import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Toast = (props) => {
    const position = props.position || 'bottom-center'

    return (
        <ToastContainer
            position={position}
            hideProgressBar
        />
    )
}

export default Toast;