export const TOKEN_KEY = 'token';
export const ID_USUARIO = 'id';
export const ID_USER = 'idUser';
export const NOME_USUARIO = 'name';
export const TYPE_USUARIO = 'usertype';
export const CNPJ_USUARIO = 'cnpj';
export const ORGID_USUARIO = 'orgid';
export const EMAIL_USUARIO = 'email';

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.clear();
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setIdUsuario = (id) => localStorage.setItem(ID_USUARIO, id);
export const getIdUsuario = () => localStorage.getItem(ID_USUARIO);

export const setIdUsuarioAdmin = (idUser) =>
  localStorage.setItem(ID_USER, idUser);
export const getIdUsuarioAdmin = () => localStorage.getItem(ID_USER);

export const setName = (name) => localStorage.setItem(NOME_USUARIO, name);
export const getIName = () => localStorage.getItem(NOME_USUARIO);

export const setUserEmailL = (email) =>
  localStorage.setItem(EMAIL_USUARIO, email);
export const getUserEmail = () => localStorage.getItem(EMAIL_USUARIO);

// export const setType = (type) => localStorage.setItem(TYPE_USUARIO, type);
// export const getType = () => localStorage.getItem(TYPE_USUARIO);

export const setCnpj = (cnpj) => localStorage.setItem(CNPJ_USUARIO, cnpj);
export const getCnpj = () => localStorage.getItem(CNPJ_USUARIO);

export const setOrgid = (orgid) => localStorage.setItem(ORGID_USUARIO, orgid);
export const getOrgid = () => localStorage.getItem(ORGID_USUARIO);
