import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import api from '../services/api';
import { getIdUsuario, setUserEmailL } from '../services/Auth/Login';
import {
  getAllEnterprises,
  getEnterpriseByCnpj,
} from '../services/Manager/MongoDB/Enterprise/EnterpriseManager';
import Load from '../pages/_components/loading/Loading';

export const GlobalContext = React.createContext();

export const GlobalStorage = ({ children }) => {
  const [userType, setUserType] = useState('');
  const [userCnpj, setUserCnpj] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [dataCertificate, setDataCertificate] = useState([]);
  const [dataCertificateUser, setDataCertificateUser] = useState([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    async function getGlobalData() {
      const response = await api.get('/users/' + getIdUsuario());
      setUserCnpj(response.data.cnpj);
      setUserType(response.data.usertype);
      setUserEmail(response.data.email);
      setUserEmailL(response.data.email);
      setPermissions(response.data.permissions);
      if (response.data.usertype === 2) {
        setEnterpriseList([]);
        let enterpriseResponse = [];
        for (let cnpj of response.data.cnpj) {
          const enterprise = await getEnterpriseByCnpj(cnpj);
          if (Object.keys(enterprise).length > 0) {
            enterpriseResponse = [...enterpriseResponse, enterprise];
          }
        }
        setEnterpriseList(enterpriseResponse);
      } else {
        setEnterpriseList([]);
        const enterprises = await getAllEnterprises();
        setEnterpriseList(enterprises);
      }

      //Certificados
      const url = process.env.REACT_APP_HOST_PLUGNOTAS;
      const key = process.env.REACT_APP_KEY_PLUGNOTAS;
      const responseCertificate = await axios.get(`${url}/certificado`, {
        headers: {
          'x-api-key': key,
        },
      });
      let data;
      data = responseCertificate.data;

      const newData = data.map((item) => {
        return {
          id: item.id,
          cadastro: moment(item.cadastro).format('DD-MM-YYYY HH:mm:ss'),
          nome: item.nome,
          cnpj: item.nome.split(':')[1]?.split(',')[0],
          email: item.email,
          date: new Date(
            moment(item.vencimento, 'DD-MM-YYYY HH:mm:ss').format(
              'MM-DD-YYYY HH:mm:ss'
            )
          ),
          vencimento: item.vencimento,
        };
      });
      newData.sort(function (a, b) {
        return b.date - a.date;
      });

      setDataCertificate(newData);

      response.data.cnpj.forEach(async (cnpj) => {
        const enterprise = await getEnterpriseByCnpj(cnpj);
        const datas = newData.filter(
          (item) => item.id === enterprise.certificado
        );
        if (datas.length > 0) {
          setDataCertificateUser((oldArray) => [...oldArray, datas[0]]);
        }
      });
      //Certificados
    }
    getGlobalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  if (
    userType === '' &&
    userCnpj &&
    enterpriseList.length === 0 &&
    window.location.pathname !== '/' &&
    dataCertificateUser.length === 0 &&
    userEmail === '' &&
    dataCertificate.length === 0 &&
    permissions === ''
  ) {
    return <Load />;
  }
  return (
    <GlobalContext.Provider
      value={{
        userType,
        userEmail,
        userCnpj,
        setUserType,
        setUserCnpj,
        enterpriseList,
        dataCertificate,
        dataCertificateUser,
        setMounted,
        mounted,
        permissions,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
