import axios from 'axios';
import { Buffer } from 'buffer';

const token = Buffer.from(
  `${process.env.REACT_APP_BASIC_AUTH_USERNAME}:${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
  'utf8'
).toString('base64');

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND, // Fixed typo
  headers: {
    Authorization: `Basic ${token}`,
  },
});

export const plugnotasApi = axios.create({
  baseURL: process.env.REACT_APP_HOST_PLUGNOTAS,
  headers: {
    'x-api-key': process.env.REACT_APP_KEY_PLUGNOTAS,
  },
});

export default api;
