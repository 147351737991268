import { toast } from 'react-toastify';

const toastConfig = {
  //position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 3000,

  pauseOnFocusLoss: true,
  hideProgressBar: false,
};

export function notifySuccess(msg) {
  toast.success(msg, toastConfig);
}

export function notifyError(msg) {
  toast.error(msg, toastConfig);
}
