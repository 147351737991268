import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';

import { Toast } from '../../../components/Toast/Toast';
import { notifyError } from '../../../components/Toast/ToastController';
import IconButton from '../../../components/IconButton/IconButton';

import {
  getEnterpriseByCnpj,
  enterpriseInitialData,
} from '../../../services/Manager/MongoDB/Enterprise/EnterpriseManager';
import {
  getNfseById,
  nfseInitialData,
} from '../../../services/Manager/MongoDB/Nfse/NfseManager';
import {
  getPdfById,
  getPlugnotasById,
  getXmlById,
  plugnotasInitialData,
} from '../../../services/Manager/Plugnotas/Nfse/PlugnotasNfseManager';

import './SeeNfse.scss';
import { formatDate } from '../../../helpers/FormatDate';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowLeft } from 'react-bootstrap-icons';

const statusToShow = (data = plugnotasInitialData) => {
  switch (data.tipoAutorizacao.toLowerCase()) {
    case 'webservice':
      return data.retorno.situacao;
    case 'upload':
      return data.status;
    default:
      return data.status;
  }
};

const validStatus = ['AUTORIZADO', 'CONCLUIDO'];

export default function UpdateClients() {
  const { nfseId } = useParams();
  const history = useHistory();
  const [nfse, setNfse] = useState(nfseInitialData);
  const [enterprise, setEnterprise] = useState(enterpriseInitialData);
  const [plugnotas, setPlugnotas] = useState(plugnotasInitialData);

  useEffect(() => {
    const getAsyncData = async () => {
      try {
        const nfseData = await getNfseById(nfseId);

        setNfse(nfseData);
      } catch (e) {
        notifyError('Erro ao buscar NFS-e no servidor.');
      }
    };

    getAsyncData();
  }, [nfseId]);

  useEffect(() => {
    if (nfse.idPlugnotas) {
      const getAsyncData = async () => {
        try {
          const plugnotasData = await getPlugnotasById(nfse.idPlugnotas);
          setPlugnotas(plugnotasData);
        } catch (e) {
          notifyError('Erro ao buscar NFS-e no plugnotas.');
        }
      };

      getAsyncData();
    }
  }, [nfse]);

  useEffect(() => {
    if (plugnotas.prestador.cpfCnpj) {
      const getAsyncData = async () => {
        try {
          const enterpriseData = await getEnterpriseByCnpj(
            plugnotas.prestador.cpfCnpj
          );
          setEnterprise(enterpriseData);
        } catch (e) {
          notifyError('Erro ao buscar o emitente no servidor.');
        }
      };

      getAsyncData();
    }
  }, [plugnotas]);

  const handleDownloadPDF = () => {
    try {
      getPdfById(nfse.idPlugnotas);
    } catch (e) {
      notifyError('Não foi possível encontrar o PDF para esta nota.');
    }
  };

  const handleDownloadXML = () => {
    try {
      getXmlById(nfse.idPlugnotas);
    } catch (e) {
      notifyError('Não foi possível encontrar o XML para esta nota.');
    }
  };
  return (
    <>
      <Container className='NewClients Container'>
        <Row>
          <Col className='title'>
            <ArrowLeft
              style={{
                cursor: 'pointer',
                marginRight: '10px',
              }}
              size={36}
              onClick={() => {
                history.push('/nfse');
              }}
            />
            <h2>Visualização de NFS-e</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form noValidate validated={false}>
              <div className='form-block'>
                <h3>Informações do Emitente</h3>
                <Form.Row>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label>Razão Social</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.razaoSocial}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>CNPJ</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.cpfCnpj}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridState'>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.email}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Inscrição Estadual</Form.Label>
                      <Form.Control
                        type='email'
                        value={enterprise.inscricaoEstadual}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>Inscrição Municipal</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.inscricaoMunicipal}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridState'>
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.endereco?.estado}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCity'>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type='text'
                        value={enterprise.endereco?.codigoCidade}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div className='form-block'>
                <h3>Informações Zoho Books</h3>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                      <Form.Label>Módulo</Form.Label>
                      <Form.Control
                        type='text'
                        value={nfse.moduleZohoBook}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} xl={4}>
                    <Form.Group as={Col} controlId='formGridMaxNotasEmitidas'>
                      <Form.Label>ID Books</Form.Label>
                      <Form.Control
                        type='text'
                        value={nfse.idInvoiceZohoBook}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>

              <div className='form-block'>
                <h3>Informações da NFS-e</h3>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Destinatário (CPF/CNPJ)</Form.Label>
                      <Form.Control
                        value={plugnotas.tomador.cpfCnpj}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>Data da emissão</Form.Label>
                      <Form.Control
                        type='text'
                        value={formatDate(plugnotas.rps.dataEmissao)}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label>ID Nota</Form.Label>
                      <Form.Control
                        type='text'
                        value={plugnotas.protocol}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Status</Form.Label>
                      <Form.Control value={statusToShow(plugnotas)} readOnly />
                    </Form.Group>
                  </Col>
                  <Col sm={18} lg={9}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label>Mensagem de retorno</Form.Label>
                      <Form.Control
                        type='text'
                        value={plugnotas.retorno.mensagemRetorno}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Nº</Form.Label>
                      <Form.Control value={plugnotas.rps.numero} readOnly />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>Data da autorização</Form.Label>
                      <Form.Control
                        type='text'
                        value={
                          validStatus.includes(statusToShow(plugnotas))
                            ? plugnotas.retorno.dataAutorizacao
                            : 'Não autorizado'
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row className='buttonRow'>
                  <IconButton onClick={handleDownloadPDF}>
                    Baixar PDF
                  </IconButton>
                  <IconButton onClick={handleDownloadXML}>
                    Baixar XML
                  </IconButton>
                </Form.Row>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <Toast />
    </>
  );
}
