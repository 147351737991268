import React, { useContext, useState } from 'react';
import { Dropdown, Form, Row } from 'react-bootstrap';
import { CaretDownFill } from 'react-bootstrap-icons';
import { GlobalContext } from '../../contexts/GlobalStorage';
import './LimitFilterButton.scss';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
    className='filter'
  >
    {' '}
    Mostrar <CaretDownFill size={10} />
  </button>
));

export default function LimitFilterButton({ handleSetLimit, limit }) {
  const global = useContext(GlobalContext);
  return (
    <Dropdown drop={global.userType === 2 && 'right'}>
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
      <Dropdown.Menu className='dropMenu'>
        <Form.Label className='filterLabel'>Limite por página</Form.Label>
        <Form.Control
          className='selectLimit'
          as='select'
          value={limit}
          onChange={(e) => {
            handleSetLimit(e.target.value);
            // changePaginator(1);limit
          }}
        >
          <option key={5} value={5}>
            5
          </option>
          <option key={10} value={10}>
            10
          </option>
          <option key={20} value={20}>
            20
          </option>
        </Form.Control>
      </Dropdown.Menu>
    </Dropdown>
  );
}
