import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { CustomToggle } from '../../../components/CustomToggle/CustomToggle';
import {dateChange} from '../../../helpers/dateChange'

const typeEnum = {
  0: 'Nenhum',
  1: 'Nome',
  2: 'Vencimento'
};
export default function FilterCertificate({
  handleSetDataCertificateShow,
  data,
  handleSetDataCertificate,
  handleSetLoad,
  load,
  back,
  qntCertificateShow,
}) {
  const [filter, setFilter] = useState({
    type: 0,
    value: '',
    searchValue: '',
  });

  const handleFilterOption = (event) => {
    handleSetLoad(!load);

    const type = Number(event?.target?.value);
    setFilter((prev) => {
      return {
        ...prev,
        searchValue: '',
        type,
      };
    });
  };

  const handleFilterSearch = (event) => {
    handleSetDataCertificate(back);
    handleSetDataCertificateShow(
      back.filter(
        (item, i) =>
          i < qntCertificateShow * 1 &&
          i >= qntCertificateShow * 1 - qntCertificateShow
      )
    );
    const searchValue = event.target.value;
    setFilter((prev) => {
      return {
        ...prev,
        searchValue,
      };
    });
  };

  const handleSubmitSearch = async () => {
    const newDataFiltered = data.filter((item) => {
      if (filter.type === 1) {
        if (
          item.nome
            .replace(/ /g, '')
            .toLowerCase()
            .includes(filter.searchValue.replace(/ /g, '').toLowerCase())
        ) {
          return item;
        } else {
          return null;
        }
      } else {
        return true;
      }
    });
    return (
      handleSetDataCertificateShow(
        newDataFiltered.filter(
          (item, i) =>
            i < qntCertificateShow * 1 &&
            i >= qntCertificateShow * 1 - qntCertificateShow
        )
      ),
      handleSetDataCertificate(newDataFiltered)
    );
  };


  const handleSubmitSearchByVencimento = async () => {
    const currentDate = moment().format('L hh:mm:ss')
    const newDataFiltered = data.filter((item) => {
      if (filter.type === 2) {
        if (dateChange(item.vencimento) >= dateChange(currentDate)) {
          return item;
        } else {
          return null;
        }
      } else {
        return true;
      }
    });

    newDataFiltered.sort((a, b) =>{ return dateChange(a.vencimento) - dateChange(b.vencimento) })
    return (
      handleSetDataCertificateShow(
        newDataFiltered.filter(
          (item, i) =>
            i < qntCertificateShow * 1 &&
            i >= qntCertificateShow * 1 - qntCertificateShow
        )
      ),
      handleSetDataCertificate(newDataFiltered)
    );
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
      <Dropdown.Menu>
        <Row className='dropMenuClients'>
          <Col className='filterCol'>
            <Form.Label className='filterLabel'>Filtrar por:</Form.Label>
            <Form.Control
              className='filterSelect'
              as='select'
              onChange={handleFilterOption}
              value={filter.type}
            >
              {Object.keys(typeEnum).map((type) => (
                <option key={type} value={type}>
                  {typeEnum[type]}
                </option>
              ))}
            </Form.Control>
          </Col>

          {filter.type === 1 && (
            <>
              <Col className='searchFilterCol'>
                <Form.Label className='filterLabel'>
                  {typeEnum[filter.type]}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                  onChange={handleFilterSearch}
                  value={filter.searchValue}
                ></Form.Control>
              </Col>
              <Button
                type='button'
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                disabled={filter.searchValue === ''}
                onClick={handleSubmitSearch}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}

          {filter.type === 2 && (
            <>
              <Button
                type='button'
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                onClick={handleSubmitSearchByVencimento}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
}
