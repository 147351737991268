import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Form, Spinner } from 'react-bootstrap';
import { Toast } from '../../components/Toast/Toast';
import {
  notifySuccess,
  notifyError,
} from './../../components/Toast/ToastController';
import api from '../../services/api';
import Header from '../_components/header/Header';
import {
  setName,
  login,
  setType,
  setIdUsuario,
  setIdUsuarioAdmin,
  setCnpj,
  setOrgid,
} from '../../services/Auth/Login';
import { getToken } from '../../services/Auth/Login';
import './Login.scss';
import { GlobalContext } from '../../contexts/GlobalStorage';
import { useHistory } from 'react-router-dom';

function Login() {
  const global = useContext(GlobalContext);
  let history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stopEnter, setStopEnter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const onKeyPressed = (e) => {
    if (e.keyCode === 13 && !stopEnter) {
      const button = document.getElementById('btnLogin');
      button.click();
    }
  };

  useEffect(() => {
    async function verify() {
      let res = await api.get('/checktoken', { params: { token: getToken() } });
      if (res.data.status === 200) {
        window.location.href = '/home';
      } else {
      }
    }
    document.addEventListener('keydown', onKeyPressed.bind(this));
    verify();
  }, [onKeyPressed]);

  const handleSubmit = async (event) => {
    try {
      setStopEnter(true);
      setLoading(true);
      await api.post('/sessions', { email, password }).then(async (res) => {
        if (res.status === 200) {
          if (res.data.status === 1) {
            console.log('right ', res);
            login(res.data.token);
            setIdUsuario(res.data.id);
            setName(res.data.name);
            setIdUsuarioAdmin(res.data.idUser);

            const response = await api.get('/users/' + res.data.id);
            global.setUserType(response.data.usertype);
            global.setUserCnpj(response.data.cnpj);
            setOrgid(response.data.orgId);
            setStopEnter(false);
            //toast(true);
            //window.location.reload(true);
            console.log(response);
            setLoading(false);
            history.push('/loading');
          } else {
            setLoading(false);
            setStopEnter(false);
            toast(false);
          }
        } else {
          setLoading(false);
          setStopEnter(false);
          toast(false);
        }
      });
    } catch {
      setLoading(false);
      setStopEnter(false);
      toast(false);
    }
    setValidated(true);
  };

  const toast = (opcao) => {
    const loginSuccessful = opcao;
    if (loginSuccessful) {
      return notifySuccess('Logado com sucesso');
    } else {
      return notifyError('Credenciais inválidas');
    }
  };

  return (
    <div>
      <Header name='' client='' />
      <Container className='Login'>
        <div className='title'>
          <h2>Login</h2>
        </div>
        <Form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId='formBasicPassword'>
            <Form.Label>Senha</Form.Label>
            <Form.Control
              type='password'
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button
            id='btnLogin'
            disabled={loading}
            variant='primary'
            onClick={handleSubmit}
          >
            {loading ? <Spinner size='sm' animation='border' /> : 'Entrar'}
          </Button>
        </Form>
      </Container>
      <Toast />
    </div>
  );
}

export default Login;
