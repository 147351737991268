import { useEffect, useState } from 'react';
import api from '../services/api';

export default function useUsers() {
  const [qntUsersShow, setQntUsersShow] = useState(10);
  const [dataUser, setDataUser] = useState([]);
  const [dataUserShow, setDataUserShow] = useState([]);
  const [back, setBack] = useState([]);
  const [paginators, setPaginators] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    async function allClient() {
      const { data } = await api.get('/users');
      data.sort((a, b) =>
        a.companyName > b.companyName
          ? 1
          : b.companyName > a.companyName
          ? -1
          : 0
      );

      let paginators = [];
      for (let i = 0; i < data.length / qntUsersShow; i++) {
        paginators.push('');
      }
      setPaginators(paginators);

      setDataUser(data);
      setBack(data);
      setDataUserShow(
        data.filter(
          (item, i) =>
            i < qntUsersShow * 1 && i >= qntUsersShow * 1 - qntUsersShow
        )
      );
    }
    allClient();
  }, [qntUsersShow, load]);
  return {
    qntUsersShow,
    setQntUsersShow,
    dataUser,
    setDataUser,
    dataUserShow,
    setDataUserShow,
    back,
    setBack,
    paginators,
    setPaginators,
    load,
    setLoad,
  };
}
