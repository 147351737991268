import React, { useContext, useState } from 'react';
import {
  ArrowLeft,
  Cloudy,
  ExclamationTriangle,
  Lightbulb,
  X,
} from 'react-bootstrap-icons';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { getIName, getUserEmail } from '../../../services/Auth/Login';
import { createFeedback } from '../../../services/Manager/MongoDB/Feedback/FeedbackManager';
import './FeedbackButton.scss';
import FeedbackForm from './FeedbackForm';
import FeedbackSuccess from './FeedbackSuccess';

export default function FeedbackOverlay({ handleSetShow, show }) {
  const global = useContext(GlobalContext);
  const [feedbackType, setFeedbackType] = useState(undefined);
  const [feedbackMessage, setFeedbackMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const feedback = {
      clientName: getIName(),
      clientEmail: getUserEmail() ?? global.userEmail,
      feedbackType,
      feedbackMessage,
    };
    const response = await createFeedback(feedback);
    setLoading(false);
    setSuccess(true);
    return console.log(response);
  };
  const reset = () => {
    setFeedbackType(undefined);
    setFeedbackMessage(undefined);
    setLoading(false);
    setSuccess(false);
  };
  return (
    <>
      <div className='feedbackOverlay'>
        <div
          style={{
            display: 'flex',
            justifyContentc: 'center',
            alignItems: 'center',
          }}
        >
          {feedbackType !== undefined && !success && (
            <button
              type='button'
              style={{
                outline: 'none',
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
              }}
              onClick={() => setFeedbackType(undefined)}
            >
              <ArrowLeft
                style={{
                  float: 'left',
                }}
                size={24}
              />
            </button>
          )}
          <h3
            style={{
              flex: 1,
              margin: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            {feedbackType === undefined && !success && ' Deixe seu feedback'}
            {feedbackType === 'Problemas' && !success && (
              <>
                <ExclamationTriangle
                  className='mx-2'
                  size={24}
                  color='#FF0000'
                />
                Problemas
              </>
            )}
            {feedbackType === 'Sugestões' && !success && (
              <>
                <Lightbulb className='mx-2' color='#FFCC4D' size={24} />
                Sugestões
              </>
            )}
            {feedbackType === 'Outros' && !success && (
              <>
                <Cloudy className='mx-2' color='#4274e3' size={24} />
                Outros
              </>
            )}
            {success && ''}
          </h3>
          <button
            type='button'
            style={{
              outline: 'none',
              backgroundColor: 'transparent',
              border: 'none',
              boxShadow: 'none',
            }}
            onClick={() => handleSetShow(!show)}
          >
            <X
              style={{
                float: 'right',
              }}
              size={24}
            />
          </button>
        </div>
        {feedbackType === undefined && !success ? (
          <div className='feedbackOverlayContent'>
            <button
              type='button'
              className='feedbackOverlayContentItem'
              onClick={() => setFeedbackType('Problemas')}
            >
              <ExclamationTriangle size={36} color='#FF0000' />
              <p>Problemas</p>
            </button>
            <button
              type='button'
              onClick={() => setFeedbackType('Sugestões')}
              className='feedbackOverlayContentItem'
            >
              <Lightbulb color='#FFCC4D' size={36} />
              <p>Sugestões</p>
            </button>
            <button
              type='button'
              onClick={() => setFeedbackType('Outros')}
              className='feedbackOverlayContentItem'
            >
              <Cloudy color='#4274e3' size={36} />
              <p>Outros</p>
            </button>
          </div>
        ) : (
          <>
            {success === true ? (
              <FeedbackSuccess handleReset={reset} />
            ) : (
              <FeedbackForm
                feedbackMessage={feedbackMessage}
                handleSetFeedbackMessage={setFeedbackMessage}
                feedbackType={feedbackType}
                handleSubmit={handleSubmit}
                loading={loading}
              />
            )}
          </>
        )}

        <footer className='feedbackOverlayContentFooter'>
          <h4>
            Dúvidas?{' '}
            <a
              target={'blanck'}
              href='https://desk.zoho.com/portal/zohobrasil/pt/signin'
            >
              Clique aqui.
            </a>{' '}
          </h4>
        </footer>
      </div>
    </>
  );
}
