import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row, Dropdown } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { CustomToggle } from '../../../components/CustomToggle/CustomToggle';

const typeEnum = {
  0: 'Filtrar por',
  1: 'Usuário',
  2: 'Ação',
  3: 'Local do log'
};
export default function FilterLogs({
  handleSetDataLogShow,
  data,
  qntLogsShow,
  handleSetDataLog,
  handleSetLoad,
  load,
  back,
  logShowData
}) {
  const global = useContext(GlobalContext);
  const [filter, setFilter] = useState({
    type: 0,
    value: '',
    searchValue: '',
  });

  const handleFilterOption = (event) => {
    handleSetLoad(!load);
    
    const type = Number(event?.target?.value);
    
    setFilter((prev) => {
      return {
        ...prev,
        searchValue: '',
        type,
      };
    });
  };

  const handleFilterSearch = (event) => {
    handleSetDataLog(back);
    handleSetDataLogShow(
      back.filter(
        (item, i) =>
          i < qntLogsShow * 1 &&
          i >= qntLogsShow * 1 - qntLogsShow
      )
    );
    const searchValue = event.target.value;
    setFilter((prev) => {
      return {
        ...prev,
        searchValue,
      };
    });
  };

  const handleSubmitSearch = async () => {
    const newDataFiltered = data.filter((item) => {
      if (filter.type === 1) {
        if(item.clientName){
        if (
          item.clientName
            .replace(/ /g, '')
            .toLowerCase()
            .includes(filter.searchValue.replace(/ /g, '').toLowerCase())
        ) {
          return item;
        } else {
          return null;
        }
      }
      } else if (filter.type === 2) {
        if (item.actionType === filter.searchValue) {
          return item;
        } else {
          return null;
        }
      } else if (filter.type === 3) {
        if (item.logLocal === filter.searchValue) {
          return item;
        } else {
          return null;
        }
      }
    });
    
    return (
        handleSetDataLogShow(
        newDataFiltered.filter(
          (item, i) =>
            i < qntLogsShow * 1 &&
            i >= qntLogsShow * 1 - qntLogsShow
        )
      ),
      
      handleSetDataLog(newDataFiltered)
    );
  };
  
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
          <Dropdown.Menu>
            <Row className='dropMenuClients'>
                <Col className='filterCol'>
                <Form.Control
                  className='filterSelect'
                  as='select'
                  onChange={handleFilterOption}
                  value={filter.type}
                >
                  {Object.keys(typeEnum).map((type) => (
                    <>
                    <option key={type} value={type}>
                        {typeEnum[type]}
                    </option>
                    </>
                  ))}
                </Form.Control>
              </Col>
                {(filter.type === 1) && global && (
                  <>
                    <Col className='searchFilterCol'>
                      <Form.Control
                        type='text'
                        placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                        onChange={handleFilterSearch}
                        value={filter.searchValue}
                      ></Form.Control>
                    </Col>
                    <Button
                      type='button'
                      style={{
                        alignSelf: 'flex-end',
                        height: 'fit-content',
                      }}
                      disabled={filter.searchValue === ''}
                      onClick={handleSubmitSearch}
                    >
                      <Search color='#fff' size={17} />
                    </Button>
                  </>
                  )}
                  {filter.type === 2 && (
                    <>
                      <Col className='searchFilterCol'>
                        <Form.Control
                          as='select'
                          placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                          onChange={handleFilterSearch}
                          value={filter.searchValue}
                        >
                          <option key={0}>Escolha uma opção</option>
                          <option key={1} value={'Atualização'}>
                            Atualização
                          </option>
                          <option key={2} value={'Exclusão'}>
                            Exclusão
                          </option>
                        </Form.Control>
                      </Col>
                      <Button
                        style={{
                          alignSelf: 'flex-end',
                          height: 'fit-content',
                        }}
                        onClick={handleSubmitSearch}
                      >
                        <Search color='#fff' size={17} />
                      </Button>
                    </>
                  )}

                  {filter.type === 3 && (
                    <>
                      <Col className='searchFilterCol'>
                        <Form.Control
                          as='select'
                          placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                          onChange={handleFilterSearch}
                          value={filter.searchValue}
                        >
                          <option key={0}>Escolha uma opção</option>
                          <option key={1} value={'Cliente'}>
                            Cliente
                          </option>
                          <option key={2} value={'Usuário'}>
                            Usuário
                          </option>
                          <option key={3} value={'Certificado'}>
                            Certificado
                          </option>
                          <option key={4} value={'Feedback'}>
                            Feedback
                          </option>
                          <option key={5} value={'Empresa'}>
                            Empresa
                          </option>
                        </Form.Control>
                      </Col>
                      <Button
                        style={{
                          alignSelf: 'flex-end',
                          height: 'fit-content',
                        }}
                        onClick={handleSubmitSearch}
                      >
                        <Search color='#fff' size={17} />
                      </Button>
                    </>
                  )}
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
