import React, { useRef, useState } from 'react';
import { Button, Overlay } from 'react-bootstrap';
import { ChatDots } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import './FeedbackButton.scss';
import FeedbackOverlay from './FeedbackOverlay';
export default function FeedbackButton() {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      {location.pathname !== '/' && (
        <div className='FeedbackContainer'>
          <Button
            className='feedButton'
            ref={target}
            onClick={() => setShow(!show)}
          >
            <ChatDots size={32} />
          </Button>
          <Overlay target={target.current} show={show} placement='bottom'>
            {(props) => <FeedbackOverlay handleSetShow={setShow} show={show} />}
          </Overlay>
        </div>
      )}
    </>
  );
}
