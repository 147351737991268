import React, { useContext } from 'react';

import './DashCertificate.scss';
import { Spinner, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../contexts/GlobalStorage';

import { ArrowClockwise } from 'react-bootstrap-icons';
import { formatDateCertificate } from '../../../helpers/formatDateCertificate';
import useCertificate from '../../../hooks/use-certificate';
import { cnpjMask } from '../../../helpers/cnpjMask';

export default function DashCertificate() {
  const global = useContext(GlobalContext);
  const history = useHistory();
  const {
    dataCertificate,
    loadingCertificate,
    load,
    setLoad,
    dataCertificateShow,
  } = useCertificate();

  const clearcertificate = (name) => {
    let response = name.split(',');
    return response[0].substring(0, 20).concat('...');
  };
  return (
    <div className='ContainerDashCertificate'>
      <div className='bodyColHead'>
        <h3>Certificados à vencer</h3>
        <ArrowClockwise
          style={{ cursor: 'pointer' }}
          size={26}
          color='#4274e3'
          onClick={() => {
            setLoad(!load);
          }}
        />
      </div>
      <Table responsive striped hover>
        <thead>
          <tr>
            <th>CNPJ</th>
            <th>Vencimento</th>
          </tr>
        </thead>
        <tbody>
          {(global.userType === 2 ? dataCertificateShow : dataCertificate).map(
            (certificate) => (
              <tr
                key={certificate.id}
                className='rowCertify'
                onClick={() => history.push(`/certificate`)}
              >
                <td>
                  {cnpjMask(certificate.cnpj) || certificate.nome.split('=')[1]}
                </td>
                <td>{formatDateCertificate(certificate.vencimento)}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
      {!loadingCertificate &&
        (dataCertificate.length || dataCertificateShow.length) === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: '50px',
              height: '100%',
            }}
          >
            Nenhum certificado encontrado
          </div>
        )}
      {loadingCertificate && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
          }}
        >
          <Spinner animation='border' variant='primary' />
        </div>
      )}
    </div>
  );
}
