import React, { useState, useEffect } from 'react';
import api from '../api';
import { logout, getToken, getIdUsuario } from './Login';
import { Route, Redirect } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

export default function WAuth({ component: Component, ...rest }) {
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function verify() {
      const response = await api.get('/users/' + getIdUsuario());

      if (!response.data.usertype) {
        logout();
        setLoading(false);
        setRedirect(true);
        window.location.href = '/';
        return;
      }
      const acessLevel = response.data.usertype.toString();

      let res = await api.get('/checktoken', { params: { token: getToken() } });
      const acessLevelPermited = rest.acessLevelPermited;
      // const acessLevel = global.userType;

      let acessPermited = false;

      if (!acessLevelPermited) {
        acessPermited = true;
      } else {
        acessPermited =
          acessLevelPermited.length === 0 ||
          acessLevelPermited.indexOf(acessLevel) >= 0;
      }

      if (res.data.status === 200) {
        if (rest.isHome || acessPermited) {
          setLoading(false);
          setRedirect(false);
        } else {
          window.location.href = '/Home';
        }
      } else {
        logout();
        setLoading(false);
        setRedirect(true);
      }
    }
    verify();
  }, [rest.acessLevelPermited, rest.isHome]);

  return loading ? (
    <div
      style={{
        width: '100vh',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner />
    </div>
  ) : (
    <Route
      {...rest}
      render={(props) =>
        !redirect ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
}
