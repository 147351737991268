import React, { useContext, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Modal,
  ProgressBar,
  Spinner,
} from 'react-bootstrap';
import { EyeFill, PencilSquare } from 'react-bootstrap-icons';
import { BiPlusMedical } from 'react-icons/bi';
import './Clients.scss';
import { GlobalContext } from '../../contexts/GlobalStorage';
import { useHistory } from 'react-router-dom';
import { cnpjMask } from '../../helpers/cnpjMask';
import FilterCompanies from '../_components/filterCompanies/FilterCompanies';
import LimitFilterButton from '../../components/LimitFilterButton/LimitFilterButton';
import useClients from '../../hooks/use-clients';

export default function Clients() {
  const global = useContext(GlobalContext);
  const {
    loadingClients,
    dataClient,
    setDataClient,
    dataClientShow,
    setDataClientShow,
    qntClientShow,
    setQntClientShow,
    back,
    load,
    setLoad,
    paginators,
  } = useClients();
  const history = useHistory();

  const [paginator, setPaginator] = useState(1);
  const [show, setShow] = useState(false);
  const [integrationPlugNotas, setIntegrationPlugNotas] = useState({
    integration: {
      plugNotas: {
        countCallApi: 0,
        enable: false,
        available: 0,
        limitRequestCallApi: 0,
        perCent: 0,
      },
    },
  });

  const showIntegrationPlugNotas = (company) => {
    company.integration.plugNotas.available =
      company.integration.plugNotas.limitRequestCallApi -
      company.integration.plugNotas.countCallApi;
    company.integration.plugNotas.perCent = (
      (company.integration.plugNotas.countCallApi * 100) /
      company.integration.plugNotas.limitRequestCallApi
    ).toFixed(2);
    setIntegrationPlugNotas(company);
    handleShow();
  };

  const changePaginator = (index) => {
    const paginatorIndex = index;
    setPaginator(index);

    setDataClientShow(
      dataClient.filter(
        (item, i) =>
          i < qntClientShow * paginatorIndex &&
          i >= qntClientShow * paginatorIndex - qntClientShow
      )
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className='Clients'>
        <Row>
          <Col className='table'>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>
                    <div className='d-flex'>
                      <FilterCompanies
                        handleSetDataClientShow={setDataClientShow}
                        data={dataClient}
                        qntClientShow={qntClientShow}
                        handleSetDataClient={setDataClient}
                        handleSetLoad={setLoad}
                        load={load}
                        back={back}
                      />
                      <LimitFilterButton
                        handleSetLimit={setQntClientShow}
                        limit={qntClientShow}
                      />
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  {global.userType !== 2 && (
                    <th>
                      <Button
                        onClick={() => history.push('/newclients')}
                        className='buttonElement'
                        variant='primary'
                      >
                        <BiPlusMedical
                          className='iconButton'
                          color='#ffffff'
                          size={13}
                        />
                        Novo
                      </Button>
                    </th>
                  )}
                </tr>
                <tr>
                  <th>Empresa</th>
                  {global.userType !== 2 ? <th>Nome Resp.</th> : <th>CNPJ</th>}
                  <th>E-mail do BoletoCloud</th>
                  {global.userType !== 2 && <th>API Key</th>}
                  <th>Opções</th>
                </tr>
              </thead>
              <tbody>
                {dataClientShow.map((client) => (
                  <tr key={client.id}>
                    {global.userType !== 2 ? (
                      <td>{client.companyName}</td>
                    ) : (
                      <td>{client.nomeFantasia}</td>
                    )}

                    {global.userType !== 2 ? (
                      <td>{client.name}</td>
                    ) : (
                      <td>{cnpjMask(client?.cpfCnpj)}</td>
                    )}

                    <td>{client.email} </td>
                    {global.userType !== 2 && <td>{client.tokenKeyHeader}</td>}

                    <td style={{ alignContent: 'start' }}>
                      {global.userType !== 2 ? (
                        <div style={{ display: 'flex' }}>
                          {/* <Button
                            size='sm'
                            onClick={() => showIntegrationPlugNotas(client)}
                            className='ButtonOptions'
                          >
                            Estatísticas
                            <GraphUp
                              style={{ marginLeft: '5px' }}
                              color='white'
                              size={17}
                            />
                          </Button> */}
                          <Button
                            size='sm'
                            onClick={() =>
                              history.push('/clients/see/' + client.id)
                            }
                            className='ButtonOptions'
                          >
                            Ver
                            <EyeFill
                              style={{ marginLeft: '5px' }}
                              color='white'
                              size={17}
                            />
                          </Button>
                        </div>
                      ) : (
                        <Button
                          size='sm'
                          onClick={() => {
                            history.push('/updatecompanies/' + client.id);
                          }}
                          style={{ display: 'flex' }}
                        >
                          Editar
                          <PencilSquare
                            style={{ marginLeft: '5px' }}
                            color='white'
                            size={17}
                          />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {!loadingClients && dataClientShow.length === 0 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '50px',
                  height: '100%',
                }}
              >
                Nenhum dado encontrado
              </div>
            )}
          </Col>
          {loadingClients && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                paddingBottom: '20px',
              }}
            >
              <Spinner animation='border' variant='primary' />
            </div>
          )}
        </Row>

        {dataClient.length > qntClientShow && (
          <Row>
            <Pagination>
              <Pagination.First
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(1);
                }}
              />
              <Pagination.Prev
                disabled={paginator <= 1}
                onClick={() => {
                  changePaginator(paginator - 1);
                }}
              />

              {paginators.map((item, index) => {
                return (
                  <Pagination.Item
                    key={index}
                    onClick={() => changePaginator(index + 1)}
                    active={index + 1 === paginator}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}

              <Pagination.Next
                onClick={() => {
                  changePaginator(paginator + 1);
                }}
                disabled={
                  Math.ceil(dataClient.length / qntClientShow) <= paginator
                }
              />
              <Pagination.Last
                onClick={() => {
                  changePaginator(
                    Math.trunc(dataClient.length / qntClientShow)
                  );
                }}
                disabled={
                  Math.ceil(dataClient.length / qntClientShow) <= paginator
                }
              />
            </Pagination>
          </Row>
        )}
      </div>

      <Modal show={show} onHide={handleClose} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Estatísticas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h5>{integrationPlugNotas.companyName}</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Integração Plugnotas</span>
            </Col>
          </Row>
          <Row>
            <Col>
              {integrationPlugNotas.integration.plugNotas.perCent < 70 && (
                <div style={{ position: 'relative' }}>
                  <ProgressBar
                    striped
                    variant='success'
                    now={integrationPlugNotas.integration.plugNotas.perCent}
                  ></ProgressBar>
                  <div className='percent'>
                    {integrationPlugNotas.integration.plugNotas.perCent}%
                  </div>
                </div>
              )}
              {integrationPlugNotas.integration.plugNotas.perCent >= 70 &&
                integrationPlugNotas.integration.plugNotas.perCent < 90 && (
                  <div style={{ position: 'relative' }}>
                    <ProgressBar
                      striped
                      variant='warning'
                      now={integrationPlugNotas.integration.plugNotas.perCent}
                    ></ProgressBar>
                    <div className='percent'>
                      {integrationPlugNotas.integration.plugNotas.perCent}%
                    </div>
                  </div>
                )}
              {integrationPlugNotas.integration.plugNotas.perCent >= 90 && (
                <div style={{ position: 'relative' }}>
                  <ProgressBar
                    striped
                    variant='danger'
                    now={integrationPlugNotas.integration.plugNotas.perCent}
                  ></ProgressBar>
                  <div className='percent'>
                    {integrationPlugNotas.integration.plugNotas.perCent}%
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                Disponivel:{' '}
                {integrationPlugNotas.integration.plugNotas.limitRequestCallApi}
              </span>
            </Col>
            <Col></Col>
            <Col>
              <span>
                Utilizadas:{' '}
                {integrationPlugNotas.integration.plugNotas.countCallApi}
              </span>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
