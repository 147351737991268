import api from '../../../api';
import { getIName } from '../../../Auth/Login';

export const createFeedback = async (body) => {
  const feedback = await api.post('/feedback', body).then(
    (response) => console.log(response),
    (error) => console.log(error)
  );
  return feedback;
};

export const getAllFeedbacks = async () => {
  const { data } = await api.get('/feedback');
  return data;
};

export const deleteFeedback = async (id) => {
  const { data } = await api.delete(`feedback/${id}`, {
    data: { superUser: getIName() },
  });
  return data;
};

export const updateFeedback = async (id, body) => {
  body['adminOrSuperAdmin'] = getIName();
  const { data } = await api.put(`feedback/${id}`, body);
  return data;
};
