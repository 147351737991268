import api from '../../../api';

export const nfeInitialData = {
  idIntegracao: '',
  idPlugnotas: '',
  idInvoiceZohoBook: '',
  idOrganizacaoZohoBook: '',
  moduleZohoBook: '',
  nfeModel: '',
  _id: '',
};

export const getNfeByZohoBooksId = async (zohoBooksId) => {
  const { data } = await api.get(
    `/notes/nfe/search/idInvoiceZohoBook/${zohoBooksId}`
  );
  return data;
};

export const getClientForNfe = async ({ nfe, orgId }) => {
  const { data } = await api.get(`/client/orgId/${orgId}`);
  return { ...nfe, clientName: data?.companyName };
};

export const getPaginatedNfe = async ({ page, limit }) => {
  const { data } = await api.get(`/notes/nfe?page=${page}&limit=${limit}`);

  return data;
};

export async function getNfeById(id) {
  const { data } = await api.get(`notes/nfe/${id}`);
  return data[0] ?? {};
}
export async function getNfeByOrgId({ orgId, page, limit }) {
  const { data } = await api.get(
    `notes/nfe/getByOrgId/${orgId}?page=${page}&limit=${limit}`
  );
  return data;
}
