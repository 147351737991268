import React from 'react';
import { Image } from 'react-bootstrap';
import logo from '../../../assets/img/logo.png';
import './Footer.scss';

export default function Footer() {
  return (
    <div className='footer-container'>
      <Image width={'150px'} height={'auto'} src={logo} />
      <div className='footer'>
        <p>
          <a href='https://zplug.in/' target='_blank' rel='noopener noreferrer'>
            Conheça nossas integrações
          </a>
        </p>
        <p>© 2022 CRM7 - Zoho Partner Brasil. Todos os direitos reservados. </p>
        <p>
          CNPJ 26371672000105. Stor7. M.V de Camargo Júnior, Tecnologia EPP.{' '}
        </p>
        <div>
          {' '}
          <a
            href='https://crm7.com.br/Sobre/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Sobre
          </a>{' '}
          <a
            href='https://crm7.com.br/blog/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Blog
          </a>{' '}
          <a
            href='https://crm7.com.br/contato/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Contato
          </a>{' '}
          <a
            href='https://crm7.com.br/portal/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Portal do Cliente
          </a>{' '}
          <a
            href='https://stor7.zohorecruit.com/jobs/Careers'
            target='_blank'
            rel='noopener noreferrer'
          >
            Trabalhe Conosco
          </a>{' '}
          <a
            href='https://desk.zoho.com/portal/zohobrasil/pt/home'
            target='_blank'
            rel='noopener noreferrer'
          >
            Suporte
          </a>{' '}
        </div>
      </div>
    </div>
  );
}
