import { plugnotasApi } from "../../../api";
import { downloadFile } from "../../../Global/downloadFile/DownloadFile";

export const plugnotasInitialData = {
  rps: {
    dataEmissaoTz: 0,
    dataVencimentoTz: 0,
    competenciaTz: 0,
    tipo: "",
    dataEmissao: "",
    dataVencimento: "",
    serie: "",
    numero: 0,
    lote: 0,
  },
  camposExtras: {
    copiasEmail: [],
  },
  prestador: {
    telefone: {
      ddd: "",
      numero: "",
    },
    config: {
      rps: {
        numero: 0,
        lote: 0,
        numeracao: [
          {
            numero: 0,
            serie: "",
          },
        ],
        serie: "",
      },
      prefeitura: {
        login: "",
      },
      email: {
        envio: true,
      },
      producao: true,
      integracoes: [],
    },
    incentivadorCultural: false,
    incentivoFiscal: false,
    cpfCnpj: "",
    endereco: {
      codigoPais: "",
      descricaoPais: "",
      tipoLogradouro: "",
      estado: "",
      cep: "",
      numero: "",
      logradouro: "",
      complemento: "",
      bairro: "",
      descricaoCidade: "",
      codigoCidade: "",
    },
    regimeTributario: 3,
    regimeTributarioEspecial: 0,
    certificado: "",
    razaoSocial: "",
    nomeFantasia: "",
    inscricaoMunicipal: "",
    inscricaoEstadual: "",
    simplesNacional: false,
    email: "",
    saasHandle: 0,
  },
  tomador: {
    cpfCnpj: "",
    email: "",
    razaoSocial: "",
    endereco: {
      codigoPais: "",
      descricaoPais: "",
      logradouro: "",
      numero: "",
      bairro: "",
      descricaoCidade: "",
      estado: "",
      cep: "",
      codigoCidade: "",
    },
  },
  pdf: {
    filename: "",
  },
  xml: {
    filename: "",
  },
  interacoes: {
    dataPrimeira: "",
    dataUltima: "",
    tentativas: 1,
  },
  retorno: {
    codigoVerificacao: "",
    dataAutorizacao: "",
    dataCancelamento: null,
    mensagemRetorno: "",
    numeroNfse: "",
    situacao: "",
  },
  cidadePrestacao: {
    utilizarDadosTomador: false,
    codigo: "",
    descricao: "",
    tipoLogradouro: "",
    estado: "",
    cep: "",
    numero: "",
    logradouro: "",
    complemento: "",
    bairro: "",
  },
  substituicao: false,
  tentativasReenvio: 0,
  enviarEmail: false,
  idIntegracao: "",
  naturezaTributacao: 1,
  servico: [
    {
      iss: {
        tipoTributacao: 6,
        exigibilidade: 1,
        retido: false,
        aliquota: 0,
        valor: 0,
        valorRetido: 0,
      },
      valor: {
        servico: 0,
        baseCalculo: 0,
        descontoCondicionado: 0,
        descontoIncondicionado: 0,
        unitario: 0,
        liquido: 0,
      },
      tributosFederaisRetidos: true,
      _id: "",
      codigoCidadeIncidencia: "",
      codigo: "",
      discriminacao: "",
      codigoTributacao: "",
      quantidade: 1,
      descricaoCidadeIncidencia: "",
      tributavel: false,
    },
  ],
  protocol: "",
  status: "",
  tipoAutorizacao: "",
  webhook: [
    {
      date: "",
      protocol: "",
    },
    {
      date: "",
      protocol: "",
    },
  ],
  parcelas: [],
  id: "",
  numeroNfse: "",
};

export async function getPlugnotasById(id) {
  const { data } = await plugnotasApi.get(`/nfse/${id}`);
  let returnData = plugnotasInitialData;
  returnData = { ...returnData, ...data };
  return returnData;
}

export async function getPdfById(id) {
  const { data } = await plugnotasApi.get(`/nfse/pdf/${id}`, {
    responseType: "blob",
  });
  const downloadUrl = window.URL.createObjectURL(data);
  downloadFile({ downloadUrl, fileName: `${id}.pdf` });
}

export async function getXmlById(id) {
  const { data } = await plugnotasApi.get(`/nfse/xml/${id}`, {
    responseType: "blob",
  });
  const downloadUrl = window.URL.createObjectURL(data);
  downloadFile({ downloadUrl, fileName: `${id}.xml` });
}
