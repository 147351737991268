import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  EyeFill,
  Clipboard,
  PencilSquare,
  ArrowLeft,
} from 'react-bootstrap-icons';
import { Link, useParams } from 'react-router-dom';
import { Toast } from '../../../components/Toast/Toast';
import api from '../../../services/api';
import { getIName, getType } from '../../../services/Auth/Login';
import './SeeClients.scss';
import Buttons from '../../../components/Button/Button';
import { copyToClipBoard } from '../../../services/Global/clipboard/Clipboard';
import { notifySuccess } from '../../../components/Toast/ToastController';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { useHistory } from 'react-router-dom';
import ClientImage from '../../../components/ClientImage/ClientImage';

export default function UpdateClients() {
  const global = useContext(GlobalContext);
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [enablePlugNota, setEnablePlugNota] = useState(false);
  const [limitRequestCallApi, setLimitRequestCallApi] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [dataIntegrationKey, setDataIntegrationKey] = useState('');

  const [dataEnterprise, setDataEnterprise] = useState([]);
  const [show, setShow] = useState(false);
  const [dataIntegrations, setDataIntegrations] = useState({});
  const [dataIntegrationsKey, setDataIntegrationsKey] = useState([]);

  const [accountToken, setAccountToken] = useState('');
  const [bankAccountToken, setBankAccountToken] = useState('');

  const { id } = useParams();

  useEffect(() => {
    async function allEnterprise() {
      const response = await api.get('/enterprise/client/' + id);

      setDataEnterprise(response.data);
    }

    const notaProduto = JSON.parse(process.env.REACT_APP_ARRAY_NOTAPRODUTO);
    const notaServico = JSON.parse(process.env.REACT_APP_ARRAY_NOTASERVICO);
    const boletoCloudCriar = JSON.parse(
      process.env.REACT_APP_URL_BOLETOCLOUD_CRIAR
    );
    const boletoCloudAtualizarVencimento = JSON.parse(
      process.env.REACT_APP_URL_BOLETOCLOUD_ATUALIZAR_VENCIMENTO
    );
    const boletoCloudBaixarBoleto = JSON.parse(
      process.env.REACT_APP_URL_BOLETOCLOUD_BAIXAR_BOLETO
    );
    const boletoCloudHomologarPagamento = JSON.parse(
      process.env.REACT_APP_URL_BOLETOCLOUD_HOMOLOGAR_PAGAMENTO
    );

    async function allClient() {
      const response = await api.get('/client/' + id);
      //   setDataEnterprise(response.data);
      const integration = response.data.tokenKeyHeader;
      setDataIntegrationKey(integration);

      setName(response.data.name);
      setEmail(response.data.email);
      setCompanyName(response.data.companyName);
      setCnpj(response.data.cnpj);
      setState(response.data.state);
      setCity(response.data.city);

      if (response.data.integration)
        if (response.data.integration.plugNotas)
          setEnablePlugNota(response.data.integration.plugNotas.enable);
      if (response.data.integration)
        if (response.data.integration.zohobooks)
          setOrganizationId(response.data.integration.zohobooks.organizationId);
      if (response.data.integration)
        if (response.data.integration.plugNotas)
          setLimitRequestCallApi(
            response.data.integration.plugNotas.limitRequestCallApi
          );
      if (response.data.integration)
        if (response.data.integration.billet)
          setAccountToken(response.data.integration.billet.accountToken);
      if (response.data.integration)
        if (response.data.integration.billet)
          setBankAccountToken(
            response.data.integration.billet.bankAccountToken
          );
    }

    const addNovaEmpresa = () => {
      window.location.href = '/newcompanies/' + id + '/' + organizationId;
    };

    const defineDataIntegrations = () => {
      const apiKey = {
        title: 'Api Key',
        links: dataIntegrationKey,
      };

      const dataIntegration = {
        notaProduto: {
          title: 'Nota de produto',
          links: notaProduto,
        },
        notaServico: {
          title: 'Nota de serviço',
          links: notaServico,
        },
        boletoCloud: {
          title: 'Boleto Cloud',
          links: [
            boletoCloudCriar,
            boletoCloudAtualizarVencimento,
            boletoCloudBaixarBoleto,
            boletoCloudHomologarPagamento,
          ],
        },
      };
      const dataKeys = Object.keys(dataIntegration);
      setDataIntegrations(dataIntegration);
      setDataIntegrationsKey(dataKeys);
    };
    defineDataIntegrations();
    allEnterprise();
    allClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyLinkIntegration = (link) => {
    copyToClipBoard(link);
    notifySuccess('Link copiado para área de transferência');
  };

  const copyLink = (link) => {
    return copyLinkIntegration(link);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container className='NewClients Container'>
        <Row
          style={{
            padding: '0px 15px',
          }}
        >
          <Col
            className='title'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowLeft
                style={{
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
                size={36}
                onClick={() => {
                  history.goBack();
                  history.push('/clients');
                }}
              />
              <h2>Visualização</h2>
            </div>
            {global.userType !== 2 && (
              <Link to={'/clients/update/' + id}>
                {' '}
                <Button variant='primary' className='subimit-btn'>
                  Editar
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form noValidate validated={false}>
              <div className='form-block'>
                <ClientImage />
                <h3>Dados pessoais</h3>
                <Form.Row>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label>Nome do Responsável</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={name}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridCompany'>
                      <Form.Label>Nome da Empresa</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={companyName}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='exemplo@exemplo.com'
                        value={email}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label>CNPJ</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={cnpj}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridState'>
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={state}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCity'>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={city}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div className='form-block'>
                <h3>Empresas</h3>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Razão Social</th>
                      <th>Nome Fantasia</th>
                      <th>Email</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataEnterprise.map((company) => {
                      return (
                        <tr key={company === null ? '' : company.id}>
                          <td>{company === null ? '' : company.razaoSocial}</td>
                          <td>
                            {company === null ? '' : company.nomeFantasia}
                          </td>
                          <td>{company === null ? '' : company.email}</td>
                          <td>
                            {company === null ? (
                              ''
                            ) : (
                              <div>
                                <Button
                                  size='sm'
                                  onClick={() =>
                                    history.push('/seecompanies/' + company.id)
                                  }
                                  className='ButtonOptions'
                                >
                                  Ver
                                  <EyeFill
                                    style={{ marginLeft: '5px' }}
                                    color='white'
                                    size={17}
                                  />
                                </Button>
                                {/* &nbsp; &nbsp;
                                <Link to={'/updatecompanies/' + company.id}>
                                  <PencilSquare color='#4274e3' size={20} />
                                </Link>
                                &nbsp; &nbsp; */}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {/* {global.userType !== 2 && (
                      <tr>
                        <td colSpan='5'>
                          <Buttons
                            name='Nova Empresa'
                            route={'/newcompanies/' + id + '/' + organizationId}
                            disabled
                          />

                        </td>
                      </tr>
                    )} */}
                  </tbody>
                </Table>
              </div>
              {global.userType !== 2 && (
                <div className='form-block'>
                  <h3>Integraçãoo Zohobooks</h3>
                  <Form.Row>
                    <Col sm={12} md={6} xl={6}>
                      <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                        <Form.Label>ID do Zoho Books</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={organizationId}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} xl={6}>
                      <Form.Group as={Col} controlId='formGridMaxNotasEmitidas'>
                        <Form.Label>Limite de notas emitidas</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={limitRequestCallApi}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <h3>Integração Boleto Cloud</h3>
                  <Form.Row>
                    <Col sm={12} md={6} xl={6}>
                      <Form.Group as={Col} controlId='formGridAccountToken'>
                        <Form.Label>Token de api do Boleto Cloud</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={accountToken}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} xl={6}>
                      <Form.Group as={Col} controlId='formGridBankAccountToken'>
                        <Form.Label>Token da Conta Bancaria</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={bankAccountToken}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Group id='formGridCheckbox'>
                        <Form.Check type='checkbox' id=''>
                          <Form.Check.Input
                            type='checkbox'
                            checked={enablePlugNota}
                            disabled
                          />
                          <Form.Check.Label>PlugNotas ativo</Form.Check.Label>
                          <Form.Control.Feedback type=''></Form.Control.Feedback>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Button variant='primary' onClick={handleShow}>
                        <EyeFill color='#ffffff' size={16} /> Visualizar links
                        Zohobooks
                      </Button>
                    </Col>
                  </Form.Row>
                </div>
              )}

              {/* <Buttons name="Atualizar Cliente" route={"/clients/update/" + id}></Buttons> */}
            </Form>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Links de integração</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='modal-row'>
            <Col>
              <h3>ApiKey</h3>
              <div
                key={dataIntegrationKey}
                className='modal-row__line'
                onClick={() => copyLink(dataIntegrationKey)}
              >
                <Clipboard color='#4274e3' size={16} />
                &nbsp;
                <div>{dataIntegrationKey}</div>
              </div>
            </Col>
          </Row>

          {dataIntegrationsKey.map((integration) => {
            return (
              <Row className='modal-row' key={integration}>
                <Col>
                  <h3>{dataIntegrations[integration].title}</h3>
                  {dataIntegrations[integration].links.map((link) => {
                    return (
                      <div
                        key={link}
                        className='modal-row__line'
                        onClick={() => copyLink(link)}
                      >
                        <Clipboard color='#4274e3' size={16} />
                        &nbsp;
                        <div>{link}</div>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            );
          })}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast />
    </>
  );
}
