import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Button } from 'react-bootstrap';
import { BiPlusMedical } from 'react-icons/bi';
import './Button.scss';

export default function Buttons({ name, route }) {
  return (
    <Col className='button'>
      <Button variant='primary'>
        <NavLink to={route} className='buttonElement'>
          <BiPlusMedical className='iconButton' color='#ffffff' size={13} />
          {name}
        </NavLink>
      </Button>
    </Col>
  );
}
