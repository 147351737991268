import React, { useState } from 'react';
import api from '../../services/api';
import styled from 'styled-components';
import profileImageDefault from '../../assets/img/profileImage.png';

export default function ProfileImage() {
  const id = localStorage.getItem('id');
  const [image, setImage] = useState();

  async function getProfileImage() {
    return await api.get('/profileImage/' + id);
  }
  getProfileImage().then((response) => {
    setImage(response.data.response);
  });

  const ImageProfile = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-image: url(${image ? image : profileImageDefault});
    background-size: cover;
    background-position: center;
    margin-right: 10px;
  `;

  return (
    <>
      <ImageProfile />
    </>
  );
}
