import React, { useContext } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import { ArrowClockwise, EyeFill } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { formatDate } from '../../../helpers/FormatDate';
import { formatValue } from '../../../helpers/FormatValue';
import useNfse from '../../../hooks/use-nfse';
import FilterNfse from '../filterNfse/FilterNfse';

import './DashNfse.scss';

export default function DashNfse() {
  const history = useHistory();
  const global = useContext(GlobalContext);
  const {
    nfses,
    loadingNfses,
    load,
    setLoad,
    setNfses,
    setLoadingNfses,
    setOrgId,
    orgId,
  } = useNfse();

  return (
    <div className='ContainerDashNfse'>
      <div className='bodyColHead'>
        <h3>Últimas NFS-e emitidas</h3>
        <ArrowClockwise
          style={{ cursor: 'pointer' }}
          size={26}
          color='#4274e3'
          onClick={() => {
            setLoad(!load);
          }}
        />
      </div>

      <Table responsive striped hover>
        <thead>
          <tr>
            <FilterNfse
              handleSetNfes={setNfses}
              handleSetLoadingNfes={setLoadingNfses}
              handleSetOrgId={setOrgId}
            />
          </tr>
          <tr>
            <th>Cliente</th>
            <th className='tableEmpresa'>ID Nota</th>
            <th>ID Books</th>
            <th>Status</th>
            <th>Hora de Criação</th>
            <th>Valor</th>
            <th className='tableOptions'>Opções</th>
          </tr>
        </thead>
        {!loadingNfses && (
          <tbody>
            {nfses.map((note) => (
              <tr
                key={note?.idPlugnotas}
                onClick={() => history.push(`/nfse/${note?._id}`)}
              >
                <td>{note?.clientName}</td>
                <td>{note?.idPlugnotas}</td>
                <td>{note?.idInvoiceZohoBook} </td>
                {note.statusPlugnotas === 'REJEITADO' && (
                  <td className='text-danger'>{note?.statusPlugnotas}</td>
                )}
                {note.statusPlugnotas === 'CONCLUIDO' && (
                  <td className='text-success'>{note?.statusPlugnotas}</td>
                )}
                {note.statusPlugnotas === 'Nota(as) em processamento' && (
                  <td className='text-warning'>{note?.statusPlugnotas}</td>
                )}
                {note.statusPlugnotas === 'CANCELADO' && (
                  <td className='text-danger'>{note?.statusPlugnotas}</td>
                )}

                <td>{formatDate(note?.dataHoraCriacao)}</td>

                <td>{formatValue(note?.valor)}</td>
                <td>
                  <Button
                    size='sm'
                    onClick={() => history.push(`/nfse/${note?._id}`)}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Ver
                    <EyeFill
                      style={{ marginLeft: '5px' }}
                      color='white'
                      size={17}
                    />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {!loadingNfses && nfses.length === 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '50px',
            height: '100%',
          }}
        >
          {global.userType === 2 && orgId === ''
            ? 'Escolha uma empresa'
            : 'Nenhuma NFs-e encontrada'}
        </div>
      )}
      {loadingNfses && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            padding: '50px',
          }}
        >
          <Spinner animation='border' variant='primary' />
        </div>
      )}
    </div>
  );
}
