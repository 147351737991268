import { plugnotasApi } from '../../../api';
import { downloadFile } from '../../../Global/downloadFile/DownloadFile';

export const plugnotasInitialData = {
  id: '',
  idIntegracao: '',
  emissao: '',
  status: '',
  destinada: false,
  emitente: '',
  destinatario: '',
  valor: 0,
  dataAutorizacao: '',
  numero: '',
  serie: '',
  chave: '',
  protocolo: '',
  mensagem: '',
  xml: '',
  pdf: '',
  cStat: 100,
};

export async function getPlugnotasById(id) {
  const { data } = await plugnotasApi.get(`/nfe/${id}/resumo`);
  return data[0] ?? {};
}

export async function getPdfById(id) {
  const { data } = await plugnotasApi.get(`/nfe/${id}/pdf`, {
    responseType: 'blob',
  });
  const downloadUrl = window.URL.createObjectURL(data);
  downloadFile({ downloadUrl, fileName: `${id}.pdf` });
}

export async function getXmlById(id) {
  const { data } = await plugnotasApi.get(`/nfe/${id}/xml`, {
    responseType: 'blob',
  });
  const downloadUrl = window.URL.createObjectURL(data);
  downloadFile({ downloadUrl, fileName: `${id}.xml` });
}
