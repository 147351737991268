import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import Router from './router/Router';
import { GlobalStorage } from './contexts/GlobalStorage';
import FeedbackButton from './pages/_components/feedbackButton';
import Footer from './pages/_components/footer/Footer';
import Header from './pages/_components/header/Header';
import { getIName } from './services/Auth/Login';

const page = window.location.pathname;

ReactDOM.render(
  <BrowserRouter>
    <GlobalStorage>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
        }}
      >
        {page !== '/' ? (
          <div className='indexHeader'>
            <Header name={getIName()} />
          </div>
        ) : (
          <div />
        )}
        <Router />
        <Footer />
      </div>
      <FeedbackButton />
    </GlobalStorage>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
