import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import api from '../../../services/api';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Toast } from '../../../components/Toast/Toast';
import {
  notifySuccess,
  notifyError,
} from '../../../components/Toast/ToastController';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import './RegisterCompany.scss';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';
import useCertificate from '../../../hooks/use-certificate';
import { formatDateCertificate } from '../../../helpers/formatDateCertificate';

function NewCompanies() {
  const history = useHistory();
  const { dataCertificate } = useCertificate();
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [cepEndereco, setCepEndereco] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [certificado, setCertificado] = useState('');
  const [simplesNacional, setSimplesNacional] = useState(false);
  const [incentivoFiscal, setIncentivoFiscal] = useState(false);
  const [incentivadorCultural, setIncentivadorCultural] = useState(false);
  const [logradouroEndereco, setLogradouroEndereco] = useState('');
  const [numeroEndereco, setNumeroEndereco] = useState('');
  const [complementoEndereco, setComplementoEndereco] = useState('');
  const [bairroEndereco, setBairrooEndereco] = useState('');
  const [codigoPaisEndereco, setCodigoPaisEndereco] = useState('1058');
  const [descricaoPaisEndereco, setDescricaoPaisEndereco] = useState('Brasil');
  const [codigoCidadeEndereco, setCodigoCidadeEndereco] = useState('');
  const [descricaoCidadeEndereco, setDescricaoCidadeEndereco] = useState('');
  const [dddTelefone, setDddTelefone] = useState('');
  const [numeroTelefone, setNumeroTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [producaoConfigNfse, setProducaoConfigNfse] = useState(false);
  const [serieConfigNfse, setSerieConfigNfse] = useState('');
  const [numeroRpsConfigNfse, setNumeroRpsConfigNfse] = useState('0');
  const [loteRpsConfigNfse, setLoteRpsConfigNfse] = useState('0');
  const [loginPrefeituraRpsConfigNfse, setLoginPrefeituraRpsConfigNfse] =
    useState('');
  const [senhaPrefeituraRpsConfigNfse, setSenhaPrefeituraRpsConfigNfse] =
    useState('');
  const [envioEmailRpsConfigNfse, setEnvioEmailRpsConfigNfse] = useState(false);
  const [producaoConfigNfe, setProducaoConfigNfe] = useState(false);
  const [impressaoFcpConfigNfe, setImpressaoFcpConfigNfe] = useState(false);
  const [impressaoPartilhaConfigNfe, setImpressaoPartilhaConfigNfe] =
    useState(false);
  const [serieConfigNfe, setSerieConfigNfe] = useState('0');
  const [numeroConfigNfe, setNumeroConfigNfe] = useState('0');
  const [serieConfigNfeEntrada, setSerieConfigNfeEntrada] = useState('');
  const [numeroConfigNfeEntrada, setNumeroConfigNfeEntrada] = useState('');
  const [ativoDfeConfigNfe, setAtivoDfeConfigNfe] = useState(false);
  const [envioEmailConfigNfe, setEnvioEmailConfigNfe] = useState(false);
  const [producaoConfigNfce, setProducaoConfigNfce] = useState(false);
  const [serieConfigNfce, setSerieConfigNfce] = useState('0');
  const [numeroConfigNfce, setNumeroConfigNfce] = useState('0');
  const [envioEmailNfce, setEnvioEmailNfce] = useState(false);
  const [
    idCodigoSegurancaContribuinteConfigSefazNfe,
    setDdCodigoSegurancaContribuinteConfigSefazNfe,
  ] = useState('');
  const [
    codigoSegurancaContribuinteConfigSefazNfe,
    setCodigoSegurancaContribuinteConfigSefazNfe,
  ] = useState('');

  const { id, idOng } = useParams();

  const [idOrg, setIdOrg] = useState(idOng);

  const [validated, setValidated] = useState(false);
  const [company, setCompany] = useState({});
  const [show, setShow] = useState(false);

  const [tax, setTax] = React.useState('0');
  const [specialTax, setSpecialTax] = React.useState('0');
  const [tipoContratoNfce, setTipoContratoNfce] = React.useState('0');
  const [tipoContratoNfse, setTipoContratoNfse] = React.useState('0');
  const [ativoNfse, setAtivoNfse] = React.useState(false);
  const [ativoNfe, setAtivoNfe] = React.useState(false);
  const [ativoNfce, setAtivoNfce] = React.useState(false);
  const [tipoLogradouroEndereco, setTipoLogradouroEndereco] = useState('Empty');
  const [tipoBairroEndereco, setTipoBairroEndereco] = useState('Empty');
  const [estadoEndereco, setEstadoEndereco] = useState('AC');

  const handleEstado = (event) => {
    setEstadoEndereco(event.target.value);
  };

  const handleTipoBairro = (event) => {
    setTipoBairroEndereco(event.target.value);
  };

  const handleTipoLogradouro = (event) => {
    setTipoLogradouroEndereco(event.target.value);
  };

  const handleAtivoNfse = (event) => {
    setAtivoNfse(event.target.value);
  };

  const handleAtivoNfe = (event) => {
    setAtivoNfe(event.target.value);
  };

  const handleAtivoNfce = (event) => {
    setAtivoNfce(event.target.value);
  };

  const handleTipoContratoNfse = (event) => {
    setTipoContratoNfse(event.target.value);
  };

  const handleTipoContratoNfce = (event) => {
    setTipoContratoNfce(event.target.value);
  };

  const handleChangetax = (event) => {
    setTax(event.target.value);
  };
  const handleSpecialTax = (event) => {
    setSpecialTax(event.target.value);
  };

  const handleProducaoNfse = (event) => {
    setProducaoConfigNfse(event.target.value);
  };

  const handleProducaoNfe = (event) => {
    setProducaoConfigNfe(event.target.value);
  };

  const handleProducaoNfce = (event) => {
    setProducaoConfigNfce(event.target.value);
  };

  const formatCPFCNPJ = (cpfCnpj) => {
    const cpfFormatted = cpfCnpj.replace(/\D+/g, '');
    if (cpf.isValid(cpfCnpj)) return setCpfCnpj(cpf.format(cpfCnpj));
    if (cnpj.isValid(cpfCnpj)) return setCpfCnpj(cnpj.format(cpfCnpj));
    return setCpfCnpj(cpfFormatted);
  };

  async function handleAddCompany() {
    const tipoContratoConvertidoNfse = parseInt(tipoContratoNfse);
    const tipoContratoConvertidoNfce = parseInt(tipoContratoNfce);

    const regimeTributarioConvertido = parseInt(tax);

    const regimeTributarioEspecialConvertido = parseInt(specialTax);
    const numeroRpsConvertido = parseInt(numeroRpsConfigNfse);
    const loteRpsConvertido = parseInt(loteRpsConfigNfse);
    const serieNfceConvertido = parseInt(serieConfigNfce);
    const numeroNfceConvertido = parseInt(numeroConfigNfce);
    const serieNfeConvertido = parseInt(serieConfigNfe);
    const numeroNfeConvertido = parseInt(numeroConfigNfe);

    const response = await api.get('/client/' + id);

    const dataCompany = {
      idClient: id,
      idOrg: response.data.integration.zohobooks.organizationId,
      cpfCnpj: cpfCnpj,
      inscricaoMunicipal: inscricaoMunicipal,
      inscricaoEstadual: inscricaoEstadual,
      razaoSocial: razaoSocial,
      nomeFantasia: nomeFantasia,
      certificado: certificado,
      simplesNacional: simplesNacional,
      regimeTributario: regimeTributarioConvertido,
      incentivoFiscal: incentivoFiscal,
      incentivadorCultural: incentivadorCultural,
      regimeTributarioEspecial: regimeTributarioEspecialConvertido,
      endereco: {
        tipoLogradouro: tipoLogradouroEndereco,
        logradouro: logradouroEndereco,
        numero: numeroEndereco,
        complemento: complementoEndereco,
        tipoBairro: tipoBairroEndereco,
        bairro: bairroEndereco,
        codigoPais: codigoPaisEndereco,
        descricaoPais: descricaoPaisEndereco,
        codigoCidade: codigoCidadeEndereco,
        descricaoCidade: descricaoCidadeEndereco,
        estado: estadoEndereco,
        cep: cepEndereco,
      },
      telefone: {
        ddd: dddTelefone,
        numero: numeroTelefone,
      },
      email: email,
      nfse: {
        ativo: ativoNfse,
        tipoContrato: 0,
        config: {
          producao: producaoConfigNfse,
          rps: {
            serie: serieConfigNfse,
            numero: numeroRpsConvertido,
            lote: loteRpsConvertido,
          },
          prefeitura: {
            login: loginPrefeituraRpsConfigNfse,
            senha: senhaPrefeituraRpsConfigNfse,
          },
          email: {
            envio: envioEmailRpsConfigNfse,
          },
        },
      },
      nfe: {
        ativo: ativoNfe,
        tipoContrato: tipoContratoConvertidoNfse,
        config: {
          producao: producaoConfigNfe,
          impressaoFcp: impressaoFcpConfigNfe,
          impressaoPartilha: impressaoPartilhaConfigNfe,
          serie: serieNfeConvertido,
          numero: numeroNfeConvertido,
          dfe: {
            ativo: ativoDfeConfigNfe,
          },
          email: {
            envio: envioEmailConfigNfe,
          },
        },
      },
      nfce: {
        ativo: ativoNfce,
        tipoContrato: tipoContratoConvertidoNfce,
        config: {
          producao: producaoConfigNfce,
          serie: serieNfceConvertido,
          numero: numeroNfceConvertido,
          email: {
            envio: envioEmailNfce,
          },
          sefaz: {
            idCodigoSegurancaContribuinte:
              idCodigoSegurancaContribuinteConfigSefazNfe,
            codigoSegurancaContribuinte:
              codigoSegurancaContribuinteConfigSefazNfe,
          },
        },
      },
    };
    try {
      await api.post('/enterprise', dataCompany).then(
        (response) => {
          const status = response;
          window.location.href = '/clients';
        },
        (error) => {
          const errors = error.response.data.errors;
          const message =
            errors.length > 0 ? translateMsg(errors[0].msg) : false;
          message && toastMsgError(message);
        }
      );
      return;
    } catch (error) {
      toast(false);
    }
  }

  const translateMsg = (msg) => {
    let msgTranslated = '';
    switch (msg) {
      case 'cpfCnpj is required':
        msgTranslated = 'O campo CPF / CNPJ está vazio';
        break;
      case 'razaoSocial is required':
        msgTranslated = 'O campo Razão social está vazio';
        break;
      case 'endereco.logradouro isrequired':
        msgTranslated = 'O campo logradouro está vazio';
        break;
      case 'endereco.numero is required':
        msgTranslated = 'O campo número está vazio';
        break;
      case 'endereco.bairro is required':
        msgTranslated = 'O campo bairro está vazio';
        break;
      case 'endereco.codigoCidade is required':
        msgTranslated = 'O cep digitado é inválido';
        break;
      case 'endereco.descricaoCidade is required':
        msgTranslated = 'O campo cidade está vazio';
        break;
      case 'nfse.config.rps.serie is required':
        msgTranslated = 'O campo NFS-e Série RPS está vazio';
        break;
      case 'nfse.config.prefeitura.login is required':
        msgTranslated = 'O campo NFS-e login prefeitura está vazio';
        break;
      case 'nfse.config.prefeitura.senha is required':
        msgTranslated = 'O campo NFS-e senha prefeitura está vazio';
        break;
      case 'email is required':
        msgTranslated = 'O campo email está vazio';
        break;
      case 'email must be a email valid':
        msgTranslated = 'O email digitado não é válido';
        break;
      case 'nfse.config.rps.numero is required':
        msgTranslated = 'O campo número de nfse é obrigatório';
        break;
      default:
        msgTranslated = msg;
    }

    return msgTranslated;
  };

  const toastMsgError = (msg) => {
    return notifyError(msg);
  };

  const toast = (opcao) => {
    const loginSuccessful = opcao;
    if (loginSuccessful) {
      return notifySuccess('Empresa criada com sucesso');
    } else {
      return notifyError('Credencias inválidas');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    handleAddCompany();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onBlurCep = async (env) => {
    const { value } = env.target;

    const cepvalidate = value?.replace(/[^0-9]/g, '');

    if (cepvalidate?.length !== 8) {
      return;
    }
    const response = await axios.get(
      `https://viacep.com.br/ws/${cepvalidate}/json/`
    );
    setCodigoCidadeEndereco(response.data.ibge);
    setLogradouroEndereco(response.data.logradouro);
    setBairrooEndereco(response.data.bairro);
    setDescricaoCidadeEndereco(response.data.localidade);
    setEstadoEndereco(response.data.uf);
  };

  const clearcertificate = (name) => {
    let response = name.split(',');
    return response[0];
  };

  return (
    <>
      <Container className='NewClients'>
        <Row
          style={{
            padding: '0px 15px',
          }}
        >
          <Col
            className='title'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowLeft
                style={{
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
                size={36}
                onClick={() => {
                  history.goBack();
                }}
              />
              <h2>Criar Empresa</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              {/* <div id="dados_gerais" className="form-block">
                <h3>Integração Zohobooks</h3>
              </div>
              <Form.Row>
                <Col>
                  <Form.Label>ID da organização do Zoho Books</Form.Label>
                  <Form.Control
                    type="text"
                    name="razaoSocial"
                    value={idOrg}
                    onChange={(e) => setIdOrg(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Escolha uma Razão Social valido.
                  </Form.Control.Feedback>
                </Col>
              </Form.Row> */}
              <div id='dados_gerais' className='form-block'>
                <h3>Dados gerais</h3>
                <Form.Row>
                  <Col>
                    <Form.Group as={Col} controlId='formCompanyRazaoSocial'>
                      <Form.Label>Razão Social</Form.Label>
                      <Form.Control
                        type='text'
                        name='razaoSocial'
                        defaultValue={razaoSocial}
                        onChange={(e) => setRazaoSocial(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma Razão Social valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId='formCompanyNomeFantasia'>
                      <Form.Label>Nome Fantasia</Form.Label>
                      <Form.Control
                        type='text'
                        name='nomeFantasia'
                        defaultValue={nomeFantasia}
                        onChange={(e) => setNomeFantasia(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma Nome Fantasia valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group as={Col} controlId='formCompanyCPFouCNPJ'>
                      <Form.Label>CPF / CNPJ</Form.Label>
                      <Form.Control
                        type='text'
                        name='cpfCnpj'
                        placeholder=''
                        defaultValue={cpfCnpj}
                        onChange={(e) => formatCPFCNPJ(e.target.value)}
                        maxLength='18'
                        required
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma CPF / CNPJ valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      as={Col}
                      controlId='formCompanyInscricaoEstadual'
                    >
                      <Form.Label>Inscrição Estadual</Form.Label>
                      <Form.Control
                        type='text'
                        name='inscricaoEstadual'
                        placeholder=''
                        defaultValue={inscricaoEstadual}
                        onChange={(e) => setInscricaoEstadual(e.target.value)}
                        maxLength='15'
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma Inscrição Estadual valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      as={Col}
                      controlId='formCompanyInscricaoMunicipal'
                    >
                      <Form.Label>Inscrição Municipal</Form.Label>
                      <Form.Control
                        type='text'
                        name='inscricaoMunicipal'
                        defaultValue={inscricaoMunicipal}
                        onChange={(e) => setInscricaoMunicipal(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma Inscrição Municipal valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col>
                    <Form.Label className='titlefromdropdraw'>
                      Certificado
                    </Form.Label>
                    <Form.Group as={Col}>
                      <FormControl>
                        <Select
                          defaultValue={certificado}
                          onChange={(e) => setCertificado(e.target.value)}
                          className='selectmenu'
                        >
                          {dataCertificate.map((response) => (
                            <MenuItem key={response.id} value={response.id}>
                              <div className='corMenu'>
                                {clearcertificate(response.nome)
                                  .split('=')[1]
                                  .split(' ', 3)
                                  .join(' ') +
                                  ' ' +
                                  formatDateCertificate(response.cadastro)}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col>
                    <Form.Label className='titlefromdropdraw'>
                      Regime Tributario
                    </Form.Label>
                    <Form.Group as={Col}>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          defaultValue={tax}
                          onChange={handleChangetax}
                          placeholder=''
                          required
                        >
                          <MenuItem value={0}>
                            <div className='corMenu'>Nenhum</div>
                          </MenuItem>
                          <MenuItem value={1}>
                            <div className='corMenu'>Simples Nacional</div>
                          </MenuItem>
                          <MenuItem value={2}>
                            <div className='corMenu'>
                              Simples Nacional - Excesso
                            </div>
                          </MenuItem>
                          <MenuItem value={3}>
                            <div className='corMenu'>
                              Normal - Lucro Presumido
                            </div>
                          </MenuItem>
                          <MenuItem value={4}>
                            <div className='corMenu'>Normal - Lucro Real</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label className='titlefromdropdraw'>
                      {' '}
                      Regime Tributario Especial
                    </Form.Label>
                    <Form.Group as={Col}>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          defaultValue={specialTax}
                          onChange={handleSpecialTax}
                          required
                        >
                          <MenuItem value={0}>
                            <div className='corMenu'>
                              Sem Regime Tributário Especial
                            </div>
                          </MenuItem>
                          <MenuItem value={1}>
                            <div className='corMenu'>
                              Micro Empresa Municipal
                            </div>
                          </MenuItem>
                          <MenuItem value={2}>
                            <div className='corMenu'>Estimativa</div>
                          </MenuItem>
                          <MenuItem value={3}>
                            <div className='corMenu'>
                              Sociedade de Profissionais
                            </div>
                          </MenuItem>
                          <MenuItem value={4}>
                            <div className='corMenu'>Cooperatival</div>
                          </MenuItem>
                          <MenuItem value={5}>
                            <div className='corMenu'>
                              Microempresário Individual - MEI
                            </div>
                          </MenuItem>
                          <MenuItem value={6}>
                            <div className='corMenu'>
                              Microempresa ou Pequeno Porte - ME EPP
                            </div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Check type='checkbox'>
                        <Form.Check.Input
                          type='checkbox'
                          name='simplesNacional'
                          id='formCompanySimplesNacional'
                          defaultValue={simplesNacional}
                          onChange={(e) => setSimplesNacional(true)}
                        />
                        <Form.Check.Label>Simples Nacional</Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Check type='checkbox'>
                        <Form.Check.Input
                          type='checkbox'
                          name='incentivoFiscal'
                          id='formCompanyIncentivoFiscal'
                          defaultValue={incentivoFiscal}
                          onChange={(e) => setIncentivoFiscal(true)}
                        />
                        <Form.Check.Label>Incentivo Fiscal</Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Check type='checkbox'>
                        <Form.Check.Input
                          type='checkbox'
                          name='incentivadorCultural'
                          id='formCompanyIncentivadorCultural'
                          defaultValue={incentivadorCultural}
                          onChange={(e) => setIncentivadorCultural(true)}
                        />
                        <Form.Check.Label>
                          Incentivador Cultural
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div id='endereco' className='form-block'>
                <h3>Endereço</h3>
                <Form.Row>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyCEP'>
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        placeholder=''
                        type='text'
                        name='cep'
                        defaultValue={cepEndereco}
                        onBlur={(ev) => onBlurCep(ev)}
                        onChange={(e) => setCepEndereco(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um CEP valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={2}>
                    <Form.Label className='titlefromdropdraw'>
                      Tipo Logradoro
                    </Form.Label>
                    <Form.Group as={Col} controlId='formCompanyTipoLogradouro'>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          defaultValue={tipoLogradouroEndereco}
                          onChange={handleTipoLogradouro}
                        >
                          <MenuItem value={'Empty'}>
                            <div className='corMenu'></div>
                          </MenuItem>
                          <MenuItem value={'Alameda'}>
                            <div className='corMenu'>Alameda</div>
                          </MenuItem>
                          <MenuItem value={'Avenida'}>
                            <div className='corMenu'>Avenida</div>
                          </MenuItem>
                          <MenuItem value={'Chácara'}>
                            <div className='corMenu'>Chácara</div>
                          </MenuItem>
                          <MenuItem value={'Colônia'}>
                            <div className='corMenu'>Colônia</div>
                          </MenuItem>
                          <MenuItem value={'Condomínio'}>
                            <div className='corMenu'>Condomínio</div>
                          </MenuItem>
                          <MenuItem value={'Estância'}>
                            <div className='corMenu'>Estância</div>
                          </MenuItem>
                          <MenuItem value={'Estrada'}>
                            <div className='corMenu'>Estrada</div>
                          </MenuItem>
                          <MenuItem value={'Fazenda'}>
                            <div className='corMenu'>Fazenda</div>
                          </MenuItem>
                          <MenuItem value={'Praça'}>
                            <div className='corMenu'>Praça</div>
                          </MenuItem>
                          <MenuItem value={'Prolongamento'}>
                            <div className='corMenu'>Prolongamento</div>
                          </MenuItem>
                          <MenuItem value={'Rodovia'}>
                            <div className='corMenu'>Rodovia</div>
                          </MenuItem>
                          <MenuItem value={'Rua'}>
                            <div className='corMenu'>Rua</div>
                          </MenuItem>
                          <MenuItem value={'Sítio'}>
                            <div className='corMenu'>Sítio</div>
                          </MenuItem>
                          <MenuItem value={'Travessa'}>
                            <div className='corMenu'>Travessa</div>
                          </MenuItem>
                          <MenuItem value={'Vicinal'}>
                            <div className='corMenu'>Vicinal</div>
                          </MenuItem>
                          <MenuItem value={'Eqnp'}>
                            <div className='corMenu'>Eqnp</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col xs={10}>
                    <Form.Group as={Col} controlId='formCompanyLogradouro'>
                      <Form.Label>Logradouro</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoLogradouro'
                        defaultValue={logradouroEndereco}
                        onChange={(e) => setLogradouroEndereco(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um logradouro valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row className=''>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyNumero'>
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoNumero'
                        required
                        defaultValue={numeroEndereco}
                        onChange={(e) => setNumeroEndereco(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Número valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyComplemento'>
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoComplemento'
                        defaultValue={complementoEndereco}
                        onChange={(e) => setComplementoEndereco(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um complemento valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Label className='titlefromdropdraw'>
                      Tipo de Bairro
                    </Form.Label>
                    <Form.Group as={Col}>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          defaultValue={tipoBairroEndereco}
                          onChange={handleTipoBairro}
                        >
                          <MenuItem value={'Empty'}>
                            <div className='corMenu'></div>
                          </MenuItem>
                          <MenuItem value={'Bairro'}>
                            <div className='corMenu'>Bairro</div>
                          </MenuItem>
                          <MenuItem value={'Bosque'}>
                            <div className='corMenu'>Bosque</div>
                          </MenuItem>
                          <MenuItem value={'Chácara'}>
                            <div className='corMenu'>Chácara</div>
                          </MenuItem>
                          <MenuItem value={'Conjunto'}>
                            <div className='corMenu'>Conjunto</div>
                          </MenuItem>
                          <MenuItem value={'Desmembramento'}>
                            <div className='corMenu'>Desmembramento</div>
                          </MenuItem>
                          <MenuItem value={'Distrito'}>
                            <div className='corMenu'>Distrito</div>
                          </MenuItem>
                          <MenuItem value={'Favela'}>
                            <div className='corMenu'>Favela</div>
                          </MenuItem>
                          <MenuItem value={'Fazenda'}>
                            <div className='corMenu'>Fazenda</div>
                          </MenuItem>
                          <MenuItem value={'Gleba'}>
                            <div className='corMenu'>Gleba</div>
                          </MenuItem>
                          <MenuItem value={'Horto'}>
                            <div className='corMenu'>Horto</div>
                          </MenuItem>
                          <MenuItem value={'Jardim'}>
                            <div className='corMenu'>Jardim</div>
                          </MenuItem>
                          <MenuItem value={'Loteamento'}>
                            <div className='corMenu'>Loteamento</div>
                          </MenuItem>
                          <MenuItem value={'Núcleo'}>
                            <div className='corMenu'>Núcleo</div>
                          </MenuItem>
                          <MenuItem value={'Parque'}>
                            <div className='corMenu'>Parque</div>
                          </MenuItem>
                          <MenuItem value={'Residencial'}>
                            <div className='corMenu'>Residencial</div>
                          </MenuItem>
                          <MenuItem value={'Sítio'}>
                            <div className='corMenu'>Sítio</div>
                          </MenuItem>
                          <MenuItem value={'Tropical'}>
                            <div className='corMenu'>Tropical</div>
                          </MenuItem>
                          <MenuItem value={'Vila'}>
                            <div className='corMenu'>Vila</div>
                          </MenuItem>
                          <MenuItem value={'Zona'}>
                            <div className='corMenu'>Zona</div>
                          </MenuItem>
                          <MenuItem value={'Centro'}>
                            <div className='corMenu'>Centro</div>
                          </MenuItem>
                          <MenuItem value={'Setor'}>
                            <div className='corMenu'>Setor</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group as={Col} controlId='formCompanyBairro'>
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoBairro'
                        defaultValue={bairroEndereco}
                        onChange={(e) => setBairrooEndereco(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um bairro valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={2} hidden>
                    <Form.Group
                      as={Col}
                      controlId='formCompanyCodCidade'
                      hidden
                    >
                      <Form.Label>Código cidade</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoCodigoCidade'
                        required
                        defaultValue={codigoCidadeEndereco}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Código cidade valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group as={Col} controlId='formCompanyCidade'>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoCidade'
                        defaultValue={descricaoCidadeEndereco}
                        onChange={(e) =>
                          setDescricaoCidadeEndereco(e.target.value)
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Cidade valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Label className='titlefromdropdraw'>
                      Tipo de Estado
                    </Form.Label>
                    <Form.Group as={Col} controlId='formCompanyEstado'>
                      <FormControl>
                        <Select
                          className='selectmenu'
                          defaultValue={estadoEndereco}
                          onChange={handleEstado}
                        >
                          <MenuItem value={'AC'}>
                            <div className='corMenu'>AC</div>
                          </MenuItem>
                          <MenuItem value={'AL'}>
                            <div className='corMenu'>AL</div>
                          </MenuItem>
                          <MenuItem value={'AM'}>
                            <div className='corMenu'>AM</div>
                          </MenuItem>
                          <MenuItem value={'AP'}>
                            <div className='corMenu'>AP</div>
                          </MenuItem>
                          <MenuItem value={'BA'}>
                            <div className='corMenu'>BA</div>
                          </MenuItem>
                          <MenuItem value={'CE'}>
                            <div className='corMenu'>CE</div>
                          </MenuItem>
                          <MenuItem value={'DF'}>
                            <div className='corMenu'>DF</div>
                          </MenuItem>
                          <MenuItem value={'ES'}>
                            <div className='corMenu'>ES</div>
                          </MenuItem>
                          <MenuItem value={'GO'}>
                            <div className='corMenu'>GO</div>
                          </MenuItem>
                          <MenuItem value={'MA'}>
                            <div className='corMenu'>MA</div>
                          </MenuItem>
                          <MenuItem value={'MG'}>
                            <div className='corMenu'>MG</div>
                          </MenuItem>
                          <MenuItem value={'MS'}>
                            <div className='corMenu'>MS</div>
                          </MenuItem>
                          <MenuItem value={'MT'}>
                            <div className='corMenu'>MT</div>
                          </MenuItem>
                          <MenuItem value={'PA'}>
                            <div className='corMenu'>PA</div>
                          </MenuItem>
                          <MenuItem value={'PB'}>
                            <div className='corMenu'>PB</div>
                          </MenuItem>
                          <MenuItem value={'PE'}>
                            <div className='corMenu'>PE</div>
                          </MenuItem>
                          <MenuItem value={'PI'}>
                            <div className='corMenu'>PI</div>
                          </MenuItem>
                          <MenuItem value={'PR'}>
                            <div className='corMenu'>PR</div>
                          </MenuItem>
                          <MenuItem value={'RJ'}>
                            <div className='corMenu'>RJ</div>
                          </MenuItem>
                          <MenuItem value={'RN'}>
                            <div className='corMenu'>RN</div>
                          </MenuItem>
                          <MenuItem value={'RO'}>
                            <div className='corMenu'>RO</div>
                          </MenuItem>
                          <MenuItem value={'RR'}>
                            <div className='corMenu'>RR</div>
                          </MenuItem>
                          <MenuItem value={'RS'}>
                            <div className='corMenu'>RS</div>
                          </MenuItem>
                          <MenuItem value={'SC'}>
                            <div className='corMenu'>SC</div>
                          </MenuItem>
                          <MenuItem value={'SE'}>
                            <div className='corMenu'>SE</div>
                          </MenuItem>
                          <MenuItem value={'SP'}>
                            <div className='corMenu'>SP</div>
                          </MenuItem>
                          <MenuItem value={'TO'}>
                            <div className='corMenu'>TO</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group
                      as={Col}
                      controlId='formCompanyCountryDescription'
                    >
                      <Form.Label>Código país</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoCountryDescription'
                        required
                        defaultValue={codigoPaisEndereco}
                        placeholder=''
                        onChange={(e) => setCodigoPaisEndereco(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Código país valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group as={Col} controlId='formCompanyPais'>
                      <Form.Label>País</Form.Label>
                      <Form.Control
                        type='text'
                        name='enderecoCodigoPais'
                        required
                        defaultValue={descricaoPaisEndereco}
                        onChange={(e) =>
                          setDescricaoPaisEndereco(e.target.value)
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um País valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div id='contato' className='form-block'>
                <h3>Contato</h3>
                <Form.Row>
                  <Col xs={2}>
                    <Form.Group as={Col} controlId='formCompanyDDD'>
                      <Form.Label>DDD</Form.Label>
                      <Form.Control
                        placeholder=''
                        type='text'
                        name='telefoneDDD'
                        defaultValue={dddTelefone}
                        maxLength='2'
                        onChange={(e) => setDddTelefone(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um DDD país valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group as={Col} controlId='formCompanyTelefone'>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        placeholder=''
                        type='phone'
                        name='telefoneNumero'
                        defaultValue={numeroTelefone}
                        onChange={(e) => setNumeroTelefone(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Telefone valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group as={Col} controlId='formCompanyEmail'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder=''
                        type='email'
                        name='email'
                        defaultValue={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um email valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>

              <div className='titlefrom'>
                <h3>NFS-e</h3> &nbsp;&nbsp;&nbsp;&nbsp;
                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  defaultValue={ativoNfse}
                  onChange={handleAtivoNfse}
                  placeholder=''
                >
                  <MenuItem value={false}>
                    <div className='corMenu'>Desativa a emissão de NFS-e.</div>
                  </MenuItem>
                  <MenuItem value={true}>
                    <div className='corMenu'>Ativa a emissão de NFS-e.</div>
                  </MenuItem>
                </Select>
              </div>

              {ativoNfse && (
                <div id='nfse' className='form-block'>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Select
                          labelId='demo-simple-select-helper-label'
                          defaultValue={producaoConfigNfse}
                          onChange={handleProducaoNfse}
                        >
                          <MenuItem value={false}>
                            <div className='corMenu'>Emissão homologação..</div>
                          </MenuItem>
                          <MenuItem value={true}>
                            <div className='corMenu'>Emissão em produção.</div>
                          </MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check type='checkbox' id='formCompanyEmailNFSE'>
                          <Form.Check.Input
                            type='checkbox'
                            defaultValue={envioEmailRpsConfigNfse}
                            onChange={(e) => setEnvioEmailRpsConfigNfse(true)}
                          />
                          <Form.Check.Label>Email</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={4}>
                      <FormControl>
                        <Form.Label className='titlefromdropdraw'>
                          Tipo de Contrato
                        </Form.Label>
                        <Select
                          className='selectmenudois'
                          labelId='demo-simple-select-helper-label'
                          defaultValue={tipoContratoNfse}
                          onChange={handleTipoContratoNfse}
                        >
                          <MenuItem value={0}>
                            <div className='corMenu'>Bilhetagem</div>
                          </MenuItem>
                          <MenuItem value={1}>
                            <div className='corMenu'>Ilimitado</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={4}>
                      <Form.Group
                        as={Col}
                        controlId='formCompanyLoginPrefeituraNFSE'
                      >
                        <Form.Label>Login Prefeitura</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfseLoginPrefeitura'
                          defaultValue={loginPrefeituraRpsConfigNfse}
                          onChange={(e) =>
                            setLoginPrefeituraRpsConfigNfse(e.target.value)
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um login valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group
                        as={Col}
                        controlId='formCompanySenhaPrefeituraNFSE'
                      >
                        <Form.Label>Senha Prefeitura</Form.Label>
                        <Form.Control
                          type='password'
                          name='nfseSenhaPrefeitura'
                          defaultValue={senhaPrefeituraRpsConfigNfse}
                          onChange={(e) =>
                            setSenhaPrefeituraRpsConfigNfse(e.target.value)
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma senha valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanyRPSSerieNFSE'>
                        <Form.Label>Série RPS</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfseRpsSerie'
                          defaultValue={serieConfigNfse}
                          onChange={(e) => setSerieConfigNfse(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma série valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanyRPSNumeroNFSE'>
                        <Form.Label>Número RPS</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfseRpsNumero'
                          defaultValue={numeroRpsConfigNfse}
                          onChange={(e) =>
                            setNumeroRpsConfigNfse(e.target.value)
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um número valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanyRPSLoteNFSE'>
                        <Form.Label>Lote RPS</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfseRpsLote'
                          defaultValue={loteRpsConfigNfse}
                          onChange={(e) => setLoteRpsConfigNfse(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um lote valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </div>
              )}
              <div className='titlefrom'>
                <h3>NF-e</h3> &nbsp;&nbsp;&nbsp;&nbsp;
                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  defaultValue={ativoNfe}
                  onChange={handleAtivoNfe}
                  placeholder=''
                >
                  <MenuItem value={false}>
                    <div className='corMenu'>Desativa a emissão de NF-e.</div>
                  </MenuItem>
                  <MenuItem value={true}>
                    <div className='corMenu'>Ativa a emissão de NF-e.</div>
                  </MenuItem>
                </Select>
              </div>
              {ativoNfe && (
                <div id='nfe' className='form-block'>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Select
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          defaultValue={producaoConfigNfe}
                          onChange={handleProducaoNfe}
                        >
                          <MenuItem value={false}>
                            <div className='corMenu'>
                              Emissão em homologação..
                            </div>
                          </MenuItem>
                          <MenuItem value={true}>
                            <div className='corMenu'>Emissão em produção.</div>
                          </MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check type='checkbox' id='formCompanyEmailNFE'>
                          <Form.Check.Input
                            type='checkbox'
                            name='nfeEmail'
                            defaultValue={envioEmailConfigNfe}
                            onChange={(e) => setEnvioEmailConfigNfe(true)}
                          />
                          <Form.Check.Label>Email</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <Form.Check
                          type='checkbox'
                          id='formCompanyImpressaoFCPNFE'
                        >
                          <Form.Check.Input
                            type='checkbox'
                            defaultValue={impressaoFcpConfigNfe}
                            onChange={(e) => setImpressaoFcpConfigNfe(true)}
                          />
                          <Form.Check.Label>Impressao FCP</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          type='checkbox'
                          id='formCompanyImpressaoPartilhaoNFE'
                        >
                          <Form.Check.Input
                            type='checkbox'
                            defaultValue={impressaoPartilhaConfigNfe}
                            onChange={(e) =>
                              setImpressaoPartilhaConfigNfe(true)
                            }
                          />
                          <Form.Check.Label>
                            Impressão partilha
                          </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  {/* Série NFe */}
                  <Form.Row>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanyNumeroNFEEntrada'>
                        <Form.Label>Série NFe de entrada</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfeConfigNumeroEntrada'
                          value={numeroConfigNfeEntrada}
                          onChange={(e) => setNumeroConfigNfeEntrada(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um número valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanySerieNFE'>
                        <Form.Label>Série NFe de saída</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfeConfigSerie'
                          value={serieConfigNfe}
                          onChange={(e) => setSerieConfigNfe(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma série valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  {/* Número NFe */}
                  <Form.Row>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanySerieNFEEntrada'>
                        <Form.Label>Número NFe de entrada</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfeConfigSerie'
                          value={serieConfigNfeEntrada}
                          onChange={(e) => setSerieConfigNfeEntrada(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma série valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanyNumeroNFE'>
                        <Form.Label>Número NFe de saída</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfeConfigNumero'
                          value={numeroConfigNfe}
                          onChange={(e) => setNumeroConfigNfe(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um número valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </div>
              )}
              <div className='titlefrom'>
                <h3>NFC-e</h3> &nbsp;&nbsp;&nbsp;&nbsp;
                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  defaultValue={ativoNfce}
                  onChange={handleAtivoNfce}
                  placeholder=''
                >
                  <MenuItem value={false}>
                    <div className='corMenu'>Desativa a emissão de NFC-e.</div>
                  </MenuItem>
                  <MenuItem value={true}>
                    <div className='corMenu'>Ativa a emissão de NFC-e.</div>
                  </MenuItem>
                </Select>
              </div>
              {ativoNfce && (
                <div id='nfce' className='form-block'>
                  <Form.Row className='accordion-body'>
                    <Col>
                      <Form.Group>
                        <Select
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          defaultValue={producaoConfigNfce}
                          onChange={handleProducaoNfce}
                        >
                          <MenuItem value={false}>
                            <div className='corMenu'>
                              Emissão em homologação..
                            </div>
                          </MenuItem>
                          <MenuItem value={true}>
                            <div className='corMenu'>Emissão em produção.</div>
                          </MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check type='checkbox' id='formCompanyEmailNFCE'>
                          <Form.Check.Input
                            type='checkbox'
                            defaultValue={envioEmailNfce}
                            onChange={(e) => setEnvioEmailNfce(true)}
                          />
                          <Form.Check.Label>Email</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={4}>
                      <FormControl>
                        <Form.Label className='titlefromdropdraw'>
                          Tipo de Contrato
                        </Form.Label>
                        <Select
                          className='selectmenudois'
                          labelId='demo-simple-select-helper-label'
                          id='demo-simple-select-helper'
                          defaultValue={tipoContratoNfce}
                          onChange={handleTipoContratoNfce}
                          placeholder=''
                        >
                          <MenuItem value={0}>
                            <div className='corMenu'>Bilhetagem</div>
                          </MenuItem>
                          <MenuItem value={1}>
                            <div className='corMenu'>Ilimitado</div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanySerieNFCE'>
                        <Form.Label>Série NFCe</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfceConfigSerie'
                          defaultValue={serieConfigNfce}
                          onChange={(e) => setSerieConfigNfce(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha uma Série valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group as={Col} controlId='formCompanyNumeroNFCE'>
                        <Form.Label>Número NFCe</Form.Label>
                        <Form.Control
                          type='text'
                          name='nfceConfigNumero'
                          defaultValue={numeroConfigNfce}
                          onChange={(e) => setNumeroConfigNfce(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um Número contrato valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanyIDCodSegNFCE'>
                        <Form.Label>
                          ID código de segurança do contribuinte
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='nfceSefazIdCod'
                          defaultValue={
                            idCodigoSegurancaContribuinteConfigSefazNfe
                          }
                          onChange={(e) =>
                            setDdCodigoSegurancaContribuinteConfigSefazNfe(
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um ID valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Col} controlId='formCompanyCodSegNFCE'>
                        <Form.Label>
                          Código de segurança do contribuinte
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='nfceSefazCod'
                          defaultValue={
                            codigoSegurancaContribuinteConfigSefazNfe
                          }
                          onChange={(e) =>
                            setCodigoSegurancaContribuinteConfigSefazNfe(
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          Escolha um Código valido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </div>
              )}
              <div className='registerCompanyButton'>
                <Button
                  style={{
                    width: 'auto',
                  }}
                  variant='primary'
                  onClick={handleSubmit}
                  className='subimit-btn'
                >
                  Cadastrar empresa
                </Button>
              </div>
            </Form>
            <Toast />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default NewCompanies;
