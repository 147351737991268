import React, { useContext, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { CustomToggle } from '../../../components/CustomToggle/CustomToggle';
import { notifyError } from '../../../components/Toast/ToastController';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { getNfeByZohoBooksId } from '../../../services/Manager/MongoDB/Nfe/NfeManager';

export default function FilterNfe({
  handleSetNfes,
  handleSetLoadingNfes,
  handleSetOrgId,
  loadingNfes,
}) {
  const global = useContext(GlobalContext);
  const [filter, setFilter] = useState({ type: 0, searchValue: '' });

  const typeEnum = {
    0: 'Nenhum',
    2: 'ID Books',
    4:
      global.userCnpj.length > 1 ||
      global.userType === 1 ||
      global.userType === 3
        ? 'Empresa'
        : '',
  };

  const handleFilterOption = (event) => {
    const type = Number(event?.target?.value);
    setFilter((prev) => {
      return {
        ...prev,
        type,
      };
    });
  };

  const handleFilterSearch = (event) => {
    const searchValue = event.target.value;
    setFilter((prev) => {
      return {
        ...prev,
        searchValue,
      };
    });
  };

  const handleSubmitSearch = async () => {
    try {
      handleSetNfes([]);
      handleSetLoadingNfes(true);
      const result = await getNfeByZohoBooksId(filter.searchValue);

      handleSetNfes(result);
      handleSetLoadingNfes(false);
    } catch (err) {
      handleSetLoadingNfes(false);
      notifyError('Erro ao buscar NFEs no servidor.');
    }
  };
  const handleOrgId = async (event) => {
    handleSetOrgId(event.target.value);
  };

  // const handleLimitNotes = (event) => {
  //   setLimitNotesToShow(event.target.value);
  // };
  return (
    <Dropdown drop={global.userType === 2 && 'right'}>
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
      <Dropdown.Menu>
        <Row className='dropMenuClients'>
          <Col className='filterCol'>
            <Form.Label className='filterLabel'>Filtrar por</Form.Label>
            <Form.Control
              className='filterSelect'
              as='select'
              onChange={handleFilterOption}
              value={filter.type}
            >
              {Object.keys(typeEnum).map((type) => (
                <>
                  {typeEnum[type] !== '' && (
                    <option key={type} value={type}>
                      {typeEnum[type]}
                    </option>
                  )}
                </>
              ))}
            </Form.Control>
          </Col>

          {filter.type === 2 && (
            <>
              <Col className='searchFilterCol'>
                <Form.Label className='filterLabel'>
                  {typeEnum[filter.type]}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                  onChange={handleFilterSearch}
                  value={filter.searchValue}
                ></Form.Control>
              </Col>
              <Button
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                onClick={handleSubmitSearch}
                disabled={!filter.searchValue.trim()}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}

          {filter.type === 4 && (
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <>
                <Form.Label className='filterLabel'>
                  Escolha uma empresa
                </Form.Label>
                <Form.Control
                  className='selectLimit'
                  as='select'
                  onChange={handleOrgId}
                  disabled={filter.type === 2}
                >
                  <option key={''} value={''}>
                    Nenhum
                  </option>
                  {global.enterpriseList.map((enterprise) => (
                    <option key={enterprise.id} value={enterprise.idOrg}>
                      {enterprise.nomeFantasia}
                    </option>
                  ))}
                </Form.Control>
              </>
            </Col>
          )}
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
}
