import React, { useEffect, useState } from 'react';
import imageEdit from '../../../assets/img/iconEdit.png';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import './ProfileImageUpdate.scss';

export default function ProfileImageUpdate() {
  const editImageProfileUpdate = document.querySelector('.btnEdit');
  const profileImage = document.querySelector('.imageProfileUpdate');
  const [image, setImage] = useState();
  const { id } = useParams();
  const idUser = localStorage.getItem('idUser');

  useEffect(() => {
    async function getProfileImage() {
      return await api.get('/profileImage/' + id);
    }
    getProfileImage().then((response) => {
      setImage(response.data.response);
    });
    if (image !== undefined && image.length !== 0) changeProfileImage();
  });

  function changeProfileImage() {
    profileImage.style.backgroundImage = `url(${image})`;
  }

  function handleOnClick(evt) {
    if (!(evt.target && evt.target.files && evt.target.files.length > 0)) {
      return;
    }

    let r = new FileReader();
    r.onload = async function () {
      const base64String = r.result;
      profileImage.style.backgroundImage = `url(${base64String})`;
      await api.put('/profileImage', {
        id: idUser,
        profileImage: base64String,
      });
      return window.location.reload(false);
    };
    r.readAsDataURL(evt.target.files[0]);
  }

  function addEditComponent() {
    editImageProfileUpdate.style.display = 'flex';
  }

  function removeEditComponent() {
    editImageProfileUpdate.style.display = 'none';
  }

  return (
    <>
      <div
        className='imageProfileUpdate'
        onMouseOver={addEditComponent}
        onMouseLeave={removeEditComponent}
      >
        <div class='overlay'>
          <div className='btnEdit'>
            <label className='custom-file-upload'>
              <input
                type='file'
                accept='image/png, image/jpeg'
                className='image-insert'
                onChange={handleOnClick}
              />
              <img
                href='file'
                src={imageEdit}
                alt='ícone de alteração de perfil'
                width='20px'
                height='20px'
              />
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
