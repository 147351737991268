import React from 'react';
import { Filter } from 'react-bootstrap-icons';
import './CustomToggle.scss';

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
    className='filter'
  >
    {' '}
    Filtro <Filter size={22} />
  </button>
));
