import React from 'react';

import './Home.scss';
import { Container } from 'react-bootstrap';

import DashClient from '../_components/dashboardClient/DashClient';
import DashCertificate from '../_components/dashboardCertificate/DashCertificate';
import DashNfe from '../_components/dashboardNfe/DashNfe';
import DashNfse from '../_components/dashboardNfse/DashNfse';

function Home() {
  return (
    <>
      <Container className='ContainerDash' fluid>
        <Container style={{ maxWidth: '100%' }}>
          <div className='ContainerDash'>
            <DashClient />
            <DashCertificate />
          </div>
          <div style={{ flexDirection: 'column' }} className='ContainerDash'>
            <DashNfe />
            <DashNfse />
          </div>
        </Container>
      </Container>
    </>
  );
}

export default Home;
