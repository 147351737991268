import React, { useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { CustomToggle } from '../../../components/CustomToggle/CustomToggle';

const typeEnum = {
  0: 'Nenhum',
  1: 'Email',
  2: 'Status',
  3: 'Nome',
  4: 'Tipo',
};
export default function FilterUsers({
  handleSetDataUserShow,
  data,
  qntUsersShow,
  handleSetDataUser,
  handleSetLoad,
  load,
  back,
}) {
  const [filter, setFilter] = useState({
    type: 0,
    value: '',
    searchValue: '',
  });

  const handleFilterOption = (event) => {
    handleSetLoad(!load);

    const type = Number(event?.target?.value);
    setFilter((prev) => {
      return {
        ...prev,
        searchValue: '',
        type,
      };
    });
  };

  const handleFilterSearch = (event) => {
    handleSetDataUser(back);
    handleSetDataUserShow(
      back.filter(
        (item, i) =>
          i < qntUsersShow * 1 && i >= qntUsersShow * 1 - qntUsersShow
      )
    );
    const searchValue = event.target.value;
    setFilter((prev) => {
      return {
        ...prev,
        searchValue,
      };
    });
  };

  const handleSubmitSearch = async () => {
    const newDataFiltered = data.filter((item) => {
      if (filter.type === 1) {
        if (
          item.email
            .replace(/ /g, '')
            .toLowerCase()
            .includes(filter.searchValue.replace(/ /g, '').toLowerCase())
        ) {
          return item;
        } else {
          return null;
        }
      } else if (filter.type === 2) {
        if (item.status === filter.searchValue) {
          return item;
        } else {
          return null;
        }
      } else if (filter.type === 3) {
        if (
          item.name
            .replace(/ /g, '')
            .toLowerCase()
            .includes(filter.searchValue.replace(/ /g, '').toLowerCase())
        ) {
          return item;
        } else {
          return null;
        }
      } else if (filter.type === 4) {
        if (item.usertype === +filter.searchValue) {
          return item;
        } else {
          return null;
        }
      } else {
        return true;
      }
    });
    return (
      handleSetDataUserShow(
        newDataFiltered.filter(
          (item, i) =>
            i < qntUsersShow * 1 && i >= qntUsersShow * 1 - qntUsersShow
        )
      ),
      handleSetDataUser(newDataFiltered)
    );
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
      <Dropdown.Menu>
        <Row className='dropMenuClients'>
          <Col className='filterCol'>
            <Form.Label className='filterLabel'>Filtrar por:</Form.Label>
            <Form.Control
              className='filterSelect'
              as='select'
              onChange={handleFilterOption}
              value={filter.type}
            >
              {Object.keys(typeEnum).map((type) => (
                <>
                  {typeEnum[type] !== '' && (
                    <option key={type} value={type}>
                      {typeEnum[type]}
                    </option>
                  )}
                </>
              ))}
            </Form.Control>
          </Col>

          {(filter.type === 1 || filter.type === 3) && (
            <>
              <Col className='searchFilterCol'>
                <Form.Label className='filterLabel'>
                  {typeEnum[filter.type]}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                  onChange={handleFilterSearch}
                  value={filter.searchValue}
                ></Form.Control>
              </Col>
              <Button
                type='button'
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                disabled={filter.searchValue === ''}
                onClick={handleSubmitSearch}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}

          {filter.type === 2 && (
            <>
              <Col className='searchFilterCol'>
                <Form.Label className='filterLabel'>
                  {typeEnum[filter.type]}
                </Form.Label>
                <Form.Control
                  as='select'
                  placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                  onChange={handleFilterSearch}
                  value={filter.searchValue}
                >
                  <option key={0}>Escolha uma opção</option>
                  <option key={1} value={'Ativo'}>
                    Ativo
                  </option>
                  <option key={2} value={'Inativo'}>
                    Inativo
                  </option>
                </Form.Control>
              </Col>
              <Button
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                onClick={handleSubmitSearch}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}

          {filter.type === 4 && (
            <>
              <Col className='searchFilterCol'>
                <Form.Label className='filterLabel'>
                  {typeEnum[filter.type]}
                </Form.Label>
                <Form.Control
                  as='select'
                  placeholder={`Pesquisar ${typeEnum[filter.type]}`}
                  onChange={handleFilterSearch}
                  value={filter.searchValue}
                >
                  <option key={0}>Escolha uma opção</option>
                  <option key={1} value={2}>
                    Cliente
                  </option>
                  <option key={2} value={1}>
                    Administrador
                  </option>
                  <option key={3} value={3}>
                    Super Administrador
                  </option>
                </Form.Control>
              </Col>
              <Button
                style={{
                  alignSelf: 'flex-end',
                  height: 'fit-content',
                }}
                onClick={handleSubmitSearch}
              >
                <Search color='#fff' size={17} />
              </Button>
            </>
          )}
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
}
