import api from '../../../api';

export const nfseInitialData = {
  idIntegracao: '',
  idPlugnotas: '',
  idInvoiceZohoBook: '',
  idOrganizacaoZohoBook: '',
  moduleZohoBook: '',
  statusPlugnotas: '',
  statusVariable: '',
  dataHoraCriacao: '',
  _id: '',
};

export const getNfseByZohoBooksId = async (zohoBooksId) => {
  const { data } = await api.get(
    `/notes/nfse/search/idInvoiceZohoBook/${zohoBooksId}`
  );
  return data;
};

export const getPaginatedNfse = async ({ page, limit }) => {
  const { data } = await api.get(`/notes/nfse?page=${page}&limit=${limit}`);

  return data;
};

export async function getNfseById(id) {
  const { data } = await api.get(`notes/nfse/${id}`);
  return data[0] ?? {};
}
export async function getNfseByOrgId({ orgId, page, limit }) {
  const { data } = await api.get(
    `notes/nfse/getByOrgId/${orgId}?page=${page}&limit=${limit}`
  );
  return data;
}
