import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../contexts/GlobalStorage';
import api from '../services/api';

export default function useClients() {
  const global = useContext(GlobalContext);
  const [loadingClients, setLoadingClients] = useState(false);
  const [dataClient, setDataClient] = useState([]);
  const [dataClientShow, setDataClientShow] = useState([]);
  const [qntClientShow, setQntClientShow] = useState(10);
  const [back, setBack] = useState([]);
  const [load, setLoad] = useState(false);
  const [paginators, setPaginators] = useState([]);

  useEffect(() => {
    async function allClient() {
      setLoadingClients(true);

      if (global.userType === 2) {
        setDataClientShow(global.enterpriseList);
      } else {
        const { data } = await api.get('/client');
        if (Object.keys(data).length === 0) {
          return setDataClientShow([]);
        }
        data.sort((a, b) =>
          a.companyName > b.companyName
            ? 1
            : b.companyName > a.companyName
            ? -1
            : 0
        );

        let paginators = [];
        for (let i = 0; i < data.length / qntClientShow; i++) {
          paginators.push('');
        }
        setPaginators(paginators);

        setDataClient(data);
        setBack(data);
        setDataClientShow(
          data.filter(
            (item, i) =>
              i < qntClientShow * 1 && i >= qntClientShow * 1 - qntClientShow
          )
        );
      }
      setLoadingClients(false);
    }

    allClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.enterpriseList, load, qntClientShow]);

  return {
    loadingClients,
    dataClient,
    setDataClient,
    dataClientShow,
    setDataClientShow,
    qntClientShow,
    setQntClientShow,
    back,
    load,
    setLoad,
    paginators,
  };
}
