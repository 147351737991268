import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  EyeFill,
  Clipboard,
  PencilSquare,
  Link45deg,
  ArrowLeft,
} from 'react-bootstrap-icons';
import { Link, useParams } from 'react-router-dom';
import { Toast } from '../../../components/Toast/Toast';
import api from '../../../services/api';
import { getIName, getType } from '../../../services/Auth/Login';
import './UpdateClients.scss';
import Buttons from '../../../components/Button/Button';
import { copyToClipBoard } from '../../../services/Global/clipboard/Clipboard';
import {
  notifySuccess,
  notifyError,
} from '../../../components/Toast/ToastController';
import axios from 'axios';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import ok from '../../../assets/img/ok.png';
import error from '../../../assets/img/error.png';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { addBusinessDays } from 'date-fns';
import ClientImage from '../../../components/ClientImage/ClientImage';

export default function UpdateClients() {
  const global = useContext(GlobalContext);
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [cnpjData, setCnpj] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [enablePlugNota, setEnablePlugNota] = useState(false);
  const [limitRequestCallApi, setLimitRequestCallApi] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [apikey, setApikey] = useState('');
  const [apiKeyIntegration, setApiKeyIntegration] = useState('');
  const [bankCharges, setBankCharges] = useState('');
  const [enableBillet, setEnableBillet] = useState(false);

  const [validated, setValidated] = useState(false);
  const [dataEnterprise, setDataEnterprise] = useState([]);
  const [show, setShow] = useState(false);
  const [showGreatToken, setShowGreatToken] = useState(false);
  const [dataIntegrations, setDataIntegrations] = useState({});
  const [dataIntegrationsKey, setDataIntegrationsKey] = useState([]);

  const [dataIntegrationsGreatToken, setDataIntegrationsGreatToken] = useState(
    {}
  );
  const [dataIntegrationsKeyGreatToken, setDataIntegrationsKeyGreatToken] =
    useState([]);

  const [clientID, setClientID] = useState('');
  const [linkgranttoken, setLinkgranttoken] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [grantToken, setGrantToken] = useState('');
  const [refreshtoken, setRefreshToken] = useState('');

  const [accountToken, setAccountToken] = useState('');
  const [bankAccountToken, setBankAccountToken] = useState('');

  const [statesBrazil, setStatesBrazil] = useState([]);

  const [refreshStatus, setRefreshStatus] = useState('');
  const [accessStatus, setAccessStatus] = useState('');

  const { id } = useParams();

  const redirect = `${process.env.REACT_APP_URL_GRANT_TOKEN}`;
  const valueredirect = `${process.env.REACT_APP_URL_GRANT_TOKEN}`;

  useEffect(() => {
    if (global.userType == 2) {
      history.push('/home');
    }
  });

  const toastGrantToken = (opcao) => {
    const loginSuccessful = opcao;
    if (loginSuccessful) {
      return notifySuccess('Grant Token criado com sucesso');
    } else {
      return notifyError('Erro ao criar o Grant Token');
    }
  };

  const onBlurIDClient = (env) => {
    const { value } = env.target;

    const response = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBooks.fullaccess.all&client_id=${value}&state=Homolog&response_type=code&redirect_uri=${redirect}&access_type=offline`;

    return response;
  };

  // const onBlurRefreshToken = async (env) => {
  //   const { value } = env.target;

  //   const response = await axios.post(`https://accounts.zoho.com/oauth/v2/token?code=${value}&client_id=${clientID}&client_secret=${clientSecret}&redirect_uri=${redirect}&grant_type=authorization_code`);

  //   if (!response.data.refresh_token) {
  //     toastGrantToken(false);
  //     return
  //   } else {
  //     toastGrantToken(true);
  //     return response.data.refresh_token
  //   }

  // };

  const formatCPFCNPJ = (cpfCnpj) => {
    const cpfFormatted = cpfCnpj.replace(/\D+/g, '');
    if (cpfCnpj.length === 11) {
      if (!cpf.isValid(cpfCnpj)) {
        setCnpj(cpfFormatted);
      }

      if (cpf.isValid(cpfCnpj)) {
        setCnpj(cpf.format(cpfCnpj));
      }
    } else if (cpfCnpj.length === 14) {
      if (!cnpj.isValid(cpfCnpj)) {
        setCnpj(cpfFormatted);
      }
      if (!cnpj.isValid(cpfCnpj)) {
        setCnpj(cnpj.format(cpfCnpj));
      }
    } else {
      setCnpj(cpfFormatted);
    }
  };

  const getStatesIBGE = async () => {
    const url = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';
    const response = await axios.get(url);
    const data = response.data;
    const states = data.map((item) => {
      return item.sigla;
    });
    const statesInOrder = states.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    return setStatesBrazil(statesInOrder);
  };

  const validateData = async () => {
    if (!name)
      return { error: 'É necessário digitar um responsável para prosseguir' };
    if (!companyName)
      return {
        error: 'É necessário digitar o nome da empresa para prosseguir',
      };
    if (!email)
      return { error: 'É necessário digitar um email válido para prosseguir' };
    if (!city)
      return { error: 'É necessário digitar uma cidade para prosseguir' };
    if (!cpf.isValid(cnpjData) && !cnpj.isValid(cnpjData))
      return { error: 'O cpf ou cnpj digitado não é válido' };
    if (limitRequestCallApi <= 0)
      return {
        error:
          'É necessário adicionar um valor para número máximo de notas a serem emitidas',
      };
    if (state.length === 0)
      return { error: 'É necessário escolher um estado para prosseguir' };

    return true;
  };

  useEffect(() => {
    async function allEnterprise() {
      const response = await api.get('/enterprise/client/' + id);
      setDataEnterprise(response.data);
    }

    const notaProduto = JSON.parse(process.env.REACT_APP_ARRAY_NOTAPRODUTO);
    const notaServico = JSON.parse(process.env.REACT_APP_ARRAY_NOTASERVICO);
    const boletoCloudCriar = JSON.parse(
      process.env.REACT_APP_URL_BOLETOCLOUD_CRIAR
    );
    const boletoCloudAtualizarVencimento = JSON.parse(
      process.env.REACT_APP_URL_BOLETOCLOUD_ATUALIZAR_VENCIMENTO
    );
    const boletoCloudBaixarBoleto = JSON.parse(
      process.env.REACT_APP_URL_BOLETOCLOUD_BAIXAR_BOLETO
    );
    const boletoCloudHomologarPagamento = JSON.parse(
      process.env.REACT_APP_URL_BOLETOCLOUD_HOMOLOGAR_PAGAMENTO
    );

    async function allClient() {
      const response = await api.get('/client/' + id);

      setName(response.data.name);
      setEmail(response.data.email);
      setCompanyName(response.data.companyName);
      setCnpj(response.data.cnpj);
      setState(response.data.state);
      setCity(response.data.city);
      setApiKeyIntegration(response.data.tokenKeyHeader);
      if (response.data.integration)
        if (response.data.integration.plugNotas)
          setEnablePlugNota(response.data.integration.plugNotas.enable);
      if (response.data.integration)
        if (response.data.integration.zohobooks)
          setOrganizationId(response.data.integration.zohobooks.organizationId);
      if (response.data.integration)
        if (response.data.integration.plugNotas)
          setLimitRequestCallApi(
            response.data.integration.plugNotas.limitRequestCallApi
          );
      if (response.data.integration)
        if (response.data.integration.zohobooks)
          setOrganizationId(response.data.integration.zohobooks.organizationId);
      if (response.data.integration)
        if (response.data.integration.zohobooks)
          setApikey(response.data.integration.zohobooks.apikey);
      if (response.data.integration)
        if (response.data.integration.billet)
          setBankCharges(response.data.integration.billet.bankCharges);
      if (response.data.integration)
        if (response.data.integration.billet)
          setEnableBillet(response.data.integration.billet.enableBillet);
      if (response.data.integration)
        if (response.data.integration.billet)
          setAccountToken(response.data.integration.billet.accountToken);
      if (response.data.integration)
        if (response.data.integration.billet)
          setBankAccountToken(
            response.data.integration.billet.bankAccountToken
          );
    }

    const defineDataIntegrations = () => {
      const dataIntegration = {
        products: {
          title: 'Notas de produtos',
          links: notaProduto,
        },
        clients: {
          title: 'Notas de clientes',
          links: notaServico,
        },
        boletoCloud: {
          title: 'Boleto Cloud',
          links: [
            boletoCloudCriar,
            boletoCloudAtualizarVencimento,
            boletoCloudBaixarBoleto,
            boletoCloudHomologarPagamento,
          ],
        },
      };

      const dataKeys = Object.keys(dataIntegration);

      setDataIntegrations(dataIntegration);
      setDataIntegrationsKey(dataKeys);

      const dataIntegrationGreatToken = {
        stepone: {
          title: '1°',
          teste: 'Acesse o link e faça o login.',
          links: ['https://api-console.zoho.com/'],
        },
        steptwo: {
          title: '2°',
          teste:
            'Crie um cliente Server-based Applications, no campo Authorized Redirect URIs, cole o link abaixo.',
          links: [`${valueredirect}`],
        },
        stepthree: {
          title: '3°',
          teste:
            'Depois de criado, copie e cole os seguintes campos Client ID e Client Secret no zplug.in.',
        },
        stepfour: {
          title: '4°',
          teste: 'Abra uma guia, clique no Link para criação do Grant Token.',
        },
        stepfive: {
          title: '5°',
          teste: 'Aceite os termos da zoho books.',
        },
        stepsix: {
          title: '6°',
          teste: 'Copie o link e cole no campo Grant Token.',
        },
      };
      const dataKeysGreatToken = Object.keys(dataIntegrationGreatToken);
      setDataIntegrationsGreatToken(dataIntegrationGreatToken);
      setDataIntegrationsKeyGreatToken(dataKeysGreatToken);
    };

    defineDataIntegrations();
    allEnterprise();
    allClient();
    getStatesIBGE();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getDataFromDb(clientID, clientSecret, grantToken) {
    const response = await api.get('/client/' + id);
    const infoCompany = {
      name: response.data.name,
      email: response.data.email,
      companyName: response.data.companyName,
      cnpj: response.data.cnpj,
      state: response.data.state,
      city: response.data.city,
      enable: response.data.integration.plugNotas.enable,
      idOrganizacaoZohoBook: response.data.integration.zohobooks.organizationId,
      limitRequestCallApi:
        response.data.integration.plugNotas.limitRequestCallApi,
      clientID: clientID,
      clientSecret: clientSecret,
      grantToken: grantToken,
      accountToken: response.data.integration.billet.accountToken,
      bankAccountToken: response.data.integration.billet.bankAccountToken,
    };
    return infoCompany;
  }
  async function handleAddClient() {
    const result = await getDataFromDb(clientID, clientSecret, grantToken); //resgata os dados do banco de dados
    const companyData = {
      name: name,
      email: email,
      companyName: companyName,
      cnpj: cnpjData,
      state: state,
      city: city,
      enable: enablePlugNota,
      idOrganizacaoZohoBook: organizationId,
      limitRequestCallApi: limitRequestCallApi,
      clientID: clientID,
      clientSecret: clientSecret,
      grantToken: grantToken,
      accountToken: accountToken,
      bankAccountToken: bankAccountToken,
    };

    let modifiedData = [];
    let modifiedField = [];
    let unmodifiedData = [];

    for (let key in companyData) {
      //fitra o que foi editado, pega as labels dos campos e adiciona nos arrays.
      if (companyData[key] !== result[key]) {
        let label = document.getElementsByName(key);
        modifiedData.push(companyData[key]);
        modifiedField.push(label[0].innerText);
        unmodifiedData.push(result[key]);
      }
    }

    const data = {
      name: name,
      email: email,
      companyName: companyName,
      cnpj: cnpjData,
      state: state,
      city: city,
      integration: {
        plugNotas: {
          enable: enablePlugNota, //Enotas ativo
          countCallApi: 0,
          limitRequestCallApi: limitRequestCallApi, //Máximo de notas a serem emitidas
        },
        zohobooks: {
          organizationId: organizationId, //ID da organização do Zoho Book
          apikey: apikey, //Token do Zoho Books
        },
        billet: {
          accountToken: accountToken,
          bankAccountToken: bankAccountToken,
          bankCharges: bankCharges,
          enable: enableBillet,
        },
      },
      zohoData: {
        client_id: clientID,
        client_secret: clientSecret,
        grantToken: grantToken,
        idOrganizacaoZohoBook: organizationId,
      },
      logData: {
        user: getIName(),
        field: modifiedField,
        currentInfo: unmodifiedData,
        newInfo: modifiedData,
      },
    };

    try {
      await api.put('/client/' + id, data).then((response) => {
        if (response.status === 203) return toastMsgError(response.data.error);
        toast(true);
      });
    } catch (error) {
      toast(false);
    }
  }

  const toastMsgError = (msg) => {
    return notifyError(msg);
  };

  const toast = (opcao) => {
    const loginSuccessful = opcao;
    if (loginSuccessful) {
      return notifySuccess('Cliente atualizado com sucesso');
    } else {
      return notifyError('Credencias inválidas');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    const dataValid = await validateData();
    if (dataValid.error) return notifyError(dataValid.error);

    handleAddClient();
  };

  const copyLinkIntegration = (link) => {
    copyToClipBoard(link);
    notifySuccess('Link copiado para área de transferência');
  };

  const copyLink = (link) => {
    return copyLinkIntegration(link);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseGreatToken = () => setShowGreatToken(false);
  const handleShowGreatToken = () => setShowGreatToken(true);

  async function getToken(orgId) {
    if (!orgId) {
      console.log('O ID da organização não foi localizado.');
      return;
    }

    api
      .get('/refreshtoken?orgid=' + orgId)
      .then((res) => {
        setRefreshStatus(res?.data?.refresh ? ok : error);
        setAccessStatus(res?.data?.access ? ok : error);
      })
      .catch((err) => {
        console.log('Erro no getToken api', err);
      });
  }

  getToken(organizationId);

  return (
    <>
      <Container className='NewClients Container'>
        <Row
          style={{
            padding: '0px 15px',
          }}
        >
          <Col
            className='title'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowLeft
                style={{
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
                size={36}
                onClick={() => {
                  history.goBack();
                }}
              />
              <h2>Atualização do Cliente</h2>
            </div>
            <Button
              variant='primary'
              onClick={handleSubmit}
              className='subimit-btn'
              style={{ width: 'auto' }}
            >
              Atualizar cliente
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className='form-block'>
                <ClientImage />
                <h3>Dados pessoais</h3>
                <Form.Row>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridName'>
                      <Form.Label name='name'>Nome do Responsável</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um nome do responsável
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={12} lg={6}>
                    <Form.Group as={Col} controlId='formGridCompany'>
                      <Form.Label name='companyName'>
                        Nome da Empresa
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        required
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha o nome da empresa valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridEmail'>
                      <Form.Label name='email'>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='exemplo@exemplo.com'
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um email valido
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCNPJ'>
                      <Form.Label name='cnpjData'>CPF/CNPJ</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='00.000.000/0000-00'
                        value={cnpjData}
                        required
                        onChange={(e) => formatCPFCNPJ(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha um CPF ou CNPJ valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridState'>
                      <Form.Label name='state'>Estado</Form.Label>
                      <Form.Control
                        className='selectState'
                        as='select'
                        onChange={(event) => {
                          setState(event.target.value);
                        }}
                      >
                        <option>{state}</option>
                        <option></option>
                        {statesBrazil.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>
                        Escolha um Estado valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3}>
                    <Form.Group as={Col} controlId='formGridCity'>
                      <Form.Label name='city'>Cidade</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        required
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Escolha uma cidade valido.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
              <div className='form-block'>
                <h3>Empresas</h3>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Razão Social</th>
                      <th>Nome Fantasia</th>
                      <th>Email</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataEnterprise.map((company) => {
                      return (
                        <tr key={company === null ? '' : company.id}>
                          <td>{company === null ? '' : company.razaoSocial}</td>
                          <td>
                            {company === null ? '' : company.nomeFantasia}
                          </td>
                          <td>{company === null ? '' : company.email}</td>
                          <td>
                            {company === null ? (
                              ''
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                }}
                              >
                                <Button
                                  size='sm'
                                  onClick={() =>
                                    history.push('/seecompanies/' + company.id)
                                  }
                                  className='ButtonOptions'
                                >
                                  Ver
                                  <EyeFill
                                    style={{ marginLeft: '5px' }}
                                    color='white'
                                    size={17}
                                  />
                                </Button>
                                <Button
                                  size='sm'
                                  onClick={() =>
                                    history.push(
                                      '/updatecompanies/' + company.id
                                    )
                                  }
                                  className='ButtonOptions'
                                >
                                  Editar
                                  <PencilSquare
                                    style={{ marginLeft: '5px' }}
                                    color='white'
                                    size={17}
                                  />
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan='5'>
                        <Buttons
                          name='Nova Empresa'
                          route={'/newcompanies/' + id + '/' + organizationId}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className='form-block'>
                <h3>Integração Zohobooks</h3>
                <Form.Row>
                  <Col sm={12} md={6} xl={8}>
                    <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                      <Form.Label name='idOrganizacaoZohoBook'>
                        ID da organização Zoho Books
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={organizationId}
                        onChange={(e) => setOrganizationId(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} xl={4}>
                    <Form.Group as={Col} controlId='formGridMaxNotasEmitidas'>
                      <Form.Label name='limitRequestCallApi'>
                        Limite de notas emitidas
                      </Form.Label>
                      <Form.Control
                        type='number'
                        placeholder=''
                        value={limitRequestCallApi}
                        onChange={(e) => setLimitRequestCallApi(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} xl={4}>
                    <Form.Group as={Col} controlId='formGridTokenZohoBooks'>
                      <Form.Label name='clientID'>Client ID</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={clientID}
                        onBlur={(env) => setLinkgranttoken(onBlurIDClient(env))}
                        onChange={(e) => setClientID(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} xl={4}>
                    <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                      <Form.Label name='clientSecret'>Client Secret</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={clientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                        required
                      />
                      {clientID !== '' && clientSecret !== '' && (
                        <a
                          id='guide'
                          key={linkgranttoken}
                          onClick={() => {
                            window.open(linkgranttoken);
                          }}
                        >
                          Clique aqui para criar o Grant Token
                        </a>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={12} xl={4}>
                    <Form.Group as={Col} controlId='formGridIDZohoBooks'>
                      <Form.Label name='grantToken'>Grant Token</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={grantToken}
                        // onBlur={(env) => setRefreshToken(onBlurRefreshToken(env))}
                        onChange={(e) => setGrantToken(e.target.value)}
                        required
                        disabled={!clientID || !clientSecret}
                      />
                      <Link45deg color='gray' size={20} />
                      <a id='guide' onClick={handleShowGreatToken}>
                        Guia para criação do Grant Token
                      </a>
                    </Form.Group>
                    <div className='tokenstatusbox'>
                      <div className='accessStatus status'>
                        <img
                          style={{ width: '13px', height: '13px' }}
                          src={accessStatus}
                        ></img>
                        <div className='accessText' style={{ fontSize: '9px' }}>
                          ACCESS TOKEN STATUS
                        </div>
                      </div>

                      <div className='refreshStatus status'>
                        <img
                          style={{ width: '13px', height: '13px' }}
                          src={refreshStatus}
                        ></img>
                        <div
                          className='refreshText'
                          style={{ fontSize: '9px' }}
                        >
                          REFRESH TOKEN STATUS
                        </div>
                      </div>
                    </div>
                  </Col>
                </Form.Row>
                <h3>Integração Boleto Cloud</h3>
                <Form.Row>
                  <Col sm={12} md={6} xl={6}>
                    <Form.Group as={Col} controlId='formGridAccountToken'>
                      <Form.Label name='accountToken'>
                        Token de api do Boleto Cloud
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={accountToken}
                        onChange={(e) => setAccountToken(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                    <Form.Group as={Col} controlId='formGridBankAccountToken'>
                      <Form.Label name='bankAccountToken'>
                        Token da Conta Bancaria
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={bankAccountToken}
                        onChange={(e) => setBankAccountToken(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={6} xl={4} className='plugnotas-checkbox'>
                    <Form.Group id='formGridCheckbox'>
                      <Form.Check type='checkbox' id=''>
                        <Form.Check.Input
                          type='checkbox'
                          checked={enablePlugNota}
                          onChange={(e) => setEnablePlugNota(e.target.checked)}
                        />
                        <Form.Check.Label name='enable'>
                          PlugNotas ativo
                        </Form.Check.Label>
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Button variant='primary' onClick={handleShow}>
                      <EyeFill color='#ffffff' size={16} /> Visualizar links
                      Zohobooks
                    </Button>
                  </Col>
                </Form.Row>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Links de integração</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='modal-row'>
            <Col>
              <h3>ApiKey</h3>
              <div
                key={apiKeyIntegration}
                className='modal-row__line'
                onClick={() => window.open(apiKeyIntegration)}
              >
                <Clipboard color='#4274e3' size={16} />
                &nbsp;
                <div>{apiKeyIntegration}</div>
              </div>
            </Col>
          </Row>
          {dataIntegrationsKey.map((integration) => {
            return (
              <Row className='modal-row' key={integration}>
                <Col>
                  <h3>{dataIntegrations[integration].title}</h3>
                  {dataIntegrations[integration].links.map((link) => {
                    return (
                      <div
                        key={link}
                        className='modal-row__line'
                        onClick={() => {
                          window.open(link);
                        }}
                      >
                        <Clipboard color='#4274e3' size={16} />
                        &nbsp;
                        <div>{link}</div>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            );
          })}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showGreatToken} onHide={handleCloseGreatToken}>
        <Modal.Header closeButton>
          <Modal.Title>Guia de criação do Grant Tokens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataIntegrationsKeyGreatToken.map((integration) => {
            return (
              <Row className='modal-row' key={integration}>
                <Col>
                  <h3>{dataIntegrationsGreatToken[integration].title}</h3>
                  <p>{dataIntegrationsGreatToken[integration].teste}</p>
                  {dataIntegrationsGreatToken[integration].links
                    ? dataIntegrationsGreatToken[integration].links.map(
                        (link) => {
                          return (
                            <div
                              key={link}
                              className='modal-row__line'
                              onClick={() => {
                                if (
                                  link == 'https://admin.zplugin.com/copytoken'
                                ) {
                                  copyLink(link);
                                  return;
                                }
                                window.open(link);
                              }}
                            >
                              <Clipboard color='#4274e3' size={16} />
                              &nbsp;
                              <div style={{ cursor: 'pointer' }}>
                                {'Clique aqui'}
                              </div>
                            </div>
                          );
                        }
                      )
                    : ''}
                </Col>
              </Row>
            );
          })}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Fechar
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast />
    </>
  );
}
