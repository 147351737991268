import { parseISO, format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
export const formatDate = (date) => {
  try {
    const parsedDate = parseISO(date);
    const formattedDate = format(parsedDate, "dd 'de' MMMM', ' HH:mm'h'", {
      locale: pt,
    });
    return formattedDate;
  } catch (err) {
    return date;
  }
};
