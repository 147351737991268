import React from 'react';
import { Col, Button } from "react-bootstrap";
import './IconButton.scss'

export default function IconButton({ children, onClick, disabled }) {
    return (
        <Col className="iconButton">
            <Button variant="primary" onClick={onClick} disabled={disabled}>
                {children}
            </Button>
        </Col>
    )
}