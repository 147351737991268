import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Form, Col, Spinner } from 'react-bootstrap';
import { Toast } from '../../../components/Toast/Toast';
import './UpdateUsers.scss';
import { getIName } from '../../../services/Auth/Login';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import { getIdUsuarioAdmin, getIdUsuario } from '../../../services/Auth/Login';
import {
  notifySuccess,
  notifyError,
} from '../../../components/Toast/ToastController';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../contexts/GlobalStorage';
import { Chip, Input, InputAdornment } from '@material-ui/core';
import { ArrowLeft, PlusCircle } from 'react-bootstrap-icons';
import { cnpjMask } from '../../../helpers/cnpjMask';
import ModalConfirm from '../../_components/modalConfirm/ModalConfirm';
import ProfileImageUpdate from '../../../components/ProfileImage/ProfileImageUpdate/ProfileImageUpdate';

function UpdateUsers() {
  let history = useHistory();
  const global = useContext(GlobalContext);
  const [validated, setValidated] = useState(false);
  const { id } = useParams();
  const idUser = getIdUsuario();

  const [loading, setLoading] = useState(false);
  const [typeUser, setTypeUser] = React.useState('1');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [cnpjValues, setCnpjValues] = useState([]);
  const [currValue, setCurrValue] = useState('');
  const [userPermissions, setUserPermissions] = useState({
    addUserClient: false,
    changeCnpjUserClient: false,
    changeStatusFeedback: false,
    changeStatusUserClient: false,
    deleteFeedback: false,
    deleteUserClient: false,
    resetPasswordUserClient: false,
  });
  const [idUserRecovered, setIdUser] = React.useState('');
  const [lastPassword, setLastPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [repeatNewPassword, setRepeatNewPassword] = React.useState('');

  const [fieldsRecoveryPassword, setFieldsRecoveryPassword] =
    React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [onConfirmModal, setOnConfirmModal] = React.useState(false);
  const [
    idUserToResetPasswwordBySuperAdmin,
    setIdUserToResetPasswwordBySuperAdmin,
  ] = React.useState('');

  useEffect(() => {
    if (global.userType === 2 && getIdUsuario() !== id) {
      history.push('/home');
    }
  });

  useEffect(() => {
    async function allUsers() {
      const userId = getIdUsuarioAdmin();
      const userType = global.userType;

      if (userId !== id && userType === '2') api.get('/users/' + userId);

      const response = await api.get('/users/' + id);

      setName(response.data.name);
      setEmail(response.data.email);
      setTypeUser(response.data.usertype);
      setStatus(response.data.status);
      setUserPermissions(response.data.permissions);
      //setCnpjValues(response.data.cnpj);
      if (response.data.cnpj != null) {
        response.data.cnpj.forEach((cnpj) => {
          setCnpjValues((oldState) => [...oldState, cnpjMask(cnpj)]);
        });
      }
      setIdUser(response.data.idUser || response.data.id);
    }
    allUsers();
  }, [global.userType, id]);

  const handleTypeUser = (event) => {
    setTypeUser(event.target.value);
  };

  const resetPassword = async () => {
    const infoLog = {
      user: getIName(),
      field: ['Senha Antiga', 'Nova Senha', 'Repetir Nova Senha'],
    };
    if (!lastPassword | !newPassword | !repeatNewPassword) {
      return notifyError('Preencha todos os campos');
    }
    if (lastPassword === newPassword) {
      return notifyError('Senha não pode ser igual a anterior');
    }
    if (newPassword !== repeatNewPassword) {
      return notifyError('As senhas não conferem');
    }
    if (newPassword.length < 6 || repeatNewPassword.length < 6) {
      return notifyError('A senha deve conter 6 caracteres');
    }
    try {
      setLoading(true);
      const reset = await api.put(`/users/reset/${id}`, {
        infoLog,
        lastPassword,
        password: newPassword,
      });
      if (reset.status === 200) {
        setLoading(false);
        setLastPassword('');
        setNewPassword('');
        setRepeatNewPassword('');
        setFieldsRecoveryPassword(false);
      } else {
        setLoading(false);
        return notifyError('Erro ao resetar senha');
      }
    } catch (error) {
      setLoading(false);
      return notifyError('Erro ao resetar senha');
    }
  };

  useEffect(() => {
    if (onConfirmModal) {
      setLoading(true);
      api
        .put('/users/newPassword/' + idUserToResetPasswwordBySuperAdmin, {
          data: { clientName: getIName(), user: name },
        })
        .then(() => {
          setIdUserToResetPasswwordBySuperAdmin('');
          setOnConfirmModal(false);
          setLoading(false);
          notifySuccess('Senha resetada com sucesso!');
          return;
        })
        .catch(() => {
          setLoading(false);
          notifyError('Erro ao resetar senha');
          setIdUserToResetPasswwordBySuperAdmin('');
          setOnConfirmModal(false);
          return;
        });
    } else {
      setLoading(false);
      setIdUserToResetPasswwordBySuperAdmin('');
      setOnConfirmModal(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirmModal]);

  const handleResetPasswordBySuperAdmin = async () => {
    const dataLog = {
      clientName: getIName(),
      userChanged: name,
    };
    setShowConfirmModal(true);
    setIdUserToResetPasswwordBySuperAdmin(id, dataLog);
  };

  async function getDataFromDb(cnpjValues) {
    const response = await api.get('/users/' + id);
    const infoUser = {
      name: response.data.name,
      email: response.data.email,
      usertype: response.data.usertype,
      cnpj: cnpjValues,
      status: response.data.status,
    };
    return infoUser;
  }

  async function handleAddUser() {
    const result = await getDataFromDb(cnpjValues); //resgata os dados do banco de dados
    const infoUser = {
      name: name,
      email: email,
      usertype: typeUser,
      cnpj: cnpjValues,
      status: status,
    };

    let modifiedData = [];
    let modifiedField = [];
    let unmodifiedData = [];

    for (let key in infoUser) {
      if (infoUser[key] !== result[key]) {
        let label = document.getElementsByName(key);
        modifiedData.push(infoUser[key]);
        modifiedField.push(label[0].innerText);
        unmodifiedData.push(result[key]);
      }
    }

    const data = {
      name: name,
      email: email,
      usertype: typeUser,
      cnpj: cnpjValues,
      status: status,
      permissions: typeUser !== 2 ? userPermissions : [],
      logData: {
        clientName: getIName(),
        field: modifiedField,
        currentInfo: unmodifiedData,
        newInfo: modifiedData,
      },
    };

    if (fieldsRecoveryPassword) {
      resetPassword();
    }

    if (typeUser === 2 && cnpjValues.length === 0) {
      return {
        error: 'CNPJ inválido',
      };
    }

    try {
      const userId = getIdUsuarioAdmin();
      const response =
        id === idUser
          ? await api.put('/users/' + userId, data)
          : await api.put('/users/' + idUserRecovered, data);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  const toastErrors = (msg) => {
    return notifyError(msg);
  };

  const toast = (opcao) => {
    const loginSuccessful = opcao;
    if (loginSuccessful) {
      return notifySuccess('Usuário atualizado com sucesso');
    } else {
      return notifyError('Credencias inválidas');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
    }

    const userAdded = await handleAddUser();
    userAdded.error ? toastErrors(userAdded.error) : toast(true);
    setFieldsRecoveryPassword(false);
    setValidated(true);
  };

  const handleChange = (e) => {
    e.persist();

    setCurrValue(cnpjMask(e.target.value));
  };

  const handleDelete = (item, index) => {
    let arr = [...cnpjValues];
    arr.splice(index, 1);
    setCnpjValues(arr);
  };

  const handleSetCnpj = (e) => {
    const cnpj = currValue;
    e.persist();

    if (cnpj.length !== 18) {
      notifyError('Digite um CNPJ válido');
      return;
    }

    if (cnpj === '') {
      notifyError('Digite um CNPJ válido');
      return;
    }

    if (cnpjValues.includes(cnpj)) {
      notifyError('CNPJ já cadastrado');
      return;
    }

    setCnpjValues((oldState) => [...oldState, cnpj]);
    setCurrValue('');
  };
  return (
    <>
      <ModalConfirm
        handleSetShowConfirmModal={setShowConfirmModal}
        handleSetOnConfirmModal={setOnConfirmModal}
        showConfirmModal={showConfirmModal}
        body={'Deseja realmente resetar a senha desse usuário?'}
      />
      <Container className='UpdateUsers'>
        <div className='title'>
          <ArrowLeft
            style={{ cursor: 'pointer', marginRight: '10px' }}
            size={40}
            onClick={() => {
              history.goBack();
            }}
          />
          <h2 style={{ margin: '0' }}>Perfil</h2>
          <div className='profileImage'>
            <div
              style={{
                width: '140px',
                height: '140px',
              }}
            >
              <ProfileImageUpdate />
            </div>
          </div>
        </div>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Row>
            <Col>
              <Form.Group as={Col} controlId='formGridName'>
                <Form.Label name='name'>Nome</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  disabled={global.userType !== 3}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId='formGridEmail'>
                <Form.Label name='email'>E-mail</Form.Label>
                <Form.Control
                  type='email'
                  placeholder=''
                  disabled={global.userType !== 3}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            {typeUser === 2 &&
              global.userType === 1 &&
              global.permissions.changeStatusUserClient && (
                <Col xs={6}>
                  <Form.Label className='teste'>Status</Form.Label>
                  <Form.Group as={Col}>
                    <FormControl>
                      <Select
                        className='selectmenu'
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        placeholder=''
                      >
                        <MenuItem value={'Ativo'}>
                          <div>Ativo</div>
                        </MenuItem>
                        <MenuItem value={'Inativo'}>Inativo</MenuItem>
                      </Select>
                    </FormControl>
                  </Form.Group>
                </Col>
              )}
            {global.userType === 3 && (
              <Col xs={6}>
                <Form.Label name='status' className='teste'>
                  Status
                </Form.Label>
                <Form.Group as={Col}>
                  <FormControl>
                    <Select
                      className='selectmenu'
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      placeholder=''
                    >
                      <MenuItem value={'Ativo'}>
                        <div>Ativo</div>
                      </MenuItem>
                      <MenuItem value={'Inativo'}>Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Form.Group>
              </Col>
            )}

            {typeUser === 2 &&
              (global.permissions?.changeCnpjUserClient ||
                global.userType === 3) && (
                <Col>
                  <Form.Group as={Col} controlId='formGridCnpj'>
                    <FormControl fullWidth>
                      <Form.Label>CNPJ</Form.Label>
                      <div>
                        {cnpjValues.map((item, index) => (
                          <>
                            {global.userType !== 2 ? (
                              <Chip
                                key={index}
                                size='small'
                                onDelete={() => handleDelete(item, index)}
                                label={item}
                              />
                            ) : (
                              <Chip key={index} size='small' label={item} />
                            )}
                          </>
                        ))}
                      </div>

                      <Input
                        value={currValue}
                        onChange={handleChange}
                        disabled={global.userType === 2}
                        endAdornment={
                          global.userType !== 2 && (
                            <InputAdornment position='end'>
                              <PlusCircle
                                size={20}
                                color='#4274e3'
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  handleSetCnpj(e);
                                }}
                              />
                            </InputAdornment>
                          )
                        }
                      />
                      {cnpjValues.length === 0 && (
                        <Form.Text className='text-muted'>
                          *Campo obrigatório para usuários do tipo Cliente
                        </Form.Text>
                      )}
                    </FormControl>
                  </Form.Group>
                </Col>
              )}
          </Form.Row>
          <Form.Row>
            <Col xs={6}>
              <Form.Label name='usertype' className='teste'>
                Tipo de Usuário
              </Form.Label>
              <Form.Group as={Col}>
                {global.userType === '1' && (
                  <FormControl>
                    <Select
                      className='selectmenu'
                      value={typeUser}
                      onChange={handleTypeUser}
                      placeholder=''
                    >
                      <MenuItem key={1} value={1}>
                        <div>Administrador</div>
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        Cliente
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}

                {global.userType !== '1' && (
                  <FormControl disabled={global.userType !== 3}>
                    <Select
                      className='selectmenu'
                      value={typeUser}
                      onChange={handleTypeUser}
                      placeholder=''
                    >
                      <MenuItem key={1} value={1}>
                        <div>Administrador</div>
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        Cliente
                      </MenuItem>
                      <MenuItem key={3} value={3}>
                        Super Admin
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Form.Group>
            </Col>

            {(global.userType === 3 ||
              global.permissions.resetPasswordUserClient) &&
              id !== idUser && (
                <Col xs={6}>
                  <Button onClick={handleResetPasswordBySuperAdmin}>
                    {loading ? (
                      <Spinner size='sm' animation='border' />
                    ) : (
                      'Resetar senha'
                    )}
                  </Button>
                </Col>
              )}

            {fieldsRecoveryPassword && (
              <Col xs={6}>
                <Form.Group as={Col} controlId=''>
                  <Form.Label>Senha antiga</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder=''
                    value={lastPassword}
                    onChange={(e) => setLastPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            )}
            {!fieldsRecoveryPassword && (
              <Col xs={6}>
                {id === idUser && (
                  <Button onClick={() => setFieldsRecoveryPassword(true)}>
                    Atualizar senha
                  </Button>
                )}
              </Col>
            )}
          </Form.Row>

          <Form.Row>
            {fieldsRecoveryPassword && (
              <Col xs={6}>
                <Form.Group as={Col} controlId=''>
                  <Form.Label>Nova senha</Form.Label>
                  <Form.Control
                    type='password'
                    minLength={6}
                    placeholder=''
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {newPassword.length < 6 && (
                    <Form.Text className='text-muted'>
                      Mínimo de 6 caracteres
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            )}

            {fieldsRecoveryPassword && (
              <Col xs={6}>
                <Form.Group as={Col} controlId=''>
                  <Form.Label>Repetir nova senha</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder=''
                    minLength={6}
                    value={repeatNewPassword}
                    onChange={(e) => setRepeatNewPassword(e.target.value)}
                  />
                  {repeatNewPassword.length < 6 && (
                    <Form.Text className='text-muted'>
                      Mínimo de 6 caracteres
                    </Form.Text>
                  )}
                  {newPassword !== repeatNewPassword &&
                    repeatNewPassword.length > 0 && (
                      <Form.Text className='text-danger'>
                        As senhas não conferem
                      </Form.Text>
                    )}
                </Form.Group>
              </Col>
            )}
          </Form.Row>
          {typeUser === 1 && typeUser !== '' && (
            <Form.Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Permissões</Form.Label>
                  <Form.Check
                    checked={userPermissions?.deleteFeedback}
                    id='deleteFeedback-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        deleteFeedback: e.target.checked,
                      })
                    }
                    label='Deletar feedback'
                  />
                  <Form.Check
                    checked={userPermissions?.changeStatusFeedback}
                    id='changeStatusFeedback-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        changeStatusFeedback: e.target.checked,
                      })
                    }
                    label='Alterar status feedback'
                  />
                  <Form.Check
                    checked={userPermissions?.addUserClient}
                    id='addUserClient-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        addUserClient: e.target.checked,
                      })
                    }
                    label='Adicionar usuário cliente'
                  />
                  <Form.Check
                    checked={userPermissions?.resetPasswordUserClient}
                    id='resetPasswordUserClient-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        resetPasswordUserClient: e.target.checked,
                      })
                    }
                    label='Resetar senha do usuário'
                  />
                  <Form.Check
                    checked={userPermissions?.deleteUserClient}
                    id='deleteUserClient-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        deleteUserClient: e.target.checked,
                      })
                    }
                    label='Deletar usuário cliente'
                  />

                  <Form.Check
                    checked={userPermissions?.changeStatusUserClient}
                    id='changeStatusUserClient-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        changeStatusUserClient: e.target.checked,
                      })
                    }
                    label='Alterar status usuário cliente'
                  />

                  <Form.Check
                    checked={userPermissions?.changeCnpjUserClient}
                    id='changeCnpjUserClient-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        changeCnpjUserClient: e.target.checked,
                      })
                    }
                    label='Alterar CNPJ usuário cliente'
                  />
                  <Form.Check
                    checked={userPermissions?.receiveEmailFeedback}
                    id='receiveEmailFeedback-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        receiveEmailFeedback: e.target.checked,
                      })
                    }
                    label='Receber emails de feedbacks'
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          )}
          {typeUser === 3 && global.userType === 3 && (
            <Form.Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Check
                    checked={userPermissions?.receiveEmailFeedback}
                    id='receiveEmailFeedback-switch'
                    type='switch'
                    disabled={global.userType !== 3}
                    onChange={(e) =>
                      setUserPermissions({
                        ...userPermissions,
                        receiveEmailFeedback: e.target.checked,
                      })
                    }
                    label='Receber emails de feedbacks'
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          )}
          <Form.Row>
            <Col>
              <Form.Group as={Col} controlId='formGridName'>
                <Button variant='primary' type='submit' className='subimit-btn'>
                  Atualizar informações
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </Container>
      <Toast />
    </>
  );
}

export default UpdateUsers;
