import React from 'react';
import logo from './../../../assets/img/logozplugin.png';
import { Button, Col, Form, Image, Modal } from 'react-bootstrap';
export default function ModalConfirm({
  title,
  body,
  showConfirmModal,
  handleSetShowConfirmModal,
  handleSetOnConfirmModal,
  isFeedback,
  feedbackMessage,
  setFeedbackMessage,
  filter,
  idFeedbackToDelete,
}) {
  const handleClose = () => {
    return handleSetShowConfirmModal(false), handleSetOnConfirmModal(false);
  };
  const handleShow = () => {
    return handleSetOnConfirmModal(true), handleSetShowConfirmModal(false);
  };

  return (
    <>
      <Modal
        show={showConfirmModal}
        onHide={handleClose}
        className='modalContainer'
      >
        <Modal.Header closeButton>
          <div className='d-flex justify-content-center flex-fill'>
            <Image src={logo} width={100} height={50} />
          </div>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center flex-fill'>
          <Col className='d-flex justify-content-center flex-fill flex-column gap  align-items-center'>
            {body}
            {isFeedback &&
              !idFeedbackToDelete &&
              filter !== 'Em Andamento' &&
              filter !== 'Em Análise' && (
                <>
                  <Form.Control
                    as='textarea'
                    className='mt-3'
                    placeholder={`Escreva a resposta que irá para o cliente.`}
                    onChange={(e) => setFeedbackMessage(e.target.value)}
                    value={feedbackMessage}
                  ></Form.Control>
                </>
              )}
          </Col>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center flex-fill'>
          <div
            className='d-flex'
            style={{ width: '120px', justifyContent: 'space-between' }}
          >
            <Button
              variant='primary'
              onClick={handleShow}
              disabled={
                isFeedback &&
                !idFeedbackToDelete &&
                feedbackMessage === '' &&
                filter !== 'Em Andamento' &&
                filter !== 'Em Análise'
              }
            >
              Sim
            </Button>
            <br />
            <Button variant='danger' onClick={handleClose}>
              Não
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
